import api from 'Server/api';
import { defaultStatus } from 'Stores/common/defaults';
import { ListParameters, PhotoAssignment, PhotoCharacteristics } from 'Stores/common/models';
import { UserPhoto, UserTimestamp, defaultUserTimestamp, useUserStore } from 'Stores/user';
import { RequestError, RequestResponse } from 'Utilities/immutables';
import cloneDeep from 'lodash-es/cloneDeep';
import { defineStore } from 'pinia';
export interface ActivityState {
    activity: Activity,
    activities: Array<any>,
    activityCounts: ActivityCounts,
    searchParameters: ListParameters,
}

export interface Activity {
    id: string,
    accountId: string,
    jobId?: string,
    type: string,
    name: string,
    artifacts: any[],
    containsInstagram?: boolean,
    containsInactiveMarket?: boolean,
    containsSensitiveData?: boolean,
    currentPhoto: PhotoAssignment,
    photoCharacteristics?: PhotoCharacteristics | PhotoCharacteristics[] | null,
    userPhoto: UserPhoto | null,
    tags: string[],
    status: {
        statusCode: number,
        status: string,
        displayText: string,
        hoverText: string
        sendEmail: number,
        timestamp: string
    },
    hasDistribution: boolean,
    lastUpdated: string,
    created: UserTimestamp,
    updated: UserTimestamp
}

export interface ActivityCounts {
    audiences: number,
    comparisons: number,
    personas: number,
    total: number,
}

export enum ActivityLoaders {
    PREP = 'preparing activities',
    LOADING = 'loading activities'
}

export const ActivityType = {
    audience: 'audience',
    comparison: 'comparison',
    persona: 'persona',
}

export const ActivityStatus = {
    draft: 'DRAFT',
    error: 'ERROR',
    inProgress: 'IN_PROGRESS',
    success: 'SUCCESS',
}

export const defaultActivity = {
    id: '',
    accountId: '',
    type: '',
    name: '',
    artifacts: [],
    currentPhoto: {
        id: '',
        filePathUri: '',
        created: cloneDeep(defaultUserTimestamp),
        updated: cloneDeep(defaultUserTimestamp)
    },
    userPhoto: null,
    tags: [],
    status: cloneDeep(defaultStatus),
    hasDistribution: false,
    lastUpdated: '',
    created: cloneDeep(defaultUserTimestamp),
    updated: cloneDeep(defaultUserTimestamp)
}

export const useActivityStore = defineStore('activity', {
    state: (): ActivityState => ({
        activity: cloneDeep(defaultActivity),
        activities: [],
        activityCounts: {
            audiences: 0,
            comparisons: 0,
            personas: 0,
            total: 0,
        },
        searchParameters: {
            limit: 10,
            offset: 0,
            sort: '',
            order: ''
        }
    }),

    getters: {
        getActivity: (state: ActivityState) => state.activity,
        getActivities: (state: ActivityState) => state.activities,
        getActivityCounts: (state: ActivityState) => state.activityCounts,
        getSearchParameters: (state: ActivityState) => state.searchParameters,
    },

    actions: {
        async getActivityById({accountId, activityId}) {
            try {
                let params = {
                    limit: 1,
                    offset: 0,
                    search: `id:${activityId}`,
                };

                if (!params.hasOwnProperty('offset')) {
                    params.offset = 0;
                }

                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/activities`, {params});
                this.activity = response.data.data.activities[0];

                return this.activity;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getServiceActivities(parameters?: ListParameters) {
            let params = parameters ? parameters : this.searchParameters;

            if (!params.hasOwnProperty('offset')) {
                params.offset = 0;
            }

            delete params._searchTags;
            const accountId = useUserStore().getUser.currentAccountId;

            try {
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/activities`, {params});
                let activityData = response?.data?.data;

                if (activityData) {
                    this.activityCounts = response?.data?.data?.counts;

                    // TODO: this may not be needed if the API is updated to suppress photos for flagged items
                    activityData.activities = activityData.activities.map((activity: Activity) => {
                        if (activity.containsSensitiveData) {
                            switch (activity.type.toLowerCase()) {
                                case ActivityType.comparison:
                                    activity.photoCharacteristics = activity.photoCharacteristics?.map(_ => null);
                                    break;

                                default:
                                    activity.photoCharacteristics = null;
                            }
                        }
                        return activity;
                    });

                    this.activities = activityData.activities;
                } else {
                    throw `${RequestError.INVALID_RESPONSE}: service activities`;
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setActivities( activities: Activity[] ) {
            try {
                this.activities = activities;

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setSearchParameters(searchParameters) {
            try {
                this.searchParameters = searchParameters;

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }
})
