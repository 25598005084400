<template>
    <section class="container-fluid bg-comparison-report p-0 py-3">
        <div class="row">
            <div v-for="(item, index) in chartData" class="col-6" :key="index">
                <geographic-report
                    :chart-data="chartData[index]"
                    class="bg-white"
                    :detail="detail[index]"
                    :key="`geoReport_${index}`"
                    :params="{showControls: false, sidebarClass: 'col-12'}"
                >
                    <template #afterChart v-if="afterChart[index]">
                        <div v-html="afterChart[index]"></div>
                    </template>
                </geographic-report>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import {Chart} from 'highcharts-vue';
    import GeographicReport from 'Components/persona/report/custom-layout/geographic-report.vue';

    @Component<GeographicComparisonReport>({
        components: {
            GeographicReport
        }
    })
    class GeographicComparisonReport extends Vue {
        // proj4Loaded: boolean = false;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        chartData: Chart[];

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail: any[];

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        get afterChart() {
            return this.params.afterChart || [];
        }
    }
    export default toNative(GeographicComparisonReport);
</script>
