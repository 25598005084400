import moment from "moment";
import {isObject} from './inspect'; // MUST use relative path so that this can remain static!

export const slugIgnoredCharacters = /[^ a-zA-Z0-9_\-]/g;
export const systemTimeZoneAbbr: string = new Date().toLocaleTimeString(undefined, {timeZoneName: 'short'}).split(' ')[2];
export const systemTimeZone: string = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (${systemTimeZoneAbbr})`;
export const timeZones: Array<TimeZone> = [
    {
        "abbr": "GMT",
        "name": "Greenwich Mean Time",
        "utcOffset": "UTC±00"
    },
    {
        "abbr": "ADT",
        "name": "Atlantic Daylight Time",
        "utcOffset": "UTC-03"
    },
    {
        "abbr": "AST",
        "name": "Atlantic Standard Time",
        "utcOffset": "UTC-04"
    },
    {
        "abbr": "EDT",
        "name": "Eastern Daylight Time (North America)",
        "utcOffset": "UTC-04"
    },
    {
        "abbr": "EST",
        "name": "Eastern Standard Time (North America)",
        "utcOffset": "UTC-05"
    },
    {
        "abbr": "CDT",
        "name": "Central Daylight Time (North America)",
        "utcOffset": "UTC-05"
    },
    {
        "abbr": "CST",
        "name": "Central Standard Time (North America)",
        "utcOffset": "UTC-06"
    },
    {
        "abbr": "MDT",
        "name": "Mountain Daylight Time (North America)",
        "utcOffset": "UTC-06"
    },
    {
        "abbr": "MST",
        "name": "Mountain Standard Time (North America)",
        "utcOffset": "UTC-07"
    },
    {
        "abbr": "PDT",
        "name": "Pacific Daylight Time (North America)",
        "utcOffset": "UTC-07"
    },
    {
        "abbr": "PST",
        "name": "Pacific Standard Time (North America)",
        "utcOffset": "UTC-08"
    },
    {
        "abbr": "AKDT",
        "name": "Alaska Daylight Time",
        "utcOffset": "UTC-08"
    },
    {
        "abbr": "AKST",
        "name": "Alaska Standard Time",
        "utcOffset": "UTC-09"
    },
    {
        "abbr": "HDT",
        "name": "Hawaii Aleutian Daylight Time",
        "utcOffset": "UTC-09"
    },
    {
        "abbr": "HST",
        "name": "Hawaii Aleutian Standard Time",
        "utcOffset": "UTC-10"
    },
    {
        "abbr": "UTC",
        "name": "Coordinated Universal Time",
        "utcOffset": "UTC+00"
    }
]

export interface DateOptions {
    format?: string,
    timeZone?: string,
    utc?: boolean
}

export interface TimeZone {
    abbr: string;
    name: string;
    utcOffset: string;
}

export interface ObjectDiff {
    added: {} | ObjectDiff;
    updated: {
        [propName: string]: ObjectUpdate | ObjectDiff;
    };
    removed: {} | ObjectDiff;
    unchanged: {} | ObjectDiff;
}

export interface ObjectUpdate {
    oldValue: any;
    newValue: any;
}

export const booleanToBinary = (val: Boolean | String) => {
    if (val === 'false') {
        val = false; // Fix broken falsy values if 'false'
    }

    return Boolean(val) ? 1 : 0;
};

export const camelCase = (val: string) => {
    val = pascalCase(kebabCase(val)).replace(/[-\s]*/g, '');
    return val[0].toLowerCase() + val.substring(1);
};

export const dateFormat = (val: Date, options?: DateOptions) => {
    let formattedDate: string;

    options = Object.assign({}, {
        format: 'standard'
        // timeZone: 'America/Denver'
    }, options);

    let pattern;
    let patternNameTest = RegExp('^[a-zA-Z]+$');
    if (patternNameTest.test(options.format || '')) {
        switch (options.format) {
            // Add patterns here as needed
            case 'date':
                pattern = 'M/D/YYYY';
                break;
            case 'englishDate':
                pattern = 'MMM D, YYYY';
                break;
            case 'fullEnglishDate':
                pattern = 'MMMM D, YYYY';
                break;
            case 'fullEnglishDateTime':
                pattern = 'MMMM D, YYYY \\a\\t h:mm A';
                break;
            case 'fileDate':
                pattern = 'YYYY-MM-DD hmmA'; // e.g. 2019-01-30 315PM
                break;
            case 'isoFull':
                pattern = 'YYYY-MM-DD HH:mm:ss'; // e.g. 2019-01-30 23:41:00
                break;
            case 'isoShort':
                pattern = 'YYYY-MM-DD'; // e.g. 2019-01-30
                break;
            case 'standard':
            default:
                pattern = 'YYYY-MM-DD h:mm A'; // e.g. 2019-01-30 3:15PM
        }
    } else {
        // Use the supplied format as-is
        pattern = options.format;
    }

    formattedDate = moment(val).format(pattern);

    return formattedDate;
};

export const getLocalTimeZoneByAbbr = (abbr: string) => {
    let timeZone: string = systemTimeZone || '';

    try {
        const filteredZones: TimeZone[] = timeZones.filter((timeZone) => {
            return timeZone.abbr === abbr
        });

        if (filteredZones && Array.isArray(filteredZones)) {
            timeZone = filteredZones[0] ? filteredZones[0].name : timeZone;
        }
    } catch (error) {
        console.error(error);
    }

    return timeZone;
};

export const getSecondsFromMinutes = (minutes: number) => {
    let seconds: number = 0;

    try {
        seconds = Math.floor(minutes * 60);
    } catch (error) {
        console.error(error);
    }

    return seconds;
}

export const kebabCase = (val: string) => {
    return val.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

/**
 * @param oldObj The previous Object or Array.
 * @param newObj The new Object or Array.
 * @param deep If the comparison must be performed deeper than 1st-level properties.
 * @return A difference summary between the two objects.
 */
export const objectDiff = (oldObj: any, newObj: any, deep: boolean = false): ObjectDiff | boolean => {
    if (!isObject(oldObj) || !isObject(newObj)) {
        return false;
    }

    const added = {};
    const updated = {};
    const removed = {};
    const unchanged = {};
    for (const oldProp in oldObj) {
        if (oldObj.hasOwnProperty(oldProp)) {
            const newPropValue = newObj[oldProp];
            const oldPropValue = oldObj[oldProp];
            if (newObj.hasOwnProperty(oldProp)) {
                if (newPropValue === oldPropValue) {
                    unchanged[oldProp] = oldPropValue;
                } else {
                    updated[oldProp] = deep && isObject(oldPropValue) && isObject(newPropValue) ?
                        objectDiff(oldPropValue, newPropValue, deep) :
                        {newValue: newPropValue};
                }
            } else {
                removed[oldProp] = oldPropValue;
            }
        }
    }
    for (const newProp in newObj) {
        if (newObj.hasOwnProperty(newProp)) {
            const oldPropValue = oldObj[newProp];
            const newPropValue = newObj[newProp];
            if (oldObj.hasOwnProperty(newProp)) {
                if (oldPropValue !== newPropValue) {
                    if (!deep || !isObject(oldPropValue)) {
                        updated[newProp].oldValue = oldPropValue;
                    }
                }
            } else {
                added[newProp] = newPropValue;
            }
        }
    }
    return {added, updated, removed, unchanged};
}

export const pascalCase = (value: string) => {
    if (value) {
        return value.replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substring(1).toLowerCase())
    } else {
        return value;
    }
}

/**
 * Generate a slug from a given string
 * e.g. "This is My New Title!" -> "this-is-my-new-title"
 * @param val
 */
export const slug = (val: string) => {
    return val
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') // Change any non-alphanumeric characters to a hyphen
        .replace(/(^-*|-*$)/g, ''); // Remove extraneous hyphens
};

export const utcTimeUnitSince = (now: string, then: string, granularity?: string) => {
    let result: number = 0;
    let unitOfTime: string = granularity ? granularity : 'seconds';

    try {
        const difference: number = moment.utc(now).diff(moment.utc(then), unitOfTime as moment.unitOfTime.Diff);

        result = Math.abs(difference);
    } catch (error) {
        console.error(error);
    }

    return result;
};

export const versionMatchAtLeast: string = 'AT LEAST';
export const versionMatchExact: string = 'EXACT';
export const versionMatchNewer: string = 'NEWER';
export const versionCheck = (version: string, targetVersion: string, matchType: string = versionMatchAtLeast) => {
    // console.debug(`Checking version "${version}" against target "${targetVersion}" (${matchType})`);
    if (matchType === versionMatchExact) {
        return version === targetVersion;
    }

    const [majorVersion, minorVersion, subVersion] = version.split('.'),
     [majorVersionTarget, minorVersionTarget, subVersionTarget] = targetVersion.split('.');
    const majorVersionNum = parseInt(majorVersion),
        minorVersionNum = parseInt(minorVersion),
        subVersionNum = parseInt(subVersion);
    const majorVersionTargetNum = parseInt(majorVersionTarget),
        minorVersionTargetNum = parseInt(minorVersionTarget),
        subVersionTargetNum = parseInt(subVersionTarget);

    switch (matchType) {
        case versionMatchAtLeast:
            return majorVersionNum >= majorVersionTargetNum
                && minorVersionNum >= minorVersionTargetNum
                && subVersionNum >= subVersionTargetNum;

        case versionMatchNewer:
            if (majorVersionNum > majorVersionTargetNum) {
                return true;
            } else if (majorVersionNum == majorVersionTargetNum) {
                if (minorVersionNum > minorVersionTargetNum) {
                    return true;
                } else if (minorVersionNum == minorVersionTargetNum) {
                    return subVersion > subVersionTarget
                }
            }

            return false;
    }
}

/**
 *  Uses the fist letter of the specified text to determine whether to use a vowel-appropriate word
 *  Examples:
 *  - vowelize('aardvark', 'a', 'an') -> an
 *  - vowelize('capybara', 'a', 'an') -> a
 */
export const vowelize = (text: string, ifConsonant: string, ifVowel: string) => {
    return ['a', 'e', 'i', 'o', 'u'].includes(text.charAt(0)) ? ifVowel : ifConsonant;
};
