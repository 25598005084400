<template>
    <section data-intent="admin-debug-tools">
        <a href
           aria-controls="debug"
           class="text-info opacity-50 opacity-hover-100"
           :title="`Show admin debug tools`" v-tooltip
           @click.prevent="showDebugTools"
        >
            <span class="fa-stack fa-lg">
                <font-awesome-icon class="fa-stack-2x" :icon="['solid', 'circle']"/>
                <font-awesome-icon class="fa-stack-1x fa-inverse" :icon="['duotone', 'screwdriver-wrench']"/>
            </span>
        </a>

        <bootstrap-modal
            class="modal-xl fs-5"
            :id="debugModalId"
        >
            <template #title>Debug</template>
            <template #body>
                <div class="overflow-auto">
                    <table class="w-100 table table-hover table-sm table-striped">
                        <tbody>
                        <tr>
                            <td>
                                <strong class="text-nowrap">On-Page Help ID:</strong>
                            </td>
                            <td>
                                <span v-html="helpId"></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong class="text-nowrap">Route:</strong>
                            </td>
                            <td>
                                <pre v-html="currentRoute"></pre>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </bootstrap-modal>
    </section>
</template>

<script setup lang="ts">
    import {computed, nextTick, onMounted, onUpdated, ref} from 'vue';
    import {useRoute} from 'vue-router';
    import {Modal} from 'bootstrap';
    import clone from 'lodash/clone';
    import pick from 'lodash/pick';
    import BootstrapModal from 'Components/common/bootstrap/modal.vue';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import {useHelpStore} from 'Stores/help';
    import {jsonPretty} from 'Utilities/utils';

    const helpStore = useHelpStore();

    /* Debug modal */
    let debugModal: Modal | null = null;
    const debugModalId = ref('adminDebugToolsModal');

    const showDebugTools = () => {
        debugModal?.show();
    };

    onMounted(() => {
        // console.debug(`🐝 Debug component mounted - help ID: ${helpId.value}`);
        nextTick(() => {
            const debugModalElement: HTMLElement | null = document.getElementById(debugModalId.value);

            if (debugModalElement) {
                debugModal = new Modal(debugModalElement);
            }
        });
    });

    /* On-page help */
    const helpId = computed(() => {
        return helpStore.getOnPageHelpId || '<em>no on-page help provided for this route</em>';
    });


    onUpdated(() => {
        // console.debug(`🐝 Debug component updated - help ID: ${helpId.value}`);
    });


    /* Route */
    const route = useRoute();
    const currentRoute = computed(() => {
        let pickedRoute = pick(clone(route), ['name', 'path', 'params', 'query', 'meta']);
        return jsonPretty(pickedRoute);
    });
    // const filteredRoute = ref<any>(pick(
    //     clone(route),
    //     ['meta', 'name', 'params', 'query', 'section', 'tab']
    // ));
    const filteredRoute = ref<any>(clone(route));
</script>
