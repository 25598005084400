import {Account, useAccountStore} from 'Stores/account';
import {Email, ListFilter, ListParameters} from 'Stores/common/models';
import {User, UserFeedback, UserTimestamp} from 'Stores/user';
import {defineStore} from 'pinia';
import {useUserStore} from 'Stores/user';
import api from 'Server/api';
import {caseInsensitiveEquals, isEmptyArray, pascalCase} from 'Utilities/utils';
import cloneDeep from 'lodash-es/cloneDeep';
import {RequestError} from 'Utilities/immutables';
import clone from 'lodash-es/clone';

export interface HelpState {
    help: Help,
    onPageHelpId: string,
}

export interface Help {
    search: HelpSearch,
    subjects: {
        parameters: ListParameters,
        results: HelpSubject[],
    },
    toc: {
        sections: string[]
    },
}

export interface HelpContent {
    priority: number,
    section: string,
    matches: any[],
    subject: HelpSubject,
    article?: HelpArticle,
    releasedAt?: string,
}

export interface HelpSearch {
    parameters: ListParameters,
    history: string[],
    results: HelpContent[],
}

export interface HelpSubject {
    id: string,
    isActive: boolean,
    title: string,
    section: string,
    tocSection: string,
    displayOrder: number,
    snippet: string,
    articles?: HelpArticle[] | [],
    releasedAt?: Date,
    lastUpdated?: string,
    created?: UserTimestamp,
    updated?: UserTimestamp,
}

export interface HelpArticle {
    id: string,
    subjectId: string,
    isActive: boolean,
    displayOrder: number,
    title: string,
    videoPathUri: string,
    body?: string,
    collapse?: boolean,
    releasedAt?: Date,
    lastUpdated?: string,
    created?: UserTimestamp,
    updated?: UserTimestamp,
}

export enum HelpLoaders {
    LOADING = 'loading help',
    SENDING = 'sending',
    VALIDATING = 'validating'
}

export enum HelpTabs {
    FAQ = 'faq',
    GLOSSARY = 'glossary',
    HOW = 'how',
    NEWS = 'news',
    SEARCH = 'search',
    VIDEO = 'video'
}

export const defaultHelpSearchParameters: ListParameters = {
    limit: 10,
    offset: 0,
    sort: 'priority',
    order: 'desc',
    search: ''
};

export const defaultHelpSubjectsParameters: ListParameters = {
    sort: '',
    order: 'asc',
    filters: [
        {field: 'isActive', value: 'true'},
        {field: 'released', value: 'true'},
        {field: 'isActiveArticles', value: 'true'},
        {field: 'releasedArticles', value: 'true'},
    ],
    search: ''
};

export const defaultSupportMessage: Email = {
    from: {
        id: '',
        name: '',
        email: ''
    },
    subject: '',
    content: ''
};

export const getHumanReadableHelpType = (type: string) => {
    let formattedType: string;

    switch (type.toLowerCase()) {
        case HelpTabs.FAQ:
            formattedType = type.toUpperCase();
            break;

        case HelpTabs.HOW:
            formattedType = `${pascalCase(type)}-To`;
            break;

        default:
            formattedType = pascalCase(type);
            break;
    }

    return formattedType;
}

export const getHelpContentIcon = (type: string) => {
    switch (type.toLowerCase()) {
        case HelpTabs.FAQ:
            return ['duotone', 'circle-question'];
        case HelpTabs.GLOSSARY:
            return ['duotone', 'list'];
        case HelpTabs.HOW:
            return ['duotone', 'note-sticky'];
        case HelpTabs.NEWS:
            return ['duotone', 'bullhorn'];
        case HelpTabs.SEARCH:
            return ['duotone', 'search'];
        case HelpTabs.VIDEO:
            return ['brands', 'youtube'];
    }
}

export const useHelpStore = defineStore('help', {
    state: (): HelpState => ({
        help: {
            search: {
                parameters: cloneDeep(defaultHelpSearchParameters),
                history: [],
                results: []
            },
            subjects: {
                parameters: cloneDeep(defaultHelpSubjectsParameters),
                results: []
            },
            toc: {
                sections: []
            }
        },
        onPageHelpId: '',
    }),

    getters: {
        getHelp: (state: HelpState): Help => state.help,
        getHelpSearch: (state: HelpState): HelpSearch => state.help.search,
        getHelpSearchHistory: (state: HelpState): string[] => state.help.search.history,
        getHelpSearchResults: (state: HelpState): HelpContent[] => state.help.search.results,
        getHelpSearchParameters: (state: HelpState): ListParameters => state.help.search.parameters,
        getHelpSubjectsParameters: (state: HelpState): ListParameters => state.help.subjects.parameters,
        getHelpSubjects: (state: HelpState): HelpSubject[] => state.help.subjects.results,
        getOnPageHelpId: (state: HelpState): string => state.onPageHelpId,
        getTocSections: (state: HelpState): any[] => state.help.toc.sections,
    },

    actions: {
        async clearHelpSubjects() {
            try {
                this.help.subjects.parameters = cloneDeep(defaultHelpSubjectsParameters);
                this.help.subjects.results = [];
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getArticleById(id: string) {
            try {
                if (id) {
                    const response = await api.getAxiosInstance.get(`/api/help/articles/${id}?isActive=true`);
                    return response.data?.data || null;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
                return true;
            }
        },

        async getOnPageHelp(id: string) {
            try {
                const response = await api.getAxiosInstance.get(`/api/help/onpage/${id}`);
                const helpData: any = response.data?.data;

                if (helpData && helpData.definition?.section1?.hasOwnProperty('body')) {
                    return helpData;
                } else {
                    throw `No on-page help for ID "${id}"`;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async searchHelp(parameters: ListParameters) {
            try {
                if (parameters.search) {
                    if (parameters.filters) {
                        for (let i = 0; i < parameters.filters?.length; i++) {
                            const filter: ListFilter = parameters.filters[i] as ListFilter;
                            parameters[filter?.field] = filter?.value;
                        }

                        delete parameters.filters;
                    }

                    const response = await api.getAxiosInstance.get(`/api/help/content?isActive=true`, {params: parameters});
                    const content: any = response.data?.data;

                    if (content) {
                        await this.setHelpSearchResults(content.results);

                        return content;
                    } else {
                        throw RequestError.INVALID_RESPONSE;
                    }
                } else {
                    await this.setHelpSearchResults([]);
                    return [];
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async sendSupportMessage(message: Email) {
            try {
                const currentUser: User = useUserStore().getUser;
                message.from.id = currentUser.id;
                message.from.name = `${currentUser.firstName} ${currentUser.lastName}`;
                message.from.account = {id: currentUser.currentAccountId, name: useAccountStore().getAccount.name};
                const body: Email = message;
                const response = await api.getAxiosInstance.post(`/api/help/support/email`, body);

                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setHelpGlossary() {
            try {
                const parameters: ListParameters = this.help.subjects.parameters;

                if (parameters.filters) {
                    for (let i = 0; i < parameters.filters?.length; i++) {
                        const filter: ListFilter = parameters.filters[i] as ListFilter;
                        parameters[filter?.field] = filter?.value;
                    }

                    delete parameters.filters;
                }

                const response = await api.getAxiosInstance.get(`/api/help/subjects/glossary`, {params: parameters});
                const helpSubjects: any = response.data;

                if (helpSubjects.data) {
                    this.help.subjects.results = helpSubjects.data.results;

                    return helpSubjects.data;
                } else {
                    throw 'No help subjects';
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setHelpSearchParameters(searchParameters) {
            try {
                this.help.search.parameters = searchParameters;

                if (searchParameters.search) {
                    let searchHistory: string[] = clone(this.help.search.history);

                    if (!this.help.search.history.includes(searchParameters.search)) {
                        if (searchHistory.length === 5) {
                            searchHistory.pop();
                        }
                    } else {
                        for (let i = 0; i < searchHistory.length; i++) {
                            if (caseInsensitiveEquals(searchHistory[i], searchParameters.search)) {
                                searchHistory.splice(i, 1);
                                break;
                            }
                        }
                    }

                    searchHistory.unshift(searchParameters.search);
                    this.help.search.history = searchHistory;
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setHelpSubjectsParameters(subjectsParameters) {
            try {
                this.help.subjects.parameters = subjectsParameters;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setHelpSearchResults(results: HelpContent[]) {
            try {
                if (isEmptyArray(results)) {
                    this.help.search.results = [];
                } else {
                    let filteredContent = results.reduce((unique: HelpContent[], content) => {
                        if (!unique.some((c: HelpContent) => {
                            return !c.article?.title && c.subject.title === content.subject.title
                        })) {
                            unique.push(content);
                        }
                        return unique;
                    }, []);

                    this.help.search.results = filteredContent as HelpContent[];
                }
                return true;
            } catch (error) {
                console.error(error);
                return true;
            }
        },

        async setHelpSubjects() {
            try {
                const parameters: ListParameters = this.help.subjects.parameters;

                if (parameters?.filters) {
                    for (let i = 0; i < parameters.filters?.length; i++) {
                        const filter: ListFilter = parameters.filters[i] as ListFilter;
                        parameters[filter?.field] = filter?.value;
                    }

                    delete parameters.filters;
                }

                const response = await api.getAxiosInstance.get(`/api/help/subjects`, {params: parameters});
                const helpSubjects: any = response.data;

                if (helpSubjects.data) {
                    this.help.subjects.results = helpSubjects.data.subjects;
                    await this.setHelpTocSections(helpSubjects.data.subjects);

                    return helpSubjects.data;
                } else {
                    throw 'No help subjects';
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setHelpTocSections(helpSubjects: Array<HelpSubject>) {
            try {
                if (helpSubjects) {
                    let sections: Array<any> = Array.from(new Set(helpSubjects.map(a => a.tocSection)))
                        .map(tocSection => {
                            return helpSubjects.find(a => a.tocSection === tocSection)
                        }) as Array<any>;
                    this.help.toc.sections = sections;
                    return true;
                } else {
                    throw 'No help sections';
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setOnPageHelpId(id: string) {
            // console.log(id.length > 0 ? `🟢 Setting store help ID: ${id}` : `🔴 Removing store help ID`);
            this.onPageHelpId = id;
        },
    }
})
