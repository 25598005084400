<template>
    <div class="row g-0 chart-display">
        <!-- Dynamically render charts in flex grid -->
        <div v-for="(chart, index) in charts"
             class="chart-item d-flex flex-column mt-4"
             :class="chartColumnClass(chart)"
             :key="chart.chartId"
             :data-chart-id="chart.chartId"
        >
            <div class="w-100" v-if="chart && chart.data !== false">
                <div class="text-center">
                    <font-awesome-icon
                        v-if="chart.data?.hasOwnProperty('icon')"
                        class="text-gray-50"
                        :icon="chart.data.icon"
                        size="2x"
                    />
                    <highcharts
                        v-if="isHighcharts(chart)"
                        :options="chart.data"
                    />
                    <component
                        v-else
                        v-bind:is="chart.data.component"
                        class="p-3"
                        :data="chart.data.params"
                    />
                </div>
            </div>
<!--            <div v-else class="alert alert-danger">Chart "{{ chart.chartId }}" has no data!</div>-->

            <!-- Associated data, if available -->
            <div class="d-flex align-items-end mt-3" v-if="chart && includesChartAssociatedData">
                <div class="additional-info bg-gray-10 p-3 m-3 w-100"
                     :class="{'d-none d-md-block': chartAssociatedData(chart.chartId) === false}"
                >
                    <template v-if="chartAssociatedData(chart.chartId) !== false">
                        <chart-associated-data
                            :key="`associated_data_${chart.chartId}`"
                            :data="chartAssociatedData(chart.chartId)"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import {Chart} from 'highcharts-vue';
    import clone from 'lodash-es/clone';
    import cloneDeep from 'lodash-es/cloneDeep';
    import * as ReportUtilities from "Utilities/reports";
    import * as Utils from 'Utilities/utils';
    import ChartAssociatedData from "Components/persona/report/chart-associated-data";
    import FontAwesomeIcon from "Components/common/font-awesome-icon";

    @Component({
        components: {
            highcharts: Chart,
            ChartAssociatedData,
            FontAwesomeIcon
        }
    })
    class ChartGrid extends Vue {
        @Prop({
            type: Array,
            default: () => [],
        })
        chartsAssociatedData?;

        @Prop({
            type: Array,
            default: () => [],
        })
        charts;

        @Prop({
            type: [String, Object],
            default: 'col-6'
        })
        chartWrapperClass?;

        Utils = Utils;

        get includesChartAssociatedData(): boolean {
            if (!this.charts.length || !this.chartsAssociatedData.length) {
                return false;
            }

            return this.charts.some(chart => {
                return this.chartAssociatedData(chart.chartId);
            });
        }

        chartAssociatedData(chartId) {
            if (!this.chartsAssociatedData.length) {
                return false;
            }

            return this.chartsAssociatedData.find(item => item.chartId === chartId)?.data || false;
        }

        chartColumnClass(chart) {
            // let classes = [...this.chartWrapperClass.split(' ')];
            let classes: any = clone(this.chartWrapperClass);
            if (typeof this.chartWrapperClass === 'string') {
                classes = {};
                for (const wrapperClass of this.chartWrapperClass.split(' ')) {
                    classes[wrapperClass] = true;
                }
            }

            if (chart.hasOwnProperty('cols')) {
                // classes = classes.filter(className => className.indexOf('col-') !== 0);
                classes[`col-${chart.cols}`] = true;
            }

            return classes;
        }

        isHighcharts(chart: any) {
            // console.log('Is this a Highcharts chart?', chart);
            if (!chart?.chartId?.length) {
                return false
            }
            if (chart.chartId?.indexOf(ReportUtilities.NON_CHART_PREFIX) > -1) {
                return false;
            }

            // if (!chart.data?.series?.length) {
            //     // This shouldn't happen...
            //     return false;
            // }

            return true;
        }
    }
    export default toNative(ChartGrid);
</script>

