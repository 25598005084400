import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row g-0 chart-display" }
const _hoisted_2 = ["data-chart-id"]
const _hoisted_3 = {
  key: 0,
  class: "w-100"
}
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = {
  key: 1,
  class: "d-flex align-items-end mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_chart_associated_data = _resolveComponent("chart-associated-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Dynamically render charts in flex grid "),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charts, (chart, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["chart-item d-flex flex-column mt-4", _ctx.chartColumnClass(chart)]),
        key: chart.chartId,
        "data-chart-id": chart.chartId
      }, [
        (chart && chart.data !== false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (chart.data?.hasOwnProperty('icon'))
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      class: "text-gray-50",
                      icon: chart.data.icon,
                      size: "2x"
                    }, null, 8 /* PROPS */, ["icon"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.isHighcharts(chart))
                  ? (_openBlock(), _createBlock(_component_highcharts, {
                      key: 1,
                      options: chart.data
                    }, null, 8 /* PROPS */, ["options"]))
                  : (_openBlock(), _createBlock(_resolveDynamicComponent(chart.data.component), {
                      key: 2,
                      class: "p-3",
                      data: chart.data.params
                    }, null, 8 /* PROPS */, ["data"]))
              ])
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode("            <div v-else class=\"alert alert-danger\">Chart \"{{ chart.chartId }}\" has no data!</div>"),
        _createCommentVNode(" Associated data, if available "),
        (chart && _ctx.includesChartAssociatedData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["additional-info bg-gray-10 p-3 m-3 w-100", {'d-none d-md-block': _ctx.chartAssociatedData(chart.chartId) === false}])
              }, [
                (_ctx.chartAssociatedData(chart.chartId) !== false)
                  ? (_openBlock(), _createBlock(_component_chart_associated_data, {
                      key: `associated_data_${chart.chartId}`,
                      data: _ctx.chartAssociatedData(chart.chartId)
                    }, null, 8 /* PROPS */, ["data"]))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */)
            ]))
          : _createCommentVNode("v-if", true)
      ], 10 /* CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}