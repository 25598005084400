import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid px-0 pt-4 bg-comparison-report" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row g-0 m-n3" }
const _hoisted_4 = {
  class: "row g-0 collapse",
  id: "additionalDemographicsCollapse"
}
const _hoisted_5 = { class: "w-100 text-center my-n3" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { "data-chart-container": "" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "row col-padding-half" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "row col-padding-half" }
const _hoisted_13 = {
  key: 2,
  class: "row col-padding-half"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_17 = { class: "h5 text-primary mb-3 mx-auto" }
const _hoisted_18 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_19 = { class: "text-primary w-100" }
const _hoisted_20 = { class: "align-top" }
const _hoisted_21 = { class: "align-top text-end text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_summary_section = _resolveComponent("summary-section")!
  const _component_wedge_chart_section = _resolveComponent("wedge-chart-section")!
  const _component_summary_widget = _resolveComponent("summary-widget")!
  const _component_persona_topic_widget = _resolveComponent("persona-topic-widget")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (personaChartData, personaIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-6",
          key: `personaChartData_${personaIndex}`
        }, [
          _createCommentVNode(" Demographics "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['duotone', _ctx.FeatureIcons.DEMOGRAPHICS],
                size: "lg"
              }, null, 8 /* PROPS */, ["icon"])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink('demographics')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Demographics")
                ], undefined, true),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.demographics.slice(0, 2), (chart, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "col-6",
                    "data-chart-container": ""
                  }, [
                    _createVNode(_component_highcharts, { options: chart }, null, 8 /* PROPS */, ["options"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createCommentVNode(" Hidden demographics collapse section "),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.demographics.slice(2), (chart, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "col-6",
                    "data-chart-container": ""
                  }, [
                    _createVNode(_component_highcharts, { options: chart }, null, 8 /* PROPS */, ["options"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "btn btn-link margin-auto",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#additionalDemographicsCollapse",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.demographicsExpanded = !_ctx.demographicsExpanded))
                }, [
                  _withDirectives(_createVNode(_component_font_awesome_icon, { icon: ['solid', 'angle-up'] }, null, 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.demographicsExpanded]
                  ]),
                  _withDirectives(_createVNode(_component_font_awesome_icon, { icon: ['solid', 'angle-down'] }, null, 512 /* NEED_PATCH */), [
                    [_vShow, !_ctx.demographicsExpanded]
                  ])
                ])
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */),
          _createCommentVNode(" Unique Facts "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createElementVNode("img", {
                class: "logo-icon",
                src: _ctx.iconPath['personaBuilder'],
                alt: "",
                tabindex: "-1"
              }, null, 8 /* PROPS */, _hoisted_6)
            ]),
            title: _withCtx(() => [
              _createTextVNode(" Unique Facts ")
            ]),
            content: _withCtx(() => [
              _createVNode(_component_wedge_chart_section, {
                data: {color: _ctx.sectionColors[personaIndex], items: personaChartData.uniqueFacts}
              }, null, 8 /* PROPS */, ["data"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */),
          _createCommentVNode(" Consumer Spend "),
          (personaChartData.consumerSpendActivity.length > 0)
            ? (_openBlock(), _createBlock(_component_summary_section, { key: 0 }, {
                icon: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['duotone', _ctx.FeatureIcons.CONSUMER_SPENDING],
                    size: "lg"
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                title: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: _ctx.summaryLink('consumer-spending')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Consumer Spend")
                    ], undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ]),
                content: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.consumerSpendActivity, (consumerSpendChart, index) => {
                    return (_openBlock(), _createBlock(_component_highcharts, {
                      key: `consumer_spend_${index}`,
                      options: consumerSpendChart
                    }, null, 8 /* PROPS */, ["options"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" High-level RFM "),
          (personaChartData.hasOwnProperty('highLevelRfm'))
            ? (_openBlock(), _createBlock(_component_summary_section, { key: 1 }, {
                icon: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['duotone', _ctx.FeatureIcons.HIGH_LEVEL_RFM],
                    size: "lg"
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                title: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: _ctx.summaryLink('rfm')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("RFM")
                    ], undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ]),
                content: _withCtx(() => [
                  _createVNode(_component_highcharts, {
                    options: personaChartData.highLevelRfm
                  }, null, 8 /* PROPS */, ["options"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("section", null, [
            _createCommentVNode(" Conglomerate RFM / IND33 / Market Affinity"),
            (personaChartData.conglomerateRfmMarket)
              ? (_openBlock(), _createBlock(_component_summary_section, { key: 0 }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['duotone', _ctx.FeatureIcons.CONGLOMERATE_RFM_MARKETS],
                      size: "lg"
                    }, null, 8 /* PROPS */, ["icon"])
                  ]),
                  title: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: _ctx.summaryLink('market-affinity')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Market Affinity, by Spend")
                      ], undefined, true),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["to"])
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_highcharts, {
                        options: personaChartData.conglomerateRfmMarket
                      }, null, 8 /* PROPS */, ["options"])
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createCommentVNode(" Past Purchases "),
                  _createVNode(_component_summary_section, null, {
                    icon: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['duotone', _ctx.FeatureIcons.PAST_PURCHASES],
                        size: "lg"
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    title: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: _ctx.summaryLink('past-purchases')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Past Purchase Activity")
                        ], undefined, true),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["to"])
                    ]),
                    content: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.pastPurchaseActivity, (pastPurchaseChart, index) => {
                        return (_openBlock(), _createBlock(_component_highcharts, {
                          key: `past_purchase_${index}`,
                          options: pastPurchaseChart
                        }, null, 8 /* PROPS */, ["options"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
          ]),
          _createCommentVNode(" Weekly social activity "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                "fixed-width": "",
                icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
              }, null, 8 /* PROPS */, ["icon"])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink('social-activity')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Weekly Activity")
                ], undefined, true),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createVNode(_component_highcharts, {
                options: personaChartData.socialWeeklyActivity
              }, null, 8 /* PROPS */, ["options"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */),
          _createCommentVNode(" Political social engagement "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
                size: "lg"
              }, null, 8 /* PROPS */, ["icon"])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink('interests')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Political Social Engagement")
                ], undefined, true),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                (personaChartData.politicalSocialSummary.hasOwnProperty('twitter'))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _withDirectives(_createVNode(_component_summary_widget, {
                        class: "col-6",
                        summaryItems: [personaChartData.politicalSocialSummary.twitter[1]],
                        summaryType: "twitter",
                        tooltipPattern: "[[ percent ]] of the group has engaged"
                      }, null, 8 /* PROPS */, ["summaryItems"]), [
                        [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                      ]),
                      _withDirectives(_createVNode(_component_summary_widget, {
                        class: "col-6",
                        summaryItems: [personaChartData.politicalSocialSummary.twitter[0]],
                        summaryType: "twitter",
                        tooltipPattern: "[[ percent ]] of the group has engaged"
                      }, null, 8 /* PROPS */, ["summaryItems"]), [
                        [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */),
          _createCommentVNode(" Political party affiliation "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
                size: "lg"
              }, null, 8 /* PROPS */, ["icon"])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink('demographics', 'politics-job-residence')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Political Party Affiliation ")
                ], undefined, true),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createVNode(_component_highcharts, {
                options: personaChartData.politicalPartyAffiliation
              }, null, 8 /* PROPS */, ["options"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */),
          _createCommentVNode(" Brand & Interest Summary "),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_summary_section, null, {
                icon: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    "fixed-width": "",
                    icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                title: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: _ctx.summaryLink('brands')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Brand Summary")
                    ], undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ]),
                content: _withCtx(() => [
                  (personaChartData.brandSummary.hasOwnProperty('twitter'))
                    ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                        key: 0,
                        summaryItems: personaChartData.brandSummary.twitter,
                        summaryType: "twitter",
                        tooltipPattern: "[[ percent ]] of the group is interested"
                      }, null, 8 /* PROPS */, ["summaryItems"])), [
                        [_vShow, _ctx.itemContext['brand'] === 'twitter']
                      ])
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_summary_section, null, {
                icon: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    "fixed-width": "",
                    icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                title: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: _ctx.summaryLink('interests')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Interest Summary")
                    ], undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ]),
                content: _withCtx(() => [
                  (personaChartData.interestSummary.hasOwnProperty('twitter'))
                    ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                        key: 0,
                        summaryItems: personaChartData.interestSummary.twitter,
                        summaryType: "twitter",
                        tooltipPattern: "[[ percent ]] of the group is interested"
                      }, null, 8 /* PROPS */, ["summaryItems"])), [
                        [_vShow, _ctx.itemContext['interest'] === 'twitter']
                      ])
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ])
          ]),
          _createCommentVNode(" Topics "),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.socialTopics.slice(0, 2), (topic) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6",
                key: topic.key
              }, [
                _createVNode(_component_summary_section, null, {
                  icon: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      "fixed-width": "",
                      icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                    }, null, 8 /* PROPS */, ["icon"])
                  ]),
                  title: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: _ctx.summaryLink(topic.tab, topic.section || null)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(topic.category || topic.name), 1 /* TEXT */)
                      ], undefined, true),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                  ]),
                  content: _withCtx(() => [
                    _createVNode(_component_persona_topic_widget, {
                      topic: topic,
                      context: _ctx.itemContext[`topic-${topic.key}`]
                    }, null, 8 /* PROPS */, ["topic", "context"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          (personaChartData.socialTopics.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.socialTopics.slice(2, 4), (topic) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-6",
                    key: topic.key
                  }, [
                    _createVNode(_component_summary_section, null, {
                      icon: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          "fixed-width": "",
                          icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                        }, null, 8 /* PROPS */, ["icon"])
                      ]),
                      title: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: _ctx.summaryLink(topic.tab, topic.section || null)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(topic.category || topic.name), 1 /* TEXT */)
                          ], undefined, true),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_persona_topic_widget, {
                          topic: topic,
                          context: _ctx.itemContext[`topic-${topic.key}`]
                        }, null, 8 /* PROPS */, ["topic", "context"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Geography "),
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                "fixed-width": "",
                icon: ['duotone', _ctx.FeatureIcons.GEOGRAPHY]
              }, null, 8 /* PROPS */, ["icon"])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink('geographic-areas')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Geography")
                ], undefined, true),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createVNode(_component_highcharts, {
                "constructor-type": "mapChart",
                options: personaChartData.geography.chart,
                style: {"overflow":"visible !important"}
              }, null, 8 /* PROPS */, ["options"]),
              _createCommentVNode(" Detail sidebar "),
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(personaChartData.geography.detail, (detailSection) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("u", _hoisted_17, _toDisplayString(detailSection.name), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("table", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items.index, (item) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: item.label
                            }, [
                              _createElementVNode("td", _hoisted_20, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_21, _toDisplayString(item.value), 1 /* TEXT */)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ])
                    ])
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}