import Vue from 'vue';
import api from 'Server/api';
import { Account, useAccountStore } from 'Stores/account';
import { ListParameters } from 'Stores/common/models';
import { User, UserTimestamp, defaultUserTimestamp } from 'Stores/user';
import { defineStore } from 'pinia';
import { useUserStore } from 'Stores/user';
import { SocialContent, SocialFollow } from 'Stores/persona';
import { cloneDeep } from 'lodash';
import { RequestError } from 'Utilities/immutables';
import { axiosResponse } from 'Utilities/utils';
import { AxiosResponse } from 'axios';

export interface TopicState {
    topic: Topic;
    topics: Array<Topic>;
    serviceTopic: any;
}

export interface Topic {
    id: string;
    name: string;
    account?: {
      id: string;
      name?: string;
    },
    accountId?: string;
    userId?: string;
    mode?: string;
    public: boolean;
    selected?: boolean;
    definition: {
      follows: Array<SocialFollow>;
      content: {
        mentions: Array<SocialContent>;
        hashtags: Array<SocialContent>;
        terms: Array<SocialContent>;
        startDate: string;
        endDate: string;
      }
    },
    tags?: Array<string>;
    classification?: string;
    category?: string;
    categoryId?: string;
    subCategory?: string;
    subCategoryId?: string;
    sentenceStyle?: string;
    verb?: string;
    indexStyle?: string;
    shortDescription?: string;
    longDescription?: string;
    iconStyle?: string;
    iconName?: string;
    defaultTopic?: boolean;
    created?: UserTimestamp;
    updated?: UserTimestamp;
    fetchedAt?: string;
    lastUsed: string;
}

export enum TopicMode {
    NEW = 'new',
    EDIT = 'edit',
    VIEW = 'view',
    RESET = 'reset'
}

export enum SortOptions {
    MOST_RECENT = 'LASTUSED',
    A_Z = 'NAME'
}

export enum TopicLoaders {
    VALIDATING = 'validating topic',
    SAVING = 'saving topic',
    VIEWING ='viewing topic'
}

export const defaultTopic: Topic = {
    accountId: '',
    id: '',
    name: '',
    mode: '',
    public: true,
    definition: {
        follows: [],
        content: {
            startDate: '',
            endDate: '',
            mentions: [],
            hashtags: [],
            terms: []
        },
    },
    tags: [],
    fetchedAt: '',
    lastUsed: '',
    classification: '',
    categoryId: '',
    subCategoryId: '',
    sentenceStyle: '',
    verb: '',
    indexStyle: '',
    defaultTopic: false,
    shortDescription: '',
    longDescription: '',
    iconStyle: '',
    iconName: ''
};

export const useTopicStore = defineStore('topic', {
    state: (): TopicState => ({
        topic: {
            id: '',
            name: '',
            userId: '',
            account: {
                id: '',
                name: ''
            },
            mode: '',
            public: true,
            tags: [],
            definition: {
                follows: [],
                content: {
                    startDate: '',
                    endDate: '',
                    mentions: [],
                    hashtags: [],
                    terms: []
                },
            },
            classification: '',
            categoryId: '',
            subCategoryId: '',
            sentenceStyle: '',
            verb: '',
            indexStyle: '',
            defaultTopic: false,
            shortDescription: '',
            longDescription: '',
            iconStyle: '',
            iconName: '',
            created: cloneDeep(defaultUserTimestamp),
            updated: cloneDeep(defaultUserTimestamp),
            fetchedAt: '',
            lastUsed: ''
        },
        topics: [],
        serviceTopic: {}
    }),

    getters: {
        getTopic: (state: TopicState) => state.topic,
        getTopicSocialFollows: (state: TopicState): Array<SocialFollow> => state.topic.definition.follows || [],
        getTopicSocialContent( state: TopicState ): Array<SocialContent> {
            let content: Array<SocialContent> = [];
            const hashtags: Array<SocialContent> = state.topic.definition.content.hashtags || [];
            const mentions: Array<SocialContent> = state.topic.definition.content.mentions || [];
            const terms: Array<SocialContent> = state.topic.definition.content.terms || [];

            content = content.concat( hashtags ).concat( mentions ).concat( terms );

            return content;
        },
        getTopicSocialContentStartDate: (state: TopicState): string => state.topic.definition.content.startDate || '',
        getTopicSocialContentEndDate: (state: TopicState): string => state.topic.definition.content.endDate || '',
    },

    actions: {
        clearTopic() {
            try {
                this.topic = cloneDeep(defaultTopic);
                return true;
            } catch (error) {
                console.error( error );
                return false;
            }
        },

        async cloneTopic(topic: Topic) {
            try {
                let clone: Topic = cloneDeep(topic);
                clone.mode = TopicMode.NEW;
                clone.id = '';
                clone.name = `${clone.name} copy`;
                clone.account = { id: useUserStore().getUser.currentAccountId };
                clone.public = false;

                this.topic = clone;
                return this.topic;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async deleteTopic(topic: Topic) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const response = await api.getAxiosInstance.delete( `/api/accounts/${accountId}/topics/${topic.id}` );
                const deleteResponse = response.data?.data;
                if (deleteResponse) {
                    this.clearTopic();

                    return deleteResponse;
                } else {
                    throw `deleteTopic: ${RequestError.UNABLE_TO_DELETE} (${topic.id})`;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async getTopicNameValidation(value: string) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const name: string = encodeURIComponent( value );
                const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/topics/names/${name}` );

                return response.data?.data;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async mapApiTopic() {
            try {
                let serviceTopic: any = cloneDeep(this.topic);
                serviceTopic.accountId = this.topic.account?.id;

                delete (serviceTopic.account);
                delete (serviceTopic.mode);
                delete (serviceTopic.selected);
                delete (serviceTopic.public)
                delete (serviceTopic.created);
                delete (serviceTopic.updated);

                this.serviceTopic = serviceTopic;
                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async mapApiTopicSocialFollows() {
            try {
                let serviceTopic: any = cloneDeep( this.serviceTopic );
                let apiFollows: Array<SocialFollow> = [];

                for ( let i = 0; i < this.topic.definition.follows.length; i++ ) {
                    let follow: SocialFollow = this.topic.definition.follows[ i ];
                    delete( follow.positive );
                    delete( follow.selected );

                    if ( 'instagramInterest' in follow ) {
                        delete( follow.instagramInterest );
                    }
                    if ( 'instagramTitle' in follow ) {
                        delete( follow.instagramTitle );
                    }

                    apiFollows.push( follow );
                }

                serviceTopic.definition.follows = apiFollows;
                this.serviceTopic = serviceTopic;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async mapApiTopicSocialContent() {
            try {
                let serviceTopic: any = cloneDeep( this.serviceTopic );
                let keys = Object.keys( this.topic.definition.content );

                for ( let i = 0; i < keys.length; i++ ) {
                    const contentType: string = keys[ i ];

                    if ( contentType !== 'startDate' && contentType !== 'endDate' ) {
                        const newTopicContent: Array<SocialContent> = this.topic.definition.content[ contentType ];
                        let contentArray: Array<any> = [];

                        for ( let j = 0; j < newTopicContent.length; j++ ) {
                            contentArray.push( { 'twitterContent' : newTopicContent[ j ].content } );
                        }

                        serviceTopic.definition.content[ contentType ] = contentArray;
                    } else if ( contentType === 'startDate' && this.topic.definition.content.startDate === '' ) {
                        delete( serviceTopic.definition.content.startDate );
                    } else if ( contentType === 'endDate' && this.topic.definition.content.endDate === '') {
                        delete( serviceTopic.definition.content.endDate );
                    }
                }

                this.serviceTopic = serviceTopic;
                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopic(topic: Topic) {
            try {
                const currentUser: User = useUserStore().getUser;
                topic.userId = currentUser.id;
                this.topic = topic;
                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopicName(name: string) {
            try {
                const currentUser: User = useUserStore().getUser;
                this.topic.name = name;
                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopicSocialFollow(socialFollow: SocialFollow) {
            try {
                if ( this.topic.definition.hasOwnProperty( 'follows' ) ) {
                    this.topic.definition.follows?.push( socialFollow );
                } else {
                    const follows: Array<SocialFollow> = [ socialFollow ];
                    this.topic.definition = {follows: follows};
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopicSocialContentType(socialContent: SocialContent) {
            try {
                const prefixChar: string = socialContent.content.charAt( 0 );
                let type: string = '';

                switch ( prefixChar ) {
                    case '#':
                        type = 'hashtags';
                        break;
                    case '@':
                        type = 'mentions';
                        break;
                    default:
                        type = 'terms';
                        break;
                }

                if ( this.topic.definition.content.hasOwnProperty( type ) ) {
                    this.topic.definition.content[ type ].push( socialContent );
                } else if ( this.topic.definition.hasOwnProperty( 'content' ) ) {
                    this.topic.definition.content[type] = socialContent;
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopicSocialContentStartDate(startDate: string) {
            try {
                if (this.topic.hasOwnProperty('definition')) {
                    this.topic.definition.content.startDate = startDate;
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setTopicSocialContentEndDate(endDate: string) {
            try {
                this.topic.definition.content.endDate = endDate;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setTopicTags(topicTags: Array<string>) {
            try {
                this.topic.tags = topicTags;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async saveTopic(topic: Topic) {
             try {
                await this.setTopic(topic);
                await this.mapApiTopic();
                await this.mapApiTopicSocialFollows();
                await this.mapApiTopicSocialContent();

                const currentUser: User = useUserStore().getUser;
                const accountId: string = currentUser.currentAccountId;
                const body: any = this.serviceTopic;
                let response: AxiosResponse<any> = axiosResponse();

                if ( topic.id && topic.mode === TopicMode.EDIT ) {
                    response = await api.getAxiosInstance.put( `/api/accounts/${accountId}/topics/${topic.id}`, body );
                } else {
                    response = await api.getAxiosInstance.post( `/api/accounts/${accountId}/topics`, body );
                }

                if ( response.data.code === 200 ) {
                    await this.setTopic(response.data.data);
                }

                return response.data;


            } catch ( error ) {

                console.error( error );
                return false;
            }
        }
    }
})
