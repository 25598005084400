<template>
    <div class="pb-spinner-container" :class="getClasses()">
        <div class="d-flex align-items-center justify-content-center pb-spinner-frame">
            <div class="flex-fill text-end">
                <div class="position-relative pb-spinner">
                    <img class="pb-spinner-inner" src="/assets/images/spinner-foreground.svg">
                </div>
            </div>
            <div class="flex-fill text-start ps-2 pb-spinner-text">
                <span v-html="textLabel" :class="textClassList"></span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import clone from 'lodash-es/clone';

    @Component({
        name: 'Spinner'
    })
    class Spinner extends Vue {
        @Prop({
            type: Array,
            required: false,
            default: (_: any) => []
        })
        classList!: string[];

        @Prop({
            type: Array,
            required: false,
            default: (_: any) => []
        })
        textClassList!: string[];

        @Prop({
            type: Boolean,
            default: true
        })
        fill!: boolean;

        @Prop({
            type: Boolean,
            default: false
        })
        noLabel!: boolean;

        @Prop({
            type: String,
            default: 'Loading...'
        })
        text!: string;

        get textLabel() {
            if (this.noLabel) {
                return '';
            } else {
                return this.text;
            }
        }

        getClasses() {
            let classes: string[] = clone(this.classList).concat(['d-flex', 'flex-column', 'justify-content-center', 'align-items-center']);

            if (this.fill) {
                classes.push('fill-space');
            }

            return classes;
        }
    }

    export default toNative(Spinner);
</script>
<style lang="scss">
    @import '../../app/styles/variables';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .pb-spinner {
        height: 5rem;
        width: 5rem;

        &-container {
            &.fill-space {
                //min-height: 50rem;
                height: calc(100vh - #{$navbar-height});
            }
        }

        &-inner, &-outer {
            display: block;
            height: auto;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &-inner {
            animation: rotate 1.755s infinite;
            animation-timing-function: cubic-bezier(1, 0, .5, 1);
            //animation: pulse 3s infinite;
            //animation-direction: alternate;
            //-webkit-animation-name: pulse;
            //animation-name: pulse;
            padding: 1.25rem;
        }

        &-text {
            color: $info;
            font-size: 2.25rem;
        }
    }

    .overlay-wrapper {
        background-color: $gray-25 !important;
        opacity: .5;

        .pb-spinner-container {
            height: inherit;
            z-index: 1;
        }

        .pb-spinner-frame {
            background-color: white;
            padding: 10px;
            border: 1px solid $blue-75;
            border-radius: $border-radius;
        }
    }

    //@keyframes pulse {
    //    0% {
    //        -webkit-transform: scale(1) rotate(-360deg);
    //        -webkit-transform-transform-origin: center center;
    //    }
    //
    //    50% {
    //        -webkit-transform: scale(1.2) rotate(360deg);
    //        -webkit-transform-transform-origin: center center;
    //    }
    //
    //    100% {
    //        -webkit-transform: scale(1) rotate(-360deg);
    //        -webkit-transform-transform-origin: center center;
    //    }
    //}

    @keyframes rotate {
        from {
            -webkit-transform: rotate(0deg)
        }
        to {
            -webkit-transform: rotate(360deg)
        }
    }

</style>
