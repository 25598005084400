<template>
    <section v-if="isDataValid" class="associated-chart-data text-center">
        <h5 v-if="associatedData('title')" class="title text">{{ associatedData('title') }}</h5>
        <p v-if="associatedData('detail')" :class="detailClass"><em v-html="associatedData('detail')"></em></p>
        <div class="row">
            <div class="col-6">
                {{ associatedDataComponents['baseline_label'] }}: {{ Utils.formatValue(associatedDataComponents['baseline_value'], associatedDataComponents['value_format']) }}
            </div>
            <div class="col-6">
                {{ associatedDataComponents['persona_label'] }}: {{ Utils.formatValue(associatedDataComponents['persona_value'], associatedDataComponents['value_format']) }}
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import * as StaticUtils from "Utilities/utils-static"
    import * as Utils from 'Utilities/utils';

    interface AssociatedData {
        title?: string,
        detail: string,
        detail_format?: string,
        detail_format_decimals?: number,
        direction?: string,
        index?: number,
        value_format?: string,
        value_format_decimals?: number,
        value_label?: string,
        positive_label?: string,
        negative_label?: string,
        baseline_label?: string,
        persona_label?: string
        value?: number,
        baseline_value: number,
        persona_value: number,
        data_invalid?: boolean,
    }

    @Component({
        components: {}
    })
    class ChartAssociatedData extends Vue {
        Utils = Utils;
        @Prop({
            type: Object,
            default: () => {
            }
        })
        data;

        get associatedDataComponents(): AssociatedData {
            return Object.assign({}, this.defaultData, this.data)
        }

        get defaultData(): AssociatedData {
            return {
                // title: null,
                detail: 'UNKNOWN',
                value_format: 'decimal',
                value_format_decimals: 2,
                value_label: 'VALUE',
                positive_label: 'more',
                negative_label: 'less',
                baseline_label: 'Baseline',
                persona_label: 'Persona',
                baseline_value: 0,
                persona_value: 100
            }
        };

        get detailClass() {
            if (this.associatedDataComponents['detail_class'] || null) {
                // Allow override in case these are not persona vs baseline values
                return this.associatedDataComponents['detail_class'];
            }

            return this.associatedDataComponents['persona_value'] <= this.associatedDataComponents['baseline_value'] ?
                'value-less' :
                'value-greater';
        }

        get isDataValid() {
            if (this.data?.hasOwnProperty('data_invalid')) {
                return !this.data?.data_invalid;
            }

            return !isNaN(this.data?.baseline_value) && !isNaN(this.data?.persona_value);
        }

        associatedData(item) {
            switch (item) {
                case 'detail':
                    const componentMap = {
                        baseline_label: 'baseline_label',
                        label: 'value_label',
                        persona_label: 'persona_label',
                    };
                    let text = this.associatedDataComponents['detail'];

                    // Add in the value
                    let value = this.associatedDataComponents.value || false ? // Was a raw value supplied?
                        this.associatedDataComponents.value :
                        (this.associatedDataComponents.index || false) !== false ? // Use index calculation?
                            Utils.indexPercent(this.associatedDataComponents.index || 0) :
                            this.associatedDataComponents.persona_value - this.associatedDataComponents.baseline_value;
                    text = text.replace('{{ value }}', Utils.formatValue(
                        value,
                        this.associatedDataComponents['detail_format'] || this.associatedDataComponents['value_format']!,
                        this.associatedDataComponents['detail_format_decimals'] || this.associatedDataComponents['value_format_decimals'] || 2
                    ));

                    // Add direction label
                    let direction = this.associatedDataComponents.direction || false ? // Was a raw direction supplied?
                        this.associatedDataComponents[
                            this.associatedDataComponents.direction === 'left' ?
                                'positive_label' :
                                'negative_label'
                        ] :
                        this.associatedDataComponents[
                            value >= 0 ?
                                'positive_label' :
                                'negative_label'
                        ];
                    text = text.replace('{{ direction }}', direction!);

                    // Add standard labels
                    text = text.replace('{{ label }}', this.associatedDataComponents[componentMap.label]!);
                    text = text.replace('{{ baseline_label }}', this.associatedDataComponents[componentMap.baseline_label]!);
                    text = text.replace('{{ persona_label }}', this.associatedDataComponents[componentMap.persona_label]!);

                    const vowelizeTokenPattern = /{{ vowelize ([a-z_]+) "([^\|]*)\|([^"]+)" }}/; // TODO: move to a reusable place?
                    if (text.match(vowelizeTokenPattern)) {
                        Array.from(text.matchAll(new RegExp(vowelizeTokenPattern, 'g')))
                            .forEach(match => {
                                const [matchedPattern, token, ifConsonant, ifVowel] = match;
                                text = text.replace(matchedPattern, StaticUtils.vowelize(
                                    this.associatedDataComponents[componentMap[token]],
                                    ifConsonant,
                                    ifVowel
                                ));
                            });

                        // const [matchedPattern, token, ifConsonant, ifVowel] = text.match(vowelizeTokenPattern);
                        // text = text.replace(matchedPattern, StaticUtils.vowelize(
                        //     this.associatedDataComponents[componentMap[token]],
                        //     ifConsonant,
                        //     ifVowel
                        // ));
                    }
                    const ifTokenPattern = /{{ if ([a-z_]+) "([^"]+)" then "([^"]+)" }}/; // TODO: move to a reusable place?
                    if (text.match(ifTokenPattern)) {
                        Array.from(text.matchAll(new RegExp(ifTokenPattern, 'g')))
                            .forEach(match => {
                                const [matchedPattern, token, ifValue, thenText] = match;
                                text = text.replace(
                                    matchedPattern,
                                    this.associatedDataComponents[componentMap[token]].toLowerCase() === ifValue.toLowerCase() ? thenText : ''
                                );
                            });
                    }

                    return text;

                default:
                    return this.associatedDataComponents[item];
            }
        }
    }
    export default toNative(ChartAssociatedData);
</script>

<style scoped lang="scss" src="./chart-associated-data.scss"/>
