import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-014ee712"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "associated-chart-data text-center"
}
const _hoisted_2 = {
  key: 0,
  class: "title text"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isDataValid)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.associatedData('title'))
          ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.associatedData('title')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.associatedData('detail'))
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: _normalizeClass(_ctx.detailClass)
            }, [
              _createElementVNode("em", {
                innerHTML: _ctx.associatedData('detail')
              }, null, 8 /* PROPS */, _hoisted_3)
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.associatedDataComponents['baseline_label']) + ": " + _toDisplayString(_ctx.Utils.formatValue(_ctx.associatedDataComponents['baseline_value'], _ctx.associatedDataComponents['value_format'])), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.associatedDataComponents['persona_label']) + ": " + _toDisplayString(_ctx.Utils.formatValue(_ctx.associatedDataComponents['persona_value'], _ctx.associatedDataComponents['value_format'])), 1 /* TEXT */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}