<template>
    <section class="container-fluid">
        <div class="row">
            <div class="col-lg">
                <highcharts
                    constructor-type="mapChart"
                    :options="filteredChart"
                    style="overflow: visible !important;"
                />

                <slot name="afterChart" id="after_chart_slot"/>

                <!-- Controls -->
                <div v-if="showControls" class="text-center pb-3">
                    <!-- Show/hide data labels -->
                    <div class="btn-group select-group me-3" role="group">
                        <button class="btn select-blue"
                                :class="[{active: showDataLabels}]"
                                @click="showDataLabels = true"
                        >
                            Show Data Labels
                        </button>
                        <button class="btn select-blue"
                                :class="[{active: !showDataLabels}]"
                                @click="showDataLabels = false"
                        >
                            No Data Labels
                        </button>
                    </div>

                    <!-- Show specific values -->
                    <div class="btn-group select-group" role="group">
                        <button v-for="(button, index) of displayOptions" :key="index"
                                class="btn"
                                :class="[`select-${button.color}`, {active: displayGroup === button.group}]"
                                @click="displayGroup = button.group"
                        >
                            {{ button.label }}
                        </button>
                    </div>
                </div>
            </div>

            <!-- Detail sidebar -->
            <div :class="sidebarClass">
                <div v-if="showControls" class="btn-group select-group d-flex mb-3">
                    <button class="btn media-body w-100 select-green"
                            :class="[{active: sortGroup === 'index'}]"
                            @click="setSortGroup('index')"
                    >
                        Index
                    </button>
                    <button class="btn media-body w-100 select-blue"
                            :class="[{active: sortGroup === 'groupRatio'}]"
                            @click="setSortGroup('groupRatio')"
                    >
                        % of Persona
                    </button>
                </div>

                <div v-for="(detailSection, index) of detail" :key="index" class="card bg-blue-10 border-0 p-3 mb-3">
                    <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                    <div style="max-height: 17em; overflow: auto">
                        <table class="text-primary w-100">
                            <tr v-for="item of detailSection.items[sortGroup]"
                                :key="item.label">
                                <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Watch, Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import {nextTick} from 'vue';
    import Highcharts from 'highcharts';
    import {Chart} from 'highcharts-vue';
    import HighchartsMap from 'highcharts/modules/map';
    // import proj4 from 'proj4';
    import cloneDeep from 'lodash-es/cloneDeep';

    // https://stackoverflow.com/questions/49009504/how-to-import-proj4js-to-use-with-highmaps
    HighchartsMap(Highcharts);

    export const defaultDisplayOptions = [
        {
            group: 'low',
            label: 'Show Only Low',
            color: 'red',
        },
        {
            group: 'all',
            label: 'Show All Values',
            color: 'blue',
        },
        {
            group: 'high',
            label: 'Show Only High',
            color: 'green',
        },
    ];

    @Component<GeographicReport>({
        components: {
            highcharts: Chart,
        }
    })
    class GeographicReport extends Vue {
        // proj4Loaded: boolean = false;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        chartData: Chart;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        showDataLabels: boolean = true;
        sortGroup: string = 'index';
        displayGroup: string = 'all';

        // beforeMount() {
        //     if (window.proj4 === undefined) {
        //         window.proj4 = proj4; // or window.proj4
        //     }
        //     this.proj4Loaded = true;
        // }

        get displayOptions() {
            return this.params.displayOptions || defaultDisplayOptions;
        }

        /**
         * Allow filtering series to show only low or high values
         */
        get filteredChart() {
            let filteredChart = cloneDeep(this.chartData);

            // Use the low/high specifiers on the series to filter
            filteredChart.series.map(series => {
                series.visible = this.displayGroup === 'all' ?
                    true :
                    ['other', this.displayGroup].includes(series.seriesType || 'other');
            });

            if (!this.showDataLabels) {
                filteredChart.series.forEach(series => {
                    if (!series.hasOwnProperty('data')) {
                        return;
                    }
                    series.data.forEach(item => item.dataLabels = {enabled: false});
                });
            }

            return filteredChart;
        }

        get showControls() {
            return this.params.hasOwnProperty('showControls') ? this.params.showControls : true;
        }

        get sidebarClass() {
            return this.params.sidebarClass || 'col-lg-3';
        }

        setSortGroup(mode: string) {
            this.sortGroup = mode;
            this.$emit('set-sort-group', this.sortGroup);
        }

        @Watch('$route.params', {deep: true})
        async onParamsChanged() {
            if (this.params.tab === 'geography') {
                await nextTick(() => {
                    this.setSortGroup(this.sortGroup || 'index');
                });
            }
        }
    }
    export default toNative(GeographicReport);
</script>
