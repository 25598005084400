import {Tooltip} from 'bootstrap';
import {isEmptyObject} from 'Utilities/inspect';
import {concat, toInteger} from 'Utilities/utils';
import {DirectiveBinding} from 'vue';

const htmlRegex = /^html$/i
const noninteractiveRegex = /^noninteractive$/i
const noFadeRegex = /^nofade$/i
const placementRegex = /^(auto|top(left|right)?|bottom(left|right)?|left(top|bottom)?|right(top|bottom)?)$/i
const boundaryRegex = /^(window|viewport|scrollParent)$/i
const delayRegex = /^d\d+$/i
const delayShowRegex = /^ds\d+$/i
const delayHideRegex = /^dh\d+$/i
const offsetRegex = /^o-?\d+$/i
const variantRegex = /^v-.+$/i
const spacesRegex = /\s+/
const defaultTrigger = 'hover';
const defaultPlacement: string = 'top';
const config: any = {};

const processModifiers = (bindings: DirectiveBinding, vnode: any) => {
    try {
        if (!isEmptyObject(bindings)) {
            if (bindings.hasOwnProperty('modifiers') && !isEmptyObject(bindings.modifiers)) {
                for (const key of Object.keys(bindings.modifiers)) {
                    let mod = key;

                    if (htmlRegex.test(mod)) {
                        // Title allows HTML
                        config.html = true;
                    } else if (noninteractiveRegex.test(mod)) {
                        // Noninteractive
                        config.interactive = false;
                    } else if (noFadeRegex.test(mod)) {
                        // No animation
                        config.animation = false;
                    } else if (placementRegex.test(mod)) {
                        // Placement of tooltip
                        config.placement = mod ? mod : defaultPlacement;
                    } else if (boundaryRegex.test(mod)) {
                        // Boundary of tooltip
                        mod = mod === 'scrollparent' ? 'scrollParent' : mod;
                        config.boundary = mod;
                    } else if (delayRegex.test(mod)) {
                        // Delay value
                        const delay = toInteger(mod.slice(1), 0);
                        config.delay.show = delay;
                        config.delay.hide = delay;
                    } else if (delayShowRegex.test(mod)) {
                        // Delay show value
                        config.delay.show = toInteger(mod.slice(2), 0);
                    } else if (delayHideRegex.test(mod)) {
                        // Delay hide value
                        config.delay.hide = toInteger(mod.slice(2), 0);
                    } else if (offsetRegex.test(mod)) {
                        // Offset value, negative allowed
                        config.offset = toInteger(mod.slice(1), 0);
                    } else if (variantRegex.test(mod)) {
                        // Variant
                        config.variant = mod.slice(2) || null;
                    }
                }
            }
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

const processTriggers = (bindings: DirectiveBinding, vnode: any) => {
    try {
        if (!isEmptyObject(bindings)) {
            if (bindings.hasOwnProperty('modifiers') && !isEmptyObject(bindings.modifiers)) {
                // Special handling of event trigger modifiers trigger is
                // a space separated list
                const selectedTriggers = {};
                // Valid event triggers
                const validTriggers = {
                    focus: true,
                    hover: true,
                    click: true,
                    blur: true,
                    manual: true
                };

                // Parse current config object trigger
                concat(config.trigger || '')
                    .filter(x => x)
                    .join(' ')
                    .trim()
                    .toLowerCase()
                    .split(spacesRegex)
                    .forEach(trigger => {
                        if (validTriggers[trigger]) {
                            selectedTriggers[trigger] = true
                        }
                    })

                // Parse modifiers for triggers
                Object.keys(bindings.modifiers).forEach(mod => {
                    mod = mod.toLowerCase()
                    if (validTriggers[mod]) {
                        // If modifier is a valid trigger
                        selectedTriggers[mod] = true
                    }
                })

                // Sanitize triggers
                config.trigger = Object.keys(selectedTriggers).join(' ')
                if (config.trigger === 'blur') {
                    // Blur by itself is useless, so convert it to 'focus'
                    config.trigger = 'focus'
                }
                if (!config.trigger) {
                    // Use default trigger
                    config.trigger = defaultTrigger;
                }
            }
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const vTooltip = {
    // called before bound element's attributes
    // or event listeners are applied
    created(el: Element, binding: DirectiveBinding, vnode: any, prevVnode: any) {},
    // called right before the element is inserted into the DOM.
    beforeMount(el: Element, binding: DirectiveBinding, vnode: any) {},
    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el: Element, binding: DirectiveBinding, vnode: any) {
        processModifiers(binding, vnode);
        processTriggers(binding, vnode);

        let bsTooltip: Tooltip = new Tooltip(el, {
            placement: config.placement ? config.placement : defaultPlacement,
            trigger: config.trigger ? config.trigger : defaultTrigger,
            title: binding.value ? binding.value : ''
        });

        // TODO: Not sure why this is necessary with trigger set but they are sticking without it
        el.addEventListener('mouseout', () => {
            bsTooltip.hide();
        });
        el.addEventListener('blur', () => {
            bsTooltip.hide();
        });
    },
    // called before the parent component is updated
    beforeUpdate(el: Element, binding: DirectiveBinding, vnode: any, prevVnode: any) {},
    // called after the parent component and
    // all of its children have updated
    updated(el: Element, binding: DirectiveBinding, vnode: any, prevVnode: any) {
        processModifiers(binding, vnode); 
        processTriggers(binding, vnode);

        let bsTooltip: Tooltip = new Tooltip(el, {
            placement: config.placement ? config.placement : defaultPlacement,
            trigger: config.trigger ? config.trigger : defaultTrigger,
            title: binding.value ? binding.value : ''
        });

        // TODO: Not sure why this is necessary with trigger set but they are sticking without it
        el.addEventListener('mouseout', () => {
            bsTooltip.hide();
        });
        el.addEventListener('blur', () => {
            bsTooltip.hide();
        });
    },
    // called before the parent component is unmounted
    beforeUnmount(el: Element, binding: DirectiveBinding, vnode: any, prevVnode: any) {},
    // called when the parent component is unmounted
    unmounted(el: Element, binding: DirectiveBinding, vnode: any, prevVnode: any) {}
}
