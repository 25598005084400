import api from 'Server/api';
import { Account, useAccountStore } from 'Stores/account';
import { ListParameters } from 'Stores/common/models';
import { User, UserTimestamp } from 'Stores/user';
import { defineStore } from 'pinia';
import { useUserStore } from 'Stores/user';
import cloneDeep from 'lodash-es/cloneDeep';
import { axiosResponse, isEmptyObject, sortByProperty } from 'Utilities/utils';
import { usePersonaStore } from './persona';
import { AxiosResponse } from 'axios';

export interface FileMeta {
    isActive?: boolean;
    userId?: string;
    accountId?: string;
    account?: Account;
    id: string;
    name: string;
    type: string;
    filePathUri?: string;
    dataType?: string;
    lineCount?: number;
    tags?: string[];
    lastUsed?: string;
    created?: UserTimestamp;
    updated?: UserTimestamp;
    selected?: boolean;
    positive?: boolean;
    mode?: string;
}

export interface FileContentType {
    text: string,
    description: string,
    value: string,
    enabled: boolean
}

export interface Files {
    params: ListParameters,
    count: number,
    files: Array<FileMeta>
}

export interface FileState {
    file: FileMeta,
    files: Files,
}

export interface FileTypes {
    mime: string[];
    ext: string[];
}

export interface UploadMeta extends FileMeta {
    fileType?: string
}

export enum FileContentTypeValues {
    EMAIL_CLEARTXT = 'email',
    EMAIL_MD5 = 'emailMD5',
    EMAIL_SHA256 = 'emailSHA256',
    CUSTOM = 'custom',
    TWITTER_ID = 'twitterId',
    TWITTER_HANDLE = 'twitterHandle',
    PARTNER_ID = 'partnerId'
}

export enum FileMode {
    NEW = 'new',
    EDIT = 'edit'
}

export enum FileLoaders {
    PREP = 'preparing files',
    LOADING = 'loading file(s)',
    SELECTING = 'selecting file'
}

export const FileContentTypes: Array<FileContentType> = [
    {
        text: 'Email',
        description: 'Cleartext Email only',
        value: FileContentTypeValues.EMAIL_CLEARTXT,
        enabled: true
    },
    {
        text: 'MD5 email',
        description: 'MD5 Hash of Email only',
        value: FileContentTypeValues.EMAIL_MD5,
        enabled: true
    },
    {
        text: 'SHA256 email',
        description: 'SHA256 Hash of Email only',
        value: FileContentTypeValues.EMAIL_SHA256,
        enabled: true
    },
    {
        text: 'X ID (formerly Twitter ID)',
        description: 'X ID only (formerly Twitter ID)',
        value: FileContentTypeValues.TWITTER_ID,
        enabled: true
    },
    {
        text: 'X (fka Twitter) Handle',
        description: 'X (fka Twitter) Handle only',
        value: FileContentTypeValues.TWITTER_HANDLE,
        enabled: true
    },
    {
        text: 'ID',
        description: 'Individual ID only',
        value: FileContentTypeValues.PARTNER_ID,
        enabled: true
    },
    {
        text: 'Custom',
        description: 'Custom Layout (Name / Address / Email)',
        value: FileContentTypeValues.CUSTOM,
        enabled: false
    },
]

export const defaultFileMeta: FileMeta = { id: '', name: '', type: '' };

export const defaultFilesParameters: ListParameters = {
    limit: 10,
    offset: 0,
    sort: 'name',
    order: 'asc',
    search: '',
    append: false,
    dataType: '',
    fileType: '',
}

export const defaultFileTypes: FileTypes = {
    mime: [
        'csv',
        'text/csv',
        'text/x-csv',
        'application/vnd.ms-excel', // Windows csv :/
        'application/csv',
        'application/x-csv',
        'text/plain'
    ],
    ext: [ 'csv', 'txt' ]
};

export const noFilesAvailable: string = 'No available files to display';

export const useFileStore = defineStore('file', {
    state: (): FileState => ({
        file: {
            id: '',
            name: '',
            dataType: '',
            type: '',
            filePathUri: '',
            tags: [],
            lastUsed: '',
            created: {
                user: {
                    id: '',
                    firstName: '',
                    lastName: ''
                },
                timestamp: ''
            },
            updated: {
                user: {
                    id: '',
                    firstName: '',
                    lastName: ''
                },
                timestamp: ''
            }
        },
        files: {
            params: {
                limit: defaultFilesParameters.limit,
                offset: defaultFilesParameters.offset,
                sort: defaultFilesParameters.sort,
                order: defaultFilesParameters.order,
                search: ''
            },
            count: 0,
            files: [],
        },
    }),

    getters: {
        getFile: ( state: FileState ) => state.file,
        getFilesCount: ( state: FileState ) => state.files.count,
        getFilesSearchResults: (state: FileState) => state.files.files
    },

    actions: {
        clearFilesSearchResults() {
            try {
                const defaultParams: ListParameters = cloneDeep( defaultFilesParameters );
                this.setFilesSearchParameters(defaultParams);
                this.setFilesSearchResults([]);

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async deleteFile(file: FileMeta) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const response = await api.getAxiosInstance.delete( `/api/accounts/${accountId}/uploads/${file.id}` );
                const deleteResponse = response.data?.data;
                if (deleteResponse) {

                    return deleteResponse;
                } else {
                    console.error('ERROR; Could not delete file', file);

                    return false;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async getFileById( params: any = { shared: false } ) {
            try {
                const accountId: string = params.accountId || useUserStore().getUserCurrentAccountId;
                const shared: boolean = params.shared === true || params.shared === 'true';
                const queryString: string = shared ? `?isActive=true&shared=true` : `?isActive=true`;
                const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/uploads/${params.file.id}${queryString}` );
                const fileResponse = response.data?.data;

                if (fileResponse) {
                    return fileResponse;
                } else {
                    return false;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async getFileNameValidation({ value, dataType }) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const name: string = encodeURIComponent( value );
                let uri: string = '';

                if ( dataType ) {
                    uri = `/api/accounts/${accountId}/uploads/${dataType}/names/${name}`;
                } else {
                    uri = `/api/accounts/${accountId}/uploads/names/${name}`;
                }

                const response = await api.getAxiosInstance.get( uri );
                return response.data?.data;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async saveFileMetaData(meta: any) {
            try {
                const currentUser: User = useUserStore().getUser;
                let body: FileMeta = meta;
                body.userId = currentUser.id;
                let response: AxiosResponse<any> = axiosResponse();

                if ( meta.mode && meta.mode === FileMode.EDIT ) {
                    response = await api.getAxiosInstance.put( `/api/accounts/${currentUser.currentAccountId}/uploads/${meta.id}`, body );
                } else {
                    response = await api.getAxiosInstance.post( `/api/accounts/${currentUser.currentAccountId}/uploads/${meta.id}`, body );
                }

                if ( response.data ) {
                    return response.data;
                } else {
                    throw 'File save failed';
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async searchFiles(params: ListParameters) {
            try {
                this.setFilesSearchParameters(params);

                let { sort, order, limit, offset, search, dataType, fileType } = params;
                if ( dataType && typeof dataType !== 'string' && dataType.length > 0) {
                    dataType = dataType.join( ',' );
                }
                if ( fileType && typeof fileType !== 'string' && fileType.length > 0) {
                    fileType = fileType.join( ',' );
                }

                const currentUser: User = useUserStore().getUser;
                const listFilesParams: Object = { sort, order, limit, offset, search, dataType, fileType };
                const response: any = await api.getAxiosInstance.get( `/api/accounts/${currentUser.currentAccountId}/uploads`, { params: listFilesParams } );
                const listFiles = response.data?.data;

                if ( listFiles ) {
                    this.setFilesCount(listFiles.count || 0);
                    await this.setFilesDefaults(listFiles.files);

                    return ( this.files.count > 0 );
                } else {
                    console.warn( 'No list files' );
                    return false;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setFile(file: FileMeta) {
            try {
                this.file = file;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setFileTags(fileTags: Array<string>) {
            try {
                this.file.tags = fileTags;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setFilesCount(count: number) {
            try {
                this.files.count = count;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setFilesDefaults(files: Array<FileMeta>) {
            try {
                const selectedFiles: Array<FileMeta> = usePersonaStore().getListFiles;
                let parameters: ListParameters = cloneDeep(this.files.params);
                let filesArray: Array<any> = (parameters.append) ? cloneDeep(this.files.files) : [];

                for ( let i = 0; i < files.length; i++ ) {
                    const file: any = files[ i ];

                    let fileFilter: FileMeta = {
                        accountId: file.accountId,
                        id: file.id,
                        name: file.name,
                        type: file.type || file.fileType,
                        dataType: file.dataType,
                        lineCount: file.lineCount,
                        filePathUri: file.filePathUri,
                        tags: file.tags,
                        lastUsed: file.lastUsed,
                        created: file.created,
                        updated: file.updated
                    };

                    for ( let j = 0; j < selectedFiles.length; j++ ) {
                        const selectedFile: FileMeta = selectedFiles[ j ];

                        fileFilter.selected = ( selectedFile.id === fileFilter.id );
                        break;
                    }

                    filesArray.push( fileFilter );
                }

                const limit: number = defaultFilesParameters.limit || 0;
                const nextOffset: number = (files.length < limit) ? filesArray.length + (limit - files.length) : filesArray.length;
                parameters.offset = (files.length === 1) ? (nextOffset + 1) : nextOffset;

                if (parameters.append) {
                    filesArray = sortByProperty(filesArray, parameters.sort, parameters.order);
                }

                this.setFilesSearchResults(filesArray);

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setFilesSearchParameters(params: ListParameters) {
            try {
                if ( isEmptyObject( params ) ) {
                    this.files.params = defaultFilesParameters;
                } else {
                    this.files.params = params;
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setFilesSearchResults(files: Array<FileMeta>) {
            try {
                this.files.files = files;

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async uploadFile(file: any) {
            try {
                const currentUser: User = useUserStore().getUser;
                let formData: FormData = new FormData();
                let response: AxiosResponse<any> = axiosResponse();
                let endpoint = `/api/accounts/${currentUser.currentAccountId}/uploads`;

                formData.append('file', file);
                formData.append('createdById', currentUser.id);
                if (file.overwriteId) {
                    formData.append('overwriteId', file.overwriteId);
                }
                if (file.representativePhoto === true) {
                    endpoint = `${endpoint}?representativePhoto=true`;
                }

                response = await api.getAxiosInstance.post(endpoint, formData, {
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity
                });

                if (response.data) {
                    return response.data;
                } else {
                    throw 'File upload failed';
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }
})
