import moment from 'moment';
import { getCookieValue } from 'Utilities/utils';
import {
    currentTimestamp,
    defaultSessionCountdownSeconds,
    futureTimestamp
} from 'Utilities/time-utils';
import {sessionTimerSourceName, UserSession} from 'Stores/user';
import {Actions} from 'Utilities/immutables';

const sessionWorker: Worker = self as any;
let session: UserSession = { active: Boolean( window.localStorage.getItem( 'pbuser' ) ) || false };
let timer: any;

function resetSession(session: any) {
    if (typeof session === 'object' && session.source === sessionTimerSourceName && session.action) {
        session.action = 'initialize';
    }
}

function initializeSessionTimer(session: any) {
    if (typeof session === 'object' && session.source === sessionTimerSourceName) {
        // console.debug(`Initialize session timer: ${session.timeout} sec. timeout\r\n`, JSON.stringify(session));
        session.startTime = new Date();
        session.action = 'initialized';
        session.countdown = defaultSessionCountdownSeconds;
        session.expiration = futureTimestamp(new Date(getCookieValue('pbAuthAt')), 'seconds', session.timeout || 0);
        // console.debug(`📜 PB session timer expires at ${(new Date(session.expiration)).toLocaleTimeString()}:`, JSON.stringify(session));
        window.localStorage.setItem('pbexp', session.expiration);
        if (!timer && session.route && !session.route.includes('share') && !session.route.includes('userRegister')) {
            timer = setInterval(() => {
                session.route = window.location.hash.replace('#/', '');
                session.route = session.route.includes('login') ? 'login' : session.route;

                if (!session.noAuthRoutes.includes(session.route)) {
                    let expiration: string = (window.localStorage.getItem('pbexp') || '').toString();

                    if (getCookieValue('pbAuthAt') && expiration) {
                        let now = currentTimestamp();
                        let countdown: number = moment(expiration).diff(now, 'seconds');

                        if (moment(expiration).isBefore(now, 'seconds')) {
                            if (countdown <= 0 && countdown > -defaultSessionCountdownSeconds) {
                                // console.debug(`⏰ Show session countdown timer: expiration "${expiration}" (${new Date(window.localStorage.getItem('pbexp')).toLocaleTimeString()}))`, JSON.stringify(session));
                                session.action = 'countdown';
                                session.countdown = defaultSessionCountdownSeconds - (-countdown);
                            } else if (countdown <= -defaultSessionCountdownSeconds) {
                                clearSessionTimer(session);
                                session.action = Actions.LOGOUT_PENDING;
                            }
                        } else {
                            session.now = now.toString();
                            session.action = 'in-progress';
                        }
                    } else {
                        clearSessionTimer(session);
                        session.action = Actions.LOGOUT_PENDING;
                    }

                    if (session.action === Actions.LOGOUT_PENDING) {
                        sessionWorker.postMessage(session);

                        setTimeout(function () {
                            session.action = Actions.LOGOUT;
                            sessionWorker.postMessage(session);
                        }, 10000);
                    } else {
                        sessionWorker.postMessage(session);
                    }
                } else {
                    clearSessionTimer(session);
                }
            }, 1000);
        }
    }
}

function clearSessionTimer(session: any) {
    clearInterval(timer);
    timer = null;
    resetSession(session);
}

sessionWorker.addEventListener('message', function (event) {
    session = event.data;

    if (typeof session === 'object' && session.source === sessionTimerSourceName && session.hasOwnProperty('active')) {
        if (session.active) {
            switch (session.action) {
                case 'initialize':
                    clearSessionTimer(session);
                    // setTimeout(function () {
                        initializeSessionTimer(session);
                    // }, 3000);
                    break;
            }
        } else {
            clearSessionTimer(session);
        }
    }

}, false);

export default sessionWorker as any;
