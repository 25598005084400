<template>
    <div class="page-header w-100 d-flex justify-content-between align-items-center">
        <div class="page-header-left text-start">
            <div v-if="content.hasOwnProperty('left')" v-html="content.left"></div>
        </div>

        <div class="page-header-center text-center">
            <div v-if="content.hasOwnProperty('center')" v-html="content.center"></div>
        </div>

        <div class="page-header-right text-end">
            <div v-if="content.hasOwnProperty('right')" v-html="content.right"></div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';

    @Component<PageHeader>({
        components: {
        }
    })
    class PageHeader extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        content: any
    }
    export default toNative(PageHeader);
</script>
