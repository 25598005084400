import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-content-between",
  "data-intent": "extra-features"
}
const _hoisted_2 = ["alt", "onerror", "src"]
const _hoisted_3 = { class: "flex-shrink-1 d-flex align-items-center" }
const _hoisted_4 = { class: "p-2" }
const _hoisted_5 = { class: "dropdown dropdown-bubble" }
const _hoisted_6 = {
  class: "p-2 show-orange",
  id: "navGeneralFunctions",
  "data-bs-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_7 = {
  class: "dropdown-menu dropdown-menu-end arrow-top",
  "aria-labelledby": "navGeneralFunctions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      alt: _ctx.account ? _ctx.account.name : '',
      class: "px-3",
      "data-intent": "client-logo",
      onerror: _ctx.Utils.imageFallback(_ctx.defaultBrandLogoPath),
      src: _ctx.logoPath
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createBlock(_component_router_link, {
          to: {
                        name: 'help',
                        params: {
                            tab: _ctx.helpTabs.FAQ,
                        },
                    },
          target: "_blank",
          title: `Open Help in new tab`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              "fixed-width": "",
              icon: "circle-question"
            })
          ], undefined, true),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])), [
          [_directive_tooltip, void 0, "bottom"]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_font_awesome_icon, {
            "fixed-width": "",
            icon: "ellipsis-v"
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            class: "dropdown-item",
            to: {name: 'topicLibrary'}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Topic Library ")
            ], undefined, true),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_router_link, {
            class: "dropdown-item",
            to: {name: 'listLibrary'}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Upload Library ")
            ], undefined, true),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_router_link, {
            class: "dropdown-item",
            to: { name: 'userFeedback' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Send Feedback ")
            ], undefined, true),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_router_link, {
            class: "dropdown-item item-secondary",
            to: {name: 'account', params: {tab: _ctx.accountTabs.SUMMARY}}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['solid', 'gear'],
                size: "lg"
              }),
              _createTextVNode(" Account Settings ")
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]),
          _createVNode(_component_router_link, {
            class: "dropdown-item item-secondary",
            to: {name: 'user', params: {accountId: _ctx.account.id}}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['solid', 'gear'],
                size: "lg"
              }),
              _createTextVNode(" User Profile ")
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]),
          _createElementVNode("a", {
            class: "dropdown-item item-danger",
            href: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)), ["prevent"]))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['solid', 'right-from-bracket'],
              size: "lg"
            }),
            _createTextVNode(" Log Out ")
          ])
        ])
      ])
    ])
  ]))
}