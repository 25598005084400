<template>
    <div class="mb-n4" data-intent="summary-widget">
        <div v-for="(item, index) of summaryItems" :key="index"
             class="brand-interest-summary mb-4"
        >
            <div class="topic-description row align-items-end">
                <div
                    class="col fw-bold overflow-ellipsis static"
                    :title="itemTitle(item)"
                    v-tooltip
                >
                    {{ itemTitle(item) }}
                </div>
            </div>
            <div
                class="group-size-display mb-2"
                :title="tooltipText(item)"
                v-tooltip
            >
                <div v-if="item"
                     class="group-size-indicator"
                     :style="`left: ${itemPercent(item)}%`"
                >&#9650;
                </div>
            </div>
<!--            <div class="d-flex justify-content-between">-->
<!--                <img v-if="!item.topByIndex.length"-->
<!--                     class="img-fluid"-->
<!--                     data-intent="summary-interest-icon"-->
<!--                     :src="blankImagePath"-->
<!--                />-->
<!--                <img v-for="topItem of item.topByIndex.slice(0, 5)"-->
<!--                     :alt="topItem.displayName"-->
<!--                     class="img-fluid"-->
<!--                     data-intent="summary-interest-icon"-->
<!--                     :onerror="Utils.imageFallback('twitter')"-->
<!--                     :src="topItem.imageUrl"-->
<!--                     :title="topItem.displayName"-->
<!--                />-->
<!--            </div>-->
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import * as Utils from 'Utilities/utils';

    @Component
    class SummaryWidget extends Vue {
        @Prop({
            type: Array,
            default: () => [],
        })
        summaryItems: [];

        @Prop({
            type: String,
        })
        summaryType: string;

        @Prop({
            type: String,
        })
        tooltipPattern: string;

        Utils = Utils;
        blankImagePath = '/assets/images/transparent.png';

        itemPercent(item: any) {
            // return item.groupRatio.toFixed(1);
            return ((((item.stdDev * 3) + (item.zScore * item.stdDev)) / (item.stdDev * 6)) * 100).toFixed(1);
        }

        itemTitle(item: any) {
            return item?.category ? `${item.category}${item.subCategory ? `: ${item.subCategory}` : ''}` : item?.name
        }

        tooltipText(item: any) {
            return null;
            // return item.index;
            // return this.tooltipPattern
            //     .replace(
            //         '[[ percent ]]',
            //         Utils.formatValue(this.itemPercent(item), 'percent', 0)
            //     );
        }
    }
    export default toNative(SummaryWidget);
</script>

<style lang="scss">
    @import '../../app/styles/variables';

    .brand-interest-summary {
        &:not(:first-child) {
            margin-top: 0.85rem;
        }

        [data-intent="summary-interest-icon"] {
            height: auto;
            max-width: 48px;
        }

        .summary-top-item {
            border-left: solid 2px $primary;
            font-size: 0.9rem;
        }
    }
</style>
