import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "mb-n4",
  "data-intent": "summary-widget"
}
const _hoisted_2 = { class: "topic-description row align-items-end" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "brand-interest-summary mb-4"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "col fw-bold overflow-ellipsis static",
            title: _ctx.itemTitle(item)
          }, [
            _createTextVNode(_toDisplayString(_ctx.itemTitle(item)), 1 /* TEXT */)
          ], 8 /* PROPS */, _hoisted_3)), [
            [_directive_tooltip]
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "group-size-display mb-2",
          title: _ctx.tooltipText(item)
        }, [
          item
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "group-size-indicator",
                style: _normalizeStyle(`left: ${_ctx.itemPercent(item)}%`)
              }, "▲ ", 4 /* STYLE */))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_4)), [
          [_directive_tooltip]
        ]),
        _createCommentVNode("            <div class=\"d-flex justify-content-between\">"),
        _createCommentVNode("                <img v-if=\"!item.topByIndex.length\""),
        _createCommentVNode("                     class=\"img-fluid\""),
        _createCommentVNode("                     data-intent=\"summary-interest-icon\""),
        _createCommentVNode("                     :src=\"blankImagePath\""),
        _createCommentVNode("                />"),
        _createCommentVNode("                <img v-for=\"topItem of item.topByIndex.slice(0, 5)\""),
        _createCommentVNode("                     :alt=\"topItem.displayName\""),
        _createCommentVNode("                     class=\"img-fluid\""),
        _createCommentVNode("                     data-intent=\"summary-interest-icon\""),
        _createCommentVNode("                     :onerror=\"Utils.imageFallback('twitter')\""),
        _createCommentVNode("                     :src=\"topItem.imageUrl\""),
        _createCommentVNode("                     :title=\"topItem.displayName\""),
        _createCommentVNode("                />"),
        _createCommentVNode("            </div>")
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}