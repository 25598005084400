import * as StaticUtils from "./utils-static"
import {isValidDateObj} from "./validators";

export const dateFormat = StaticUtils.dateFormat;
export const getLocalTimeZoneByAbbr = StaticUtils.getLocalTimeZoneByAbbr;
export const getSecondsFromMinutes = StaticUtils.getSecondsFromMinutes;
export const systemTimeZoneAbbr = StaticUtils.systemTimeZoneAbbr;
export const utcTimeUnitSince = StaticUtils.utcTimeUnitSince;

export const defaultSessionTimeoutSeconds: number = 3600;
export const defaultSessionCountdownSeconds: number = 60;

export const currentTimestamp = () => {
    return new Date().toISOString();
}

export const futureTimestamp = (date: Date, granularity: string, value: number) => {
    try {
        if (isValidDateObj(date) && granularity) {
            switch (granularity) {
                case 'minute':
                case 'minutes':
                    return new Date(date.setMinutes(date.getMinutes() + value)).toISOString();
                case 'second':
                case 'seconds':
                    return new Date(date.setSeconds(date.getSeconds() + value)).toISOString();
                default:
                    return new Date().toISOString();
            }
        }
    } catch (error) {
        console.error(error.stack);
    }
}

export const pastTimestamp = (date: Date, granularity: string, value: number) => {
    try {
        if (isValidDateObj(date) && granularity) {
            switch (granularity) {
                case 'minute':
                case 'minutes':
                    return new Date(date.setMinutes(date.getMinutes() - value)).toISOString();
                case 'second':
                case 'seconds':
                    return new Date(date.setSeconds(date.getSeconds() - value)).toISOString();
                default:
                    return new Date().toISOString();
            }
        }
    } catch (error) {
        console.error(error.stack);
    }
}
