import Vue from 'vue';
import {AxiosResponse} from 'axios';
import {defineStore} from 'pinia';
import cloneDeep from 'lodash-es/cloneDeep';
import api from 'Server/api';
import {useAccountStore} from 'Stores/account';
import {Activity as ActivityInterface, defaultActivity} from 'Stores/activity';
import {PhotoAssignment, PhotoCharacteristics} from 'Stores/common/models';
import {FileMeta, useFileStore} from 'Stores/file';
import {useJobStore, Job as JobInterface} from 'Stores/job';
import {User, UserPhoto, defaultUserTimestamp, useUserStore} from 'Stores/user';
import {Operators} from 'Utilities/immutables';
import {isEmptyString, isUndefinedOrNullOrEmpty} from 'Utilities/inspect';
import {
    axiosResponse,
    dereference,
    formatValue,
    isEmptyArray,
    sortByProperty
} from 'Utilities/utils';

export interface AudienceState {
    audience: Audience;
    originalAudience: any;
    selectableDemographics: Array<any>;
    selectableGeographics: Array<any>;
    activity: any;
    demographics: any;
    geographics: any;
    personaJob: any;
    tuningProfile: AudienceTuningProfile;
    apiAudience: any;
    newAudience: any;
}

export interface AudienceSegment {
    id: string;
    name: string;
    individualCount: number;
    recordCount: number;
    offlineReport: any;
    strategy: string;
    strategyValues: number[];
}

export interface Audience {
    id: string;
    accountId: string;
    personaId: string;
    personaJobId: string;
    userId: string;
    name: string;
    type: string;
    isDraft: boolean;
    mode: string;
    photos?: PhotoAssignment[];
    photoCharacteristics?: PhotoCharacteristics;
    userPhoto: UserPhoto | null;
    tags: string[];
    selectsAndOmits: {
        omit: any;
        select: any;
    },
    lists: any;
    tuningProfileId: string;
    tuningProfile: {
        id: string;
        characteristicSuppression: any;
    },
    uploads: any;
    segments: AudienceSegment[];
    segmentStrategy: any[];
    sensitiveSelectsAndOmits?: boolean;
    suppressionUpload: {
        id: string;
    },
    suppressionUploadId: string;
    distributions: any[];
    updated: any;
    completedAt?: string;
    fetchedAt: string;
}

export interface AudienceTuningProfile {
    id: string;
    description: string;
    hoverText: string;
    displayOrder: number;
    consumerSpendEnabled: boolean;
    highLevelRFMEnabled: boolean;
    characteristicSuppression: object;
    spendIntensityModifier: number;
    active: boolean;
}

export enum AudienceMode {
    NEW = 'new',
    DRAFT = 'draft',
    BUILD = 'build',
    REBUILD = 'rebuild',
    EDIT = 'edit',
    DELETE = 'delete',
    RESET = 'reset',
    REPORT = 'report',
    DEFAULTS = 'defaults'
}

export enum AudienceLoaders {
    FILES = 'loading files',
    PREP = 'preparing audience build',
    SAVING = 'saving audience',
    VALIDATING = 'validating audience'
}

export enum AudienceTabs {
    // Tabs
    CONFIRMATION = 'confirmation',
    PERSONA = 'persona',
    REFINE = 'refine',
    TUNE = 'tune',

    // Sections
    REACHPRECISION = 'reach-precision',
    TARGETMARKET = 'target-market',
    DEMOGRAPHIC = 'demographic',
    GEOGRAPHIC = 'geographic',
    // SELECTOMIT = 'select-omit',
    LISTS = 'lists',
}

export const geographicFields = ['dma_code', 'postal_code', 'region', 'state'];
export const segmentStrategyTypesEditable = [
    'positivePercentiles',
    'positiveScoreRecordCount',
    'positiveVentiles',
];
export const segmentStrategyTypesPercent = [
    'positivePercentiles',
    'positiveVentiles',
];
export const segmentVisualizations: any[] = [
    {
        defaultCount: 10000,
        defaultPercent: 15,
        position: 1,
        label: 'Top',
        tooltip: 'This portion of the population is predicted to be the most responsive',
        selectable: true,
    },
    {
        defaultCount: 10000,
        defaultPercent: 30,
        position: 2,
        label: 'Next',
        tooltip: 'This portion of the population is predicted to be the next most responsive',
        selectable: true,
    },
    {
        defaultCount: 10000,
        defaultPercent: 45,
        position: 3,
        label: 'Subsequent',
        tooltip: 'This portion of the population is predicted to be responsive, but not as strongly',
        selectable: true,
    },
    {
        position: 4,
        label: 'Eliminated',
        tooltip: 'This portion of the population is not selected for this Audience',
        selectable: false,
    },
];
export const selectOmitContexts = [
    {
        position: 2,
        property: 'omit',
        value: 'omitted'
    },
    {
        position: 1,
        property: 'select',
        value: 'selected'
    },
];
export const tierColorCount = 7;

/** @deprecated **/
export const TierGroups = [
    {
        label: 'Best [DEPRECATED]',
        segments: [20, 19, 18], // TODO: OVERWRITE FROM ACCOUNT STRATEGY!
    },
    {
        label: 'Better [DEPRECATED]',
        segments: [17, 16, 15, 14, 13, 12], // TODO: OVERWRITE FROM ACCOUNT STRATEGY!
    },
    {
        label: 'Good [DEPRECATED]',
        segments: [11, 10, 9, 8, 7, 6, 5, 4, 3], // TODO: OVERWRITE FROM ACCOUNT STRATEGY!
    },
];

export const defaultAudience: Audience = {
    id: '',
    accountId: '',
    personaJobId: '',
    name: '',
    type: '',
    isDraft: false,
    currentPhoto: {
        id: '',
        filePathUri: ''
    },
    tags: [], // TODO: not in the API?
    selectsAndOmits: {
        omit: {
            offline: {},
        },
        select: {
            offline: {},
        },
    },
    personaDetails: '',
    segmentStrategy: [],

    // POST only
    userId: '',
    tuningProfileId: '',
    suppressionUploadId: '',

    // GET only
    personaId: '',
    sensitiveSelectsAndOmits: false,
    status: {
        statusCode: 0,
        timestamp: '',
    },
    tuningProfile: {
        id: '',
        description: '',
        hoverText: '',
        displayOrder: 0,
        characteristicSuppression: null,
        consumerSpendScore: null,
        spendIntensityModifier: null,
    },
    candidateDatasetPath: null,
    scoreAnalysis: null,
    lists: {
        operator: Operators.OR,
        definitions: []
    },
    created: cloneDeep(defaultUserTimestamp),
    updated: cloneDeep(defaultUserTimestamp),
    completedAt: '',
    // UX use only - not supported in API
    mode: '',
    uploads: {},
    userPhoto: {
        id: '',
        name: '',
        filePathUri: ''
    }
}

export const useAudienceStore = defineStore('audience', {
    state: (): AudienceState => ({
        audience: cloneDeep(defaultAudience),
        demographics: {},
        geographics: {},
        personaJob: {
            id: '',
            persona: {
                id: ''
            },
        },
        tuningProfile: {} as AudienceTuningProfile,
        apiAudience: {
            id: '',
            userId: '',     // Temp until post audience no longer needs
            accountId: '',  // Temp until post audience no longer needs
            account: {
                id: '',
                name: 'Delivery Testing',
            },
            created: cloneDeep(defaultUserTimestamp),
            updated: cloneDeep(defaultUserTimestamp),
            name: '',
            isDraft: false,
            segmentStrategy: [],
            tuningProfileId: '',
            tags: [''],
            selectsAndOmits: {
                omit: {
                    offline: {},
                },
                select: {
                    offline: {},
                }
            },
            suppressionUploadId: '',
            personaJobId: '',
            distributions: [],
            fetchedAt: ''
        },
        newAudience: {},
        originalAudience: {
            suppressionUpload: {},
            selectsAndOmits: {
                omit: {
                    offline: {},
                },
                select: {
                    offline: {},
                }
            },
            fetchedAt: '',
        },
        selectableDemographics: [],
        selectableGeographics: [],
        activity: {}
    }),

    getters: {
        getAudienceActivity: (state: AudienceState) => state.activity,
        getAudience: (state: AudienceState) => state.audience,
        getAudienceListFiles(state: AudienceState) {
            // Note: For now there will only be 1 list per audience
            return state.audience?.lists?.definitions || [];
        },
        getAudienceDemographics: (state: AudienceState) => state.demographics,
        getAudiencePersonaJob: (state: AudienceState) => state.personaJob,
        getAudienceSelectableDemographics: (state: AudienceState) => state.selectableDemographics,
        getAudienceSelectableGeographics: (state: AudienceState) => state.selectableGeographics,
        getAudienceSelectsAndOmits() {
            // Sort selects and omits by characteristic position
            let sortedSelectsAndOmits: any = {};
            for (const {property} of selectOmitContexts) {
                let keys = Object.keys(this.audience.selectsAndOmits[property]?.offline || {});
                let characteristicArray = keys
                    .map(key => this.audience.selectsAndOmits[property].offline[key])
                    .sort((a, b) =>
                        a[0].parentPosition > b[0].parentPosition ? 1 : -1
                    );

                // Convert the array back into an object
                let sortedCharacteristics: Object = {};
                for (const characteristic of characteristicArray) {
                    sortedCharacteristics[characteristic[0].parent] = sortByProperty(characteristic, 'position', 'asc');
                }

                sortedSelectsAndOmits[property] = {offline: sortedCharacteristics};
            }

            return sortedSelectsAndOmits;
        },
        getAudienceTuningProfile: (state: AudienceState) => state.tuningProfile,
        getOriginalAudience: (state: AudienceState) => state.originalAudience,
    },

    actions: {
        applyAudienceSelectsAndOmits() {
            try {
                for (const field of this.selectableDemographics) {
                    for (let filter of field.values) {
                        if (filter.omitted || filter.selected) {
                            this.setAudienceDemographicFilter({field: field.name, filter});
                        }
                    }
                }

                for (const geographic of this.selectableGeographics) {
                    const selections = this.geographics[geographic.field];
                    if (selections) {
                        const filter = {
                            field: geographic.field,
                            context: selections[0].omitted ? 'omit' : 'select',
                        };
                        this.setAudienceGeographicFilter({filter, selections});
                    }
                }

                return true;
            } catch (error: any) {
                console.error(error.stack);
                return false;
            }
        },

        audienceHasSelectsAndOmits(audience: Audience) {
            return Object.keys(audience.selectsAndOmits?.omit?.offline || {}).length
                || Object.keys(audience.selectsAndOmits?.select?.offline || {}).length;
        },

        clearAudienceState() {
            try {
                useJobStore().setJobId(''); // VERY important! leftover job IDs cause massive issues!

                this.audience = cloneDeep(defaultAudience);
                this.originalAudience = {};
                this.clearAudienceActivityState();
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        clearAudienceActivityState() {
            try {
                this.setAudienceActivity(cloneDeep(defaultActivity));
                this.personaJob = {};
                this.audience.personaJobId = '';
                this.audience.segmentStrategy = [];
                this.audience.lists.definitions = [];
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        clearAudienceDemographicFilters() {
            try {
                this.selectableDemographics = [];
                // this.selectableGeographics = [];
                this.demographics = {};
                // this.audience.selectsAndOmits = {select: {offline: {}}, omit: {offline: {}}};
                this.clearAudienceSelectsAndOmits('demographic');
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        clearAudienceGeographicFilters() {
            try {
                // this.selectableDemographics = [];
                this.selectableGeographics = [];
                // this.demographics = {};
                // this.audience.selectsAndOmits = {select: {offline: {}}, omit: {offline: {}}};
                this.clearAudienceSelectsAndOmits('geographic');
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        clearAudienceSelectsAndOmits(mode: string, stateType: string = 'audience') {
            if (!this[stateType]) {
                console.error(`No state found for "${stateType}"`);
                return;
            } else if (!this[stateType].hasOwnProperty('selectsAndOmits')) {
                this[stateType].selectsAndOmits = {omit: {offline: {}}, select: {offline: {}}};
            }

            for (const {property} of selectOmitContexts) {
                let filterContext = this[stateType].selectsAndOmits[property].offline;
                for (const characteristicName of Object.keys(filterContext)) {
                    const isGeographicCharacteristic = geographicFields.includes(characteristicName);
                    const deleteCharacteristic = mode === 'geographic' ?
                        isGeographicCharacteristic :
                        !isGeographicCharacteristic;

                    if (deleteCharacteristic) {
                        delete filterContext[characteristicName];
                    }
                }
            }
        },

        async deleteAudience(params: any) {
            let deleted: boolean = false;

            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const response = await api.getAxiosInstance.delete(`/api/accounts/${accountId}/audiences/${params.id}`);

                if (response.data) {
                    deleted = true;
                }
            } catch (error) {
                console.error(error);
                return false;
            }

            return deleted;
        },

        deselectAudienceFile(file: FileMeta, context: string = 'definitions') {
            this.audience.lists[context] = this.audience.lists[context]
                .filter((listFile: FileMeta) => listFile.id !== file.id);
        },

        async generateAudienceShareToken({audienceId, personaJob}) {
            const body: any = {};

            try {
                const response = await api.getAxiosInstance.post(`/api/accounts/${personaJob.persona?.account.id}/personas/${personaJob.persona.id}/jobs/${personaJob.id}/audiences/${audienceId}/share`, body);

                return response.data?.data || {};
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getAudienceById(params: any = {shared: false}) {
            try {
                const accountId = params.accountId || useAccountStore().getActiveAccount.id;
                const shared: boolean = params.shared === 'true';
                const queryString: string = shared ? `?shared=true` : ``;
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/audiences/${params.id}${queryString}`);
                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        getAudienceCharacteristicObject(characteristicArray: Array<any>) {
            // Set sorted characteristic array back to object
            let sortedCharacteristic: Object = {};

            try {
                // for (let j = 0; j < characteristicArray.length; j++) {
                //     let characteristic = characteristicArray[j];
                for (const characteristic of characteristicArray) {
                    if (isEmptyArray(characteristic)) {
                        continue;
                    }
                    let valueArray: any = [];
                    let sortedObject: Object = {};

                    // for (let k = 0; k < characteristic.length; k++) {
                    for (const characteristicValue of characteristic) {
                        // let characteristicValue = characteristic[k];
                        let delimiter: String = characteristic.footprint.values === '' ? '' : ', ';

                        if (characteristicValue.footprintDescription != null) {
                            characteristic.footprint.description = characteristicValue.footprintDescription;
                        }

                        valueArray.push(characteristicValue);
                        characteristic.footprint.values += delimiter + characteristicValue.shortDescription;
                    }

                    // Set sorted value array and footprint property in new object and make reactive
                    sortedObject = valueArray;
                    sortedObject['footprint'] = characteristic.footprint;
                    sortedCharacteristic[characteristic[0]?.parent] = sortedObject;
                }
            } catch (error) {
                console.error(error);
                throw error;
            }

            return sortedCharacteristic;
        },

        async getAudienceDistributionConfirmation({accountId, confirmationId}) {
            try {
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/audiences/distributions/confirmations/${confirmationId}`);

                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getAudienceNameValidation(value: string) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const name: string = encodeURIComponent(value);
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/audiences/names/${name}`);

                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        getAudienceSegmentLabel({position, segments}) {
            try {
                const index = position - 1;

                // 1 - Find segment at this position
                const segment = segments[index];
                if (!segment) {
                    // This position is out of range for the account segment strategy
                    return 'Eliminated';
                }

                if (!segmentStrategyTypesEditable.includes(segment.strategy)) {
                    return 'Custom';
                }

                const segmentPrefix = segmentVisualizations.find(segmentVisualization => segmentVisualization.position === position)?.label;
                if (segmentStrategyTypesPercent.includes(segment.strategy)) {
                    // 2 - Combine tier label with percentage value
                    let percentValue = useAudienceStore().getAudienceSegmentPercentage(segment);

                    return `${segmentPrefix} ${percentValue}%`;
                }

                // Count-based segment
                return `${segmentPrefix} ${formatValue(segment.strategyValues[0], 'separated')}`;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        getAudienceSegmentPercentage(segment: any) {
            try {
                let percentValue = 0;
                switch (segment.strategy) {
                    case 'positivePercentiles':
                        percentValue = (segment.strategyValues[0] - segment.strategyValues[1]) + 1;
                        break;

                    case 'positiveVentiles':
                        percentValue = segment.strategyValues.length * 5;
                        break;
                }

                return percentValue;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        mapAudienceDefinitionApi(audience: Audience) {
            try {
                let newAudience: any = cloneDeep(this.apiAudience);
                (audience as any).isDraft = ((audience as any).mode === AudienceMode.DRAFT) ? true : false;
                newAudience.userId = audience.userId;
                newAudience.accountId = audience.accountId;
                newAudience.personaId = audience.personaId;
                newAudience.personaJobId = audience.personaJobId;
                newAudience.name = audience.name;
                newAudience.isDraft = audience.mode === AudienceMode.DRAFT ? true : false;
                newAudience.tags = audience.tags;
                newAudience.userPhoto = audience.userPhoto;

                // Select & Omit
                for (const context of selectOmitContexts.map(item => item.property)) {
                    for (const key of Object.keys(audience.selectsAndOmits[context]?.offline || {})) {
                        newAudience.selectsAndOmits[context].offline[key] = audience.selectsAndOmits[context].offline[key].map(item => item.value);
                    }
                }

                newAudience.segmentStrategy = audience.segmentStrategy.map((segment, index) => {
                    segment.name = `${index + 1}`;
                    return segment;
                });
                newAudience.type = 'MODELED'; // hard-coded
                newAudience.tuningProfileId = audience.tuningProfileId || audience.tuningProfile?.id || this.tuningProfile?.id;
                if (audience.lists?.definitions?.length) {
                    newAudience.suppressionUploadId = audience.lists.definitions[0].id;
                }

                // Remove UX-only params
                delete (newAudience.mode);
                delete (newAudience.uploads);
                delete (newAudience.lists);

                // Remove GET-only params
                delete (newAudience.account);
                delete (newAudience.created);
                delete (newAudience.updated);

                if (audience.mode === AudienceMode.EDIT || (newAudience)) {
                    newAudience.fetchedAt = audience.fetchedAt;
                }

                if (!audience.id && newAudience.hasOwnProperty('id')) {
                    delete newAudience.id;
                }

                this.newAudience = newAudience;

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        getAudienceIdByMode(audience: Audience) {
            switch (audience.mode) {
                case AudienceMode.DRAFT:
                    if (!audience.isDraft) {
                        // Save new draft from existing audience (clear id) or continue (no id) to save new draft from scratch
                        return !isEmptyString(audience.id) ? '' : audience.id;
                    } else {
                        // Overwrite existing draft
                        return audience.id;
                    }
                case AudienceMode.BUILD:
                case AudienceMode.EDIT:
                    // Audience builds, or edit/rebuilds that are not draft edit/rebuilds should POST a new Audience;
                    return '';
                default:
                    return audience.id;
            }
        },

        async saveAudience(audience: Audience) {
            try {
                const currentUser: User = useUserStore().getUser;
                let newAudience: Audience = audience;

                newAudience.id = this.getAudienceIdByMode(audience);
                newAudience.userId = currentUser.id;
                newAudience.accountId = currentUser.currentAccountId;
                newAudience.personaJobId = this.personaJob.id;
                newAudience.personaId = this.personaJob.persona.id;
                this.audience = newAudience;
                this.mapAudienceDefinitionApi(newAudience);
                const accountId: string = this.newAudience.accountId,
                    personaId: string = this.newAudience.personaId,
                    personaJobId: string = this.newAudience.personaJobId;
                const body: any = this.newAudience;
                let response: AxiosResponse<any> = axiosResponse();

                if (isUndefinedOrNullOrEmpty(this.audience.id)) {
                    response = await api.getAxiosInstance.post(`/api/accounts/${accountId}/personas/${personaId}/jobs/${personaJobId}/audiences`, body);
                } else {
                    response = await api.getAxiosInstance.put(`/api/accounts/${accountId}/personas/${personaId}/jobs/${personaJobId}/audiences/${this.audience.id}`, body);
                }

                return response?.data;
            } catch (error) {
                console.error(error);

                return false;
            }
        },

        async saveAudienceDistributionConfirmation({accountId, confirmationId, status}) {
            try {
                const body: any = {
                    status,
                    currentUserId: useUserStore().getUser?.id || ''
                };
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.put(`/api/accounts/${accountId}/audiences/distributions/confirmations/${confirmationId}`, body);

                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveAudienceName(name: string) {
            try {
                this.audience.name = name;

                // Hit the API with the new name
                const accountId = useAccountStore().getActiveAccount.id;
                const endpoint = `/api/accounts/${accountId}/personas/${this.audience.personaId}/jobs/${this.audience.personaJobId}/audiences/${this.audience.id}`;
                const body = {name: this.audience.name};

                return await api.getAxiosInstance.patch(endpoint, body);
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveAudienceUserPhoto(file) {
            try {
                // Hit the API with the new photo
                const accountId = useAccountStore().getActiveAccount.id;
                const endpoint = `/api/accounts/${accountId}/personas/${this.audience.personaId}/jobs/${this.audience.personaJobId}/audiences/${this.audience.id}`;
                const response = await api.getAxiosInstance.patch(endpoint, {userPhotoId: file?.id || null});
                const patchResult = response.data?.data;

                if (patchResult) {
                    const photoData = patchResult.userPhoto || null;
                    this.audience.userPhoto = photoData;

                    return photoData;
                } else {
                    return response.data;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudience(audience: Audience) {
            try {
                this.audience = audience;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceMode(mode: string) {
            try {
                this.audience.mode = mode;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAudienceActivity(activity: ActivityInterface) {
            try {
                this.activity = activity;
                this.audience.tags = activity?.tags || [];
                this.audience.userPhoto = {id: '', name: '', filePathUri: ''};
                // Always pull the related Persona from the Activity's Job definition
                if (activity && activity.id) {
                    useJobStore().setOriginatorRoot('personas');
                    useJobStore().setOriginatorId(activity.id);
                    const job = await useJobStore().getJobByIdOrLatest();
                    this.setAudiencePersonaJob(job as JobInterface);
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceDemographicFilter(filterContainer: any) {
            try {
                const {field, filter} = filterContainer;
                let filterValueArray: any[] = [];

                if (this.demographics.hasOwnProperty(field)) {
                    for (const demographicData of this.demographics[field]) {
                        const demographicSelection = dereference(demographicData);
                        if (demographicSelection?.value === filter.value) {
                            // Ignore repeated values in case of select/omit
                            continue;
                        }
                        if (demographicSelection) {
                            filterValueArray.push(demographicSelection);
                        }
                    }
                }

                if (filter.selected || filter.omitted) {
                    filterValueArray.push(filter);
                }
                this.demographics[field] = filterValueArray;

                // Update select & omit properties
                for (const {property, value} of selectOmitContexts) {
                    let offline: any = this.audience.selectsAndOmits[property].offline || {};
                    delete offline[field];
                    for (const key of Object.keys(this.demographics)) {
                        const contextDemographics = this.demographics[key].filter(item => item[value] === true)
                        if (contextDemographics.length) {
                            offline[key] = contextDemographics;
                        }
                    }

                    this.audience.selectsAndOmits[property] = {offline};
                }

                return true;
            } catch (error: any) {
                console.error(error.stack);
                return false;
            }
        },

        setAudienceDemographicFilterDefaults(fields: object) {
            try {
                const characteristicSuppression = this.tuningProfile?.characteristicSuppression?.offline || [];
                let filtersArray: Array<any> = Object.keys(fields)
                    .filter(fieldName => {
                        return fields[fieldName].selectableAudience && !characteristicSuppression.includes(fieldName);
                    })
                    .map(fieldName => {
                        let selectableFilter: any = fields[fieldName];
                        selectableFilter.name = fieldName;
                        selectableFilter.values = selectableFilter.values ?
                            sortByProperty(selectableFilter.values, 'position', 'asc') :
                            [];

                        if (selectableFilter.iconName) {
                            selectableFilter.iconName = selectableFilter.iconName.replace('fa-', '');
                        }

                        for (let filterValue of selectableFilter.values) {
                            filterValue.allowOmit = selectableFilter.omittableAudience;
                            filterValue.omitted = false;
                            filterValue.selected = false;
                            filterValue.parent = selectableFilter.name;
                            filterValue.parentPosition = selectableFilter.position;
                            filterValue.footprintDescription = selectableFilter.footprintDescription ? selectableFilter.footprintDescription : null;
                        }

                        return selectableFilter;
                    });

                this.selectableDemographics = sortByProperty(filtersArray, 'position', 'asc');
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceDemographics(demographics: any) {
            try {
                this.demographics = this.getAudienceCharacteristicObject(this.sortAudienceSelectedCharacteristics(demographics));
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceGeographicFilter(geographicContainer: any) {
            try {
                const {filter, selections} = geographicContainer;
                if (filter.context && selections?.length) {
                    for (const {property} of selectOmitContexts) {
                        if (filter.context !== property) {
                            delete this.audience.selectsAndOmits[property].offline[filter.field];
                        }
                    }

                    this.audience.selectsAndOmits[filter.context].offline[filter.field] = selections;
                } else {
                    for (const {property} of selectOmitContexts) {
                        if (this.audience.selectsAndOmits[property]?.offline) {
                            delete this.audience.selectsAndOmits[property]?.offline[filter.field];
                        }
                    }
                }

                return true;
            } catch (error: any) {
                console.error(error.stack);
                return false;
            }
        },

        setAudienceGeographicFilterDefaults(fields: any[]) {
            try {
                const filtersArray: Array<any> = fields
                    .map(selectableFilter => {
                        for (let filterValue of selectableFilter.values) {
                            filterValue.omitted = false;
                            filterValue.selected = false;
                            filterValue.parent = selectableFilter.field;
                            filterValue.parentName = selectableFilter.name;
                            filterValue.parentPosition = selectableFilter.position;
                        }

                        return selectableFilter;
                    });

                this.selectableGeographics = sortByProperty(filtersArray, 'position', 'asc');
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceGeographics(geographics: any) {
            try {
                this.geographics = geographics;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAudienceListFile(params: any) {
            try {
                // Check to see if the file is still active
                const fileInfo = await useFileStore().getFileById(params);
                params.file.isActive = (fileInfo !== false);
                this.audience.lists.definitions = [params.file];
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceListFiles(files: Array<FileMeta>) {
            try {
                this.audience.lists.definitions = files;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceName(name: string) {
            try {
                this.audience.name = name;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudiencePersonaJob(personaJob: JobInterface) {
            try {
                this.personaJob = personaJob;
                this.audience.personaJobId = personaJob?.id;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceSegments(segments: any) {
            try {
                this.audience.segmentStrategy = segments;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAudienceTuningProfile(tuningProfile: any) {
            try {
                this.tuningProfile = tuningProfile;
                this.audience.tuningProfileId = this.tuningProfile.id;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setAvailableAudienceDemographics(mode?: string) {
            try {
                const accountDictionary: any = useAccountStore().getAccountDictionary;

                if (accountDictionary.hasOwnProperty('standard')) {
                    const fields: any = cloneDeep(accountDictionary.standard);
                    this.setAudienceDemographicFilterDefaults(fields);

                    if (mode === AudienceMode.REPORT) {
                        let demographics = {};
                        for (const contextName of Object.keys(this.originalAudience.selectsAndOmits)) {
                            const filterContext = this.originalAudience.selectsAndOmits[contextName].offline;

                            for (const characteristicName of Object.keys(filterContext)) {
                                if (geographicFields.includes(characteristicName)) {
                                    continue;
                                }

                                const characteristicValues = filterContext[characteristicName];
                                if (fields[characteristicName].hasOwnProperty('values')) {
                                    // const characteristicValue = fields[characteristicName].values.find(item => item.value === characteristic);
                                    const demographicSelections = fields[characteristicName].values
                                        .filter(fieldValue => characteristicValues.includes(fieldValue.value))
                                        .map(characteristic => {
                                            switch (contextName) {
                                                case 'omit':
                                                    characteristic.omitted = true;
                                                    characteristic.selected = false;
                                                    break;

                                                case 'select':
                                                    characteristic.omitted = false;
                                                    characteristic.selected = true;
                                            }

                                            return characteristic;
                                        });
                                    demographics[characteristicName] = (demographics[characteristicName] || []).concat(demographicSelections);
                                }
                            }
                        }

                        this.setAudienceDemographics(demographics);
                    }
                } else {
                    throw 'No field dictionary';
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        setAvailableAudienceGeographics(mode?: string) {
            // console.debug('🌐🌐🌐 SETTING AVAILABLE GEOGRAPHICS...');
            try {
                const accountDictionary: any = useAccountStore().getAccountDictionary;

                if (accountDictionary.hasOwnProperty('standard')) {
                    const fields: any = cloneDeep(accountDictionary.standard);
                    let mappedFields = geographicFields
                        .map((field: string) => {
                            const dictionaryItem = accountDictionary.standard[field];
                            if (!dictionaryItem) {
                                console.debug(`No dictionary entry for "${field}"`);
                            }

                            return {
                                field,
                                name: dictionaryItem?.shortDescription || 'UNKNOWN FIELD',
                                position: dictionaryItem?.position || 999,
                                values: dictionaryItem?.values || [],
                            }
                        });

                    this.setAudienceGeographicFilterDefaults(mappedFields);

                    if (mode === AudienceMode.REPORT) {
                        let geographics = {};
                        for (const contextName of Object.keys(this.originalAudience.selectsAndOmits)) {
                            const filterContext = this.originalAudience.selectsAndOmits[contextName].offline;

                            for (const characteristicName of Object.keys(filterContext)) {
                                if (!geographicFields.includes(characteristicName)) {
                                    continue;
                                }

                                const characteristicValues = filterContext[characteristicName];
                                if (fields[characteristicName].hasOwnProperty('values')) {
                                    // const characteristicValue = fields[characteristicName].values.find(item => item.value === characteristic);
                                    const geographicSelections = fields[characteristicName].values
                                        .filter(fieldValue => characteristicValues.includes(fieldValue.value))
                                        .map(characteristic => {
                                            switch (contextName) {
                                                case 'omit':
                                                    characteristic.omitted = true;
                                                    characteristic.selected = false;
                                                    break;

                                                case 'select':
                                                    characteristic.omitted = false;
                                                    characteristic.selected = true;
                                            }

                                            return characteristic;
                                        });
                                    geographics[characteristicName] = (geographics[characteristicName] || [])
                                        .concat(geographicSelections);
                                } else {
                                    // Handle cases where the values are not provided in the dictionary
                                    const dictionaryItem = accountDictionary.standard[characteristicName];
                                    switch (characteristicName) {
                                        case 'dma_code': {
                                            const dmaCodes = useAccountStore().getAccountDmaCodes;
                                            const geographicSelections = filterContext[characteristicName]
                                                .map(value => {
                                                    let dmaCode = dmaCodes.find(dmaCode => dmaCode.code === value);
                                                    let characteristic: any = {
                                                        parentPosition: dictionaryItem.position,
                                                        parent: characteristicName,
                                                        shortDescription: `${dmaCode.code} - ${dmaCode.description}`,
                                                        value,
                                                    };

                                                    switch (contextName) {
                                                        case 'omit':
                                                            characteristic.omitted = true;
                                                            characteristic.selected = false;
                                                            break;

                                                        case 'select':
                                                            characteristic.omitted = false;
                                                            characteristic.selected = true;
                                                    }

                                                    return characteristic;
                                                })
                                            geographics[characteristicName] = (geographics[characteristicName] || [])
                                                .concat(geographicSelections);
                                        }
                                            break;

                                        case 'postal_code': {
                                            const geographicSelections = filterContext[characteristicName]
                                                .map(value => {
                                                    // let dmaCode = dmaCodes.find(dmaCode => dmaCode.code === value);
                                                    let characteristic: any = {
                                                        parentPosition: dictionaryItem.position,
                                                        parent: characteristicName,
                                                        shortDescription: value,
                                                        value,
                                                    };

                                                    switch (contextName) {
                                                        case 'omit':
                                                            characteristic.omitted = true;
                                                            characteristic.selected = false;
                                                            break;

                                                        case 'select':
                                                            characteristic.omitted = false;
                                                            characteristic.selected = true;
                                                    }

                                                    return characteristic;
                                                })
                                            geographics[characteristicName] = (geographics[characteristicName] || [])
                                                .concat(geographicSelections);
                                        }
                                            break;
                                    }
                                }
                            }
                        }

                        this.setAudienceGeographics(geographics);
                    } else {
                        this.setAudienceGeographics([]);
                    }
                } else {
                    const error: string = 'No field dictionary';
                    console.error(error);
                    throw error;
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async setOriginalAudience(params: any) {
            try {
                let originalAudience: any;

                if (params.refresh) {
                    const audience = await this.getAudienceById({id: params.id, shared: params.shared});
                    if (audience) {
                        originalAudience = audience;
                        originalAudience.mode = params.mode;
                    } else {
                        return false;
                    }
                } else if (typeof params.audience === 'object') {
                    // Audience object has already been supplied
                    originalAudience = params.audience;
                }

                this.originalAudience = originalAudience;
                this.audience.id = originalAudience.id;
                this.audience.name = originalAudience.name;
                this.audience.fetchedAt = originalAudience.updated.timestamp;
                this.audience.mode = originalAudience.mode;

                await this.setOriginalAudienceLists({shared: params.shared});
                this.setAvailableAudienceDemographics(AudienceMode.REPORT);
                this.setAvailableAudienceGeographics(AudienceMode.REPORT);
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setOriginalAudienceLists(params: any) {
            try {
                const listFile = this.originalAudience.suppressionUpload || null;

                if (listFile) {
                    const fileParams: any = {file: listFile, shared: params.shared};
                    await this.setAudienceListFile(fileParams);
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        sortAudienceSelectedCharacteristics(characteristics: any) {
            let characteristicArray: any[] = [];

            try {
                let keys = Object.keys(characteristics);

                // Sort characteristic values by position
                // for (let i = 0; i < keys.length; i++) {
                for (const key of Object.keys(characteristics)) {
                    // let key = keys[i];
                    let demographic = characteristics[key];

                    demographic.footprint = {description: '', values: ''};
                    demographic = sortByProperty(demographic, 'position', 'asc');
                    // demographic.sort((a, b) => (a.position > b.position) ? 1 : -1);
                }

                // Map parent characteristic types to array for sorting by value position
                characteristicArray = keys.map((index) => characteristics[index]);
            } catch (error) {
                console.error(error);
                throw error;
            }

            return sortByProperty(characteristicArray, 'parentPosition', 'asc');

            // return characteristicArray.sort((a, b) =>
            //     a[0].parentPosition > b[0].parentPosition ? 1 : -1
            // );
        }
    }
})
