import cloneDeep from 'lodash-es/clone';
import {
    emailMatcher,
    iso8601Date,
    mediumStrengthPassword,
    noCountryCodeUSPhoneNumber,
    strongStrengthPassword,
    uriFileNameMatcher
} from 'Utilities/regex-matchers';
import * as Utils from 'Utilities/utils';
import {Error} from 'Stores/common/models';
import {PasswordChange} from 'Stores/common/models';
import {useAudienceStore} from 'Stores/audience/audience';
import {useAccountStore} from 'Stores/account';
import {useUserStore} from 'Stores/user';
import {usePersonaStore} from 'Stores/persona';
import {useClientPlatformStore} from 'Stores/client/client-platform';
import {useComparisonStore} from 'Stores/comparison';
import {useTopicStore} from 'Stores/topic';
import {useFileStore} from 'Stores/file';
import {isEmptyArray, isUndefinedOrNullOrEmpty} from 'Utilities/inspect';
import {RequestError} from 'Utilities/immutables';

export interface Validity {
    valid: boolean,
    error: Error,
    codes?: string[],
}

export const nameValidationErrorCodes = {
    DUPLICATE_NAME: 'DUPLICATE_NAME',
    INVALID_NAME: 'INVALID_NAME',
    INVALID_NAME_LENGTH: 'INVALID_NAME_LENGTH',
};

export const Validation: string = 'VALIDATION';
export const defaultValidity: Validity = cloneDeep({valid: true, error: {message: ''}});
export const defaultInvalidity: Validity = cloneDeep({valid: false, error: {message: ''}});
export const duplicateName: string = 'name already exists';
export const invalidPassword: string = 'is not a valid password';
export const passwordRequired: string = 'Please enter a password';
export const badDateFormatError: string = 'Dates must use yyyy-mm-dd format';
export const badUSPhoneError: string = 'Must use (555) 555-5555 format';
export const pleaseSelectValue: string = 'Please select a value';
export const pleaseEnterValue: string = 'Please enter a value';
export const dateRangeError: string = 'Please select a start date and end date';
export const listFilesError: string = 'The Persona cannot be defined with only excluded list files.';

export const isInputEditingKeystroke = (keycode): boolean => {
    return (keycode > 47 && keycode < 58) // number keys
        || (keycode > 64 && keycode < 91) // letter keys
        || (keycode > 95 && keycode < 112) // numpad keys
        || (keycode > 185 && keycode < 193) // ;=,-./` (in order)
        || (keycode > 218 && keycode < 223) // [\]' (in order)
        || keycode == 8 // backspace
        || keycode == 32 // spacebar
        || keycode == 46 // delete
};

export const isValidAccountName = async (name: string) => {
    let error: Error = {
        name: 'AccountName',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error};

    switch (name.replace(/-|\s/g, '').toLowerCase()) {
        case 'unnamed':
            error.message = `Cannot be "Unnamed" or "Un-named"`
            validity.error = error;

            return validity;
        default:
            try {
                let response = await useAccountStore().getAccountNameValidation(name);
                validity.valid = response.valid || false;

                if (!validity.valid) {
                    error.message = response.reason = 'DUPLICATE_NAME' ? 'Account name already exists' : response.reason;
                    validity.error = error;
                }

                return validity;
            } catch (error) {
                console.error('VALIDITY CHECK ERROR:', error);
                error.message = 'Invalid account name';
                validity.error = error;
            }
            break;
    }

    return validity;
};

export const isValidActivityName = async (name: string, type: string = 'persona') => {
    type = type ? type.toLowerCase() : '';
    const typeTitle = Utils.titleCase(type);
    let error: Error = {
        name: `${typeTitle}Name`,
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    // Don't bother with an API call if it's blank
    if (isUndefinedOrNullOrEmpty(name)) {
        error.message = RequestError.REQUIRED_INPUT
        validity.error = error;

        return validity;
    }

    // Validate against the API
    let response: any;

    switch (type) {
        case 'audience':
            response = await useAudienceStore().getAudienceNameValidation(name);
            break;

        case 'comparison':
            response = await useComparisonStore().getComparisonNameValidation(name);
            break;
        case 'persona':
        default:
            response = await usePersonaStore().getPersonaNameValidation(name);
            break;
    }
    validity.valid = response?.valid || false;

    if (!validity.valid) {
        let responseReasons = response?.reason;
        let errorMessages: string[] = [];
        if (typeof responseReasons === 'string') {
            responseReasons = [responseReasons];
        }
        validity.codes = responseReasons;

        if (!isEmptyArray(responseReasons)) {
            for (let responseReason of responseReasons) {
                switch (responseReason) {
                    case nameValidationErrorCodes.DUPLICATE_NAME:
                        errorMessages.push(`${typeTitle} ${duplicateName}`);
                        break;

                    case nameValidationErrorCodes.INVALID_NAME:
                        // No need to display the existing match, since it's now invalid
                        errorMessages = errorMessages.filter(errorMessage => !(new RegExp(duplicateName)).test(errorMessage))
                        errorMessages.push('Invalid name');
                        break;

                    case nameValidationErrorCodes.INVALID_NAME_LENGTH:
                        errorMessages.push('Invalid name length');
                        break;

                    default:
                        errorMessages.push(responseReason || `Error validating ${typeTitle} name`);
                }
            }
        }

        error.message = errorMessages.join('; ');

        validity.error = error;
    }

    return validity;
}

export const isValidDateObj = (date: any) => {
    // If the date object is invalid it will return 'NaN' on getTime()
    // and NaN is never equal to itself.
    return date.getTime() === date.getTime();
}

export const isValidDateRange = (startDateVal: string, endDateVal: string) => {
    let error: Error = {
        name: 'Daterange',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};
    const validStartDate: boolean = iso8601Date.test(startDateVal);
    const validEndDate: boolean = iso8601Date.test(endDateVal);
    const startDate: Date = new Date(startDateVal);
    const endDate: Date = new Date(endDateVal);

    if (validStartDate && validEndDate) {
        if (endDate < startDate) {
            validity.error.message = 'End date must come after start date';
        } else {
            validity.valid = true;
        }
    } else {
        validity.error.message = `${badDateFormatError}`;
    }

    return validity;
};

export const isValidEmail = (email: string) => {
    let error: Error = {
        name: 'Username',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (email !== '' && !emailMatcher.test(email)) {
        validity.error.message = `${email} is not a valid email format`;
    } else {
        validity.valid = true;
    }

    return validity;
};

export const isValidFileName = async (name: string, dataType?: string) => {
    let error: Error = {
        name: 'FileName',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    switch (name.replace(/\s/g, '').toLowerCase()) {
        case '':
            error.message = RequestError.REQUIRED_INPUT
            validity.error = error;

            return validity;
        case 'unnamed':
            error.message = `Cannot be "Unnamed" or "Un-named"`
            validity.error = error;

            return validity;
        default:
            try {
                if (!uriFileNameMatcher.test(name)) {
                    error.message = `File name can contain letters, numbers, dashes, underscores and periods. Please rename your file and try again.`;
                    validity.error = error;
                } else {
                    if ( dataType === 'accountLogo' ) {
                        validity.valid = true;
                    } else {
                        let value: string = name;
                        let response = await useFileStore().getFileNameValidation({ value, dataType } );
                        validity.valid = response.valid || false;

                        if (!validity.valid) {
                            error.message = response.reason = nameValidationErrorCodes.DUPLICATE_NAME ?
                                'File name already exists for this account' :
                                response.reason;
                            validity.error = error;
                        }
                    }
                }

                return validity;
            } catch (error) {
                console.error('VALIDITY CHECK ERROR:', error);
                error.message = 'Invalid file name';
                validity.error = error;
            }
            break;
    }

    return validity;
};

export const isValidHumanName = (name: string) => {
    let error: Error = {
        name: 'HumanName',
        type: Validation,
        message: ''
    };

    let validity: Validity = {valid: false, error: error};

    if (name !== '' && name.match(/^[a-z\s-']*$/ig)) {
        validity.valid = true;
        error.message = '';
    } else {
        error.message = `Please enter a valid name`;
        validity.error = error;
    }

    return validity;
}

export const isValidISODate = (dateVal: string) => {
    let error: Error = {
        name: 'isoDate',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};
    const validDate: boolean = iso8601Date.test(dateVal);

    if (!validDate) {
        validity.error.message = `${badDateFormatError}`;
    } else {
        validity.valid = true;
    }

    return validity;
};

export const isValidName = (name: string) => {
    let error: Error = {
        name: 'Name',
        type: Validation,
        message: ''
    };

    let validity: Validity = {valid: false, error: error};

    if (name && name.match(/^[-_0-9a-zA-Z\s&']+$/g)) {
        validity.valid = true;
        error.message = '';
    } else {
        error.message = `Please enter a valid name`;
        validity.error = error;
    }

    return validity;
}

export const isValidPassword = (password: PasswordChange) => {
    let error: Error = {
        name: 'Password',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (password.match) {
        if (!password.value || (!mediumStrengthPassword.test(password.value) && !strongStrengthPassword.test(password.value))) {
            validity.error.message = password.value ? `This ${invalidPassword}` : `${passwordRequired}`;
        } else {
            validity.valid = true;
        }
    } else {
        validity.error.message = 'Password must meet all requirements';
    }

    return validity;
};

export const isValidPlatformName = async (name: string, validity: Validity) => {
    let error: Error = {name: 'PlatformName', type: Validation, message: ''};

    try {
        let response = await useClientPlatformStore().getClientPlatformNameValidation(name);
        validity.valid = response.valid || false;

        if (!validity.valid) {
            error.message = response.reason = nameValidationErrorCodes.DUPLICATE_NAME ?
                'Platform name already exists' :
                response.reason;
            validity.error = error;
        }

        return validity;
    } catch (error) {
        console.error('VALIDITY CHECK ERROR:', error);
        error.message = 'Invalid platform name';
        validity.error = error;
    }

    return validity;
};

export const isValidRequiredInput = (value: string) => {
    let error: Error = {
        name: 'Required Input',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (value.trim() === '' || value === undefined || value === null || value === pleaseEnterValue) {
        validity.error.message = pleaseEnterValue;
    } else {
        validity.valid = true;
        validity.error.message = '';
    }

    return validity;
};

export const isValidUniqueEmail = async (email: string) => {
    let error: Error = {
        name: 'Username',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (email !== '' && !emailMatcher.test(email)) {
        validity.error.message = `${email} is not a valid email format`;
    } else {
        try {
            let response = await useUserStore().getUserEmailValidation(email);
            validity.valid = response.valid || false;

            if (!validity.valid) {
                error.message = response.reason = nameValidationErrorCodes.DUPLICATE_NAME ?
                    'User email already exists' :
                    response.reason;
                validity.error = error;
            }

            return validity;
        } catch (error) {
            console.error('VALIDITY CHECK ERROR:', error);
            error.message = 'Invalid user email';
            validity.error = error;
        }
    }

    return validity;
};

export const isValidUniqueName = async (name: string, type: string) => {
    let error: Error = {
        name: 'UniqueName',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    switch (name.replace(/-|\s/g, '').toLowerCase()) {
        case '':
            error.message = RequestError.REQUIRED_INPUT
            validity.error = error;

            return validity;
        case 'unnamed':
            error.message = `Cannot be "Unnamed" or "Un-named"`
            validity.error = error;

            return validity;
        default:
            switch (type) {
                // TODO: Refactor all unique name validators through here (see platform name validator)
                // case 'persona':
                //     validity = await isValidPersonaName( name, validity, store );
                //     break;
                // case 'audience':
                //     validity = await isValidAudienceName( name, validity, store );
                //     break;
                // case 'topic':
                //     validity = await isValidTopicName( name, validity, store );
                //      break;
                // case 'file':
                //     validity = await isValidFileName( name, validity, store );
                //      break;
                case 'custom-platform':
                    validity = await isValidPlatformName(name, validity);
                    break;

                default:
                    error.message = `Name vaidation error`;
                    validity.error = error;
                    console.error(`${error.message}: no type defined`);
                    break;
            }

            break;
    }

    return validity;
}

export const isValidUsername = (username: string) => {
    let error: Error = {
        name: 'Username',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (username !== '' && !emailMatcher.test(username)) {
        validity.error.message = `${username} is not a valid username format`;
    } else {
        validity.valid = true;
    }

    return validity;
};

export const isValidUSPhoneNumber = (phone: string) => {
    let error: Error = {
        name: 'usPhoneNumber',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};
    const validPhone: boolean = noCountryCodeUSPhoneNumber.test(phone);

    if (!validPhone) {
        validity.error.message = `${badUSPhoneError}`;
    } else {
        validity.valid = true;
    }

    return validity;
};

export const isValidSelectOption = (value: string) => {
    let error: Error = {
        name: 'Select Option',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    if (value === '' || value === undefined || value === null || value === pleaseSelectValue) {
        validity.error.message = pleaseSelectValue;
    } else {
        validity.valid = true;
        validity.error.message = '';
    }

    return validity;
};

export const isValidTopicName = async (name: string) => {
    let error: Error = {
        name: 'TopicName',
        type: Validation,
        message: ''
    };
    let validity: Validity = {valid: false, error: error};

    switch (name.replace(/-|\s/g, '').toLowerCase()) {
        case '':
            error.message = RequestError.REQUIRED_INPUT
            validity.error = error;

            return validity;
        case 'unnamed':
            error.message = `Cannot be "Unnamed" or "Un-named"`
            validity.error = error;

            return validity;
        default:
            try {
                let response = await useTopicStore().getTopicNameValidation(name);
                validity.valid = response.valid || false;

                if (!validity.valid) {
                    error.message = response.reason = nameValidationErrorCodes.DUPLICATE_NAME ?
                        'Topic name already exists' :
                        response.reason;
                    validity.error = error;
                }

                return validity;
            } catch (error) {
                console.error('VALIDITY CHECK ERROR:', error);
                error.message = 'Invalid persona name';
                validity.error = error;
            }
            break;
    }

    return validity;
};
