<template>
    <section>
        <div id="navbarTop" class="nav-empty shadow-sm">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light row d-flex justify-content-between w-100">
                    <router-link
                        class="navbar-brand"
                        to="/"
                        tabindex="-1"
                    >
                        <img src="/assets/images/logo.svg" alt="" tabindex="-1">
                    </router-link>

                    <div class="d-lg-none flex-grow-1">
                        <img class="px-3"
                             data-intent="client-logo"
                             :title="account ? account.name : ''" v-tooltip
                             :onerror="Utils.imageFallback(defaultBrandLogoPath)"
                             :src="logoPath"
                        />
                    </div>
                </nav>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Component, Vue, Watch, toNative} from 'vue-facing-decorator';
    import * as Utils from 'Utilities/utils';
    import {useAccountStore} from 'Stores/account';

    @Component<NavigationAnonymous>({
        setup() {
            const accountStore = useAccountStore();
            return { accountStore };
        },
        components: {}
    })
    class NavigationAnonymous extends Vue {
        Utils = Utils;
        defaultBrandLogoPath = '/assets/images/transparent.png';

        get account() {
            return this.accountStore.getAccount;
        }

        get activeAccount() {
            return this.accountStore.getActiveAccount;
        }

        get logoPath(): string {
            return this.account ?
                this.account.logoFile?.filePathUri?.length > 0 ?
                    Utils.getNonPrefixedFileUri(this.account.logoFile.filePathUri) :
                    this.defaultBrandLogoPath
                    : '';
        }
    }
    export default toNative(NavigationAnonymous);
</script>
<style lang="scss" src="./navigation.scss"></style>
