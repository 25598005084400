// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ti-tag-input[data-v-454f5ad3] {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  top: 0px;
  position: absolute;
  width: 100%;
  line-height: inherit;
}
.ti-tag-input[data-v-454f5ad3]::-ms-clear {
  display: none;
}
input[data-v-454f5ad3]:focus {
  outline: none;
}
input[disabled][data-v-454f5ad3] {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/client/components/common/tag/vue-tags-input/tag-input.vue"],"names":[],"mappings":";AA8BA;EACE,6BAA6B;EAC7B,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,aAAa;EACb,QAAQ;EACR,kBAAkB;EAClB,WAAW;EACX,oBAAoB;AACtB;AAEA;EACE,aAAa;AACf;AAEA;EACE,aAAa;AACf;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["<!-- A helper component used by VueTagsInput and can be put into the tag-center slot -->\r\n\r\n<template>\r\n  <input\r\n    v-if=\"scope.edit\"\r\n    v-model=\"scope.tag.text\"\r\n    :maxlength=\"scope.maxlength\"\r\n    type=\"text\"\r\n    class=\"ti-tag-input\"\r\n    size=\"1\"\r\n    @input=\"scope.validateTag(scope.index, $event)\"\r\n    @blur=\"scope.performCancelEdit(scope.index)\"\r\n    @keydown=\"scope.performSaveEdit(scope.index, $event)\"\r\n  >\r\n</template>\r\n\r\n<script>\r\n\r\nexport default {\r\n  name: 'TagInput',\r\n  props: {\r\n    scope: {\r\n      type: Object,\r\n    },\r\n  },\r\n};\r\n\r\n</script>\r\n\r\n<style lang=\"css\" scoped>\r\n.ti-tag-input {\r\n  background-color: transparent;\r\n  color: inherit;\r\n  border: none;\r\n  padding: 0px;\r\n  margin: 0px;\r\n  display: flex;\r\n  top: 0px;\r\n  position: absolute;\r\n  width: 100%;\r\n  line-height: inherit;\r\n}\r\n\r\n.ti-tag-input::-ms-clear {\r\n  display: none;\r\n}\r\n\r\ninput:focus {\r\n  outline: none;\r\n}\r\n\r\ninput[disabled] {\r\n  background-color: transparent;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
