import {createApp} from '@vue/compat';
import {createPinia} from 'pinia';
import {createHead} from '@unhead/vue'
import {eventBus} from 'Plugins/event-bus';
import {useAppStore} from 'Stores/common/app';
import {vAutofocus} from 'Directives/vAutofocus';
import {vTooltip} from 'Directives/vTooltip';
import App from 'Components/app/app.vue';
import VueGtag from 'vue-gtag';
import router from 'Router/index';
import api from 'Server/api';
import './index.scss';

const pinia = createPinia();
const head = createHead();
const app = createApp(App)

app.use(router)
.use(eventBus)
.use(pinia)
.use(head, {refreshOnceOnNavigation: true})
.use(VueGtag, {
    appName: 'PersonaBuilder',
    bootstrap: false,
    config: {id: 'G-C62SZ4HHK1'},
    pageTrackerScreenviewEnabled: true,
}, router);

app.directive('autofocus', vAutofocus);
app.directive('tooltip', vTooltip);
app.mount('#app');

api.getAxiosInstance.interceptors.request.use( async ( request ) => {
    request.headers[ 'referrer' ] = `${window.location.protocol}//${window.location.hostname}`;

    return request
}, async ( error ) => {
    if ( error.request ) {
        if ( error.request.status && [ 401, 403 ].includes( error.request.status ) ) {
            await useAppStore().logout();
            window.location.href = '/';
        }
    }

    return Promise.reject( error )
} );

api.getAxiosInstance.interceptors.response.use( async ( response ) => {
    if ( response && [ 401, 403 ].includes( response.data.code ) ) {
        if ( !response.config.url?.includes( '2fa' ) && !response.config.url?.includes( 'login' ) ) {
            await useAppStore().logout();
            window.location.href = '/';
        }
    }

    return response
}, async ( error ) => {
    if ( error.response ) {
        if ( error.response.status && [ 401, 403 ].includes( error.response.status ) ) {
            await useAppStore().logout();
            window.location.href = '/';
        }
    }

    return Promise.reject( error )
} );

if ( window.Cypress ) {
    // Only available during E2E tests
    window.app = app
}
