import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid bg-comparison-report p-0 py-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_geographic_report = _resolveComponent("geographic-report")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-6",
          key: index
        }, [
          (_openBlock(), _createBlock(_component_geographic_report, {
            "chart-data": _ctx.chartData[index],
            class: "bg-white",
            detail: _ctx.detail[index],
            key: `geoReport_${index}`,
            params: {showControls: false, sidebarClass: 'col-12'}
          }, _createSlots({ _: 2 /* DYNAMIC */ }, [
            (_ctx.afterChart[index])
              ? {
                  name: "afterChart",
                  fn: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.afterChart[index]
                    }, null, 8 /* PROPS */, _hoisted_3)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["chart-data", "detail"]))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}