<template>
    <div>
        <a
            aria-label="Choose photo"
            data-action="choose-picture"
            href
            @click.prevent="manageChoosePhotoModal('show')"
        >
            <representative-photo
                :characteristics="photoCharacteristics()"
                :photo-source="object.userPhoto?.filePathUri || null"
            />

            <div class="text-uppercase" data-intent="hover-text">Edit</div>
        </a>

        <input
            :accept="acceptedImageTypes"
            class="d-none"
            name="image"
            ref="customImageInput"
            type="file"
            @change="prepareCustomImage"
        />

        <div
            aria-labelledby="Choose Photo Modal"
            class="modal modal-lg"
            :data-content-type="objectType.toUpperCase()"
            :id="choosePhotoModalId"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-primary">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="d-block modal-close text-end mb-0">
                                <a href @click.prevent="manageChoosePhotoModal('hide')">Close</a>
                            </div>
                            <h3 class="text-center mb-3 fw-bold">Representative Photo Image</h3>
                            <p>Select a photo to represent this {{ objectType }}.</p>
                            <div class="row">
                                <div v-for="{type, label} in imageTypes" class="col-6">
                                    <div class="card rounded h-100"
                                         :class="{'border-green hover-border-green bg-green-25': isCurrentPhoto(type)}"
                                    >
                                        <div class="card-body overflow-hidden">
                                            <div class="mb-3 photo-lg" data-intent="representative-photo">
                                                <representative-photo
                                                    :characteristics="photoCharacteristics(type)"
                                                    class="mx-auto"
                                                />
                                            </div>
                                            <h5 class="text-center">{{ label }}</h5>
                                            <table class="table table-borderless mt-3 text-size-md">
                                                <tbody>
                                                <tr>
                                                    <td class="text-start">Age Range</td>
                                                    <td class="text-center">{{ insightsDisplay('age_range', type, 'description') }}</td>
                                                    <td class="text-end">{{ insightsDisplay('age_range', type, 'difference') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-start">Gender</td>
                                                    <td class="text-center">{{ insightsDisplay('gender', type, 'description') }}</td>
                                                    <td class="text-end">{{ insightsDisplay('gender', type, 'difference') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-start">Urbanicity</td>
                                                    <td class="text-center">{{ insightsDisplay('county_size', type, 'description') }}</td>
                                                    <td class="text-end">{{ insightsDisplay('county_size', type, 'difference') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-start">Married</td>
                                                    <td class="text-center">{{ insightsDisplay('marital_status', type, 'description') }}</td>
                                                    <td class="text-end">{{ insightsDisplay('marital_status', type, 'difference') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-start">Children Present in Household</td>
                                                    <td class="text-center">{{ insightsDisplay('children_present_in_hh', type, 'description') }}</td>
                                                    <td class="text-end">{{ insightsDisplay('children_present_in_hh', type, 'difference') }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <em v-if="type === 'index'" class="text-size-md">compared to baseline</em>
                                        </div>
                                    </div>
                                </div>

                                <!-- USER-SPECIFIED PHOTO -->
                                <div class="col-6">
                                    <div
                                        class="card rounded h-100"
                                        :class="{'border-green hover-border-green bg-green-25': isCurrentPhoto('user')}"
                                    >
                                        <div class="card-body overflow-hidden">
                                            <div class="text-center mb-3 photo-lg">
                                                <representative-photo
                                                    v-if="hasNewUserPhoto || hasUserPhoto"
                                                    class="mx-auto"
                                                    :class="{'border-danger': customImageError && customImageError.code}"
                                                    :detail="false"
                                                    :photo-source="hasNewUserPhoto ? croppedImage : object.userPhoto.filePathUri"
                                                />

                                                <a v-else
                                                   class="text-success"
                                                   data-action="select-custom-picture"
                                                   href
                                                   @click.prevent="selectCustomPhoto"
                                                >
                                                    <font-awesome-icon
                                                        class="photo"
                                                        fixed-width
                                                        :icon="['solid', 'circle-plus']"
                                                    />
                                                </a>
                                            </div>
                                            <h5 class="text-center">Custom Photo</h5>

                                            <div class="input-group mb-5">
                                                <div class="input-group-prepend">
                                                    <button
                                                        class="btn btn-primary rounded-start"
                                                        :disabled="photoSelectionPending"
                                                        type="button"
                                                        @click="selectCustomPhoto"
                                                    >
                                                        <font-awesome-icon
                                                            class="me-2"
                                                            :icon="['solid', 'image']"
                                                            size="lg"
                                                        />
                                                        Upload New File
                                                    </button>
                                                </div>
                                                <input
                                                    aria-describedby="validationFeedbackImageValid"
                                                    aria-label="readonly input example"
                                                    class="form-control pe-5"
                                                    :class="{'is-invalid': customImageError && customImageError.code}"
                                                    :disabled="photoSelectionPending"
                                                    readonly
                                                    type="text"
                                                    :value="imageFilename"
                                                    :title="customImageFilename" v-tooltip
                                                />
                                                <a v-if="allowDeleteUserImage"
                                                   class="me-1 position-absolute center-vertical snap-right"
                                                   href
                                                   :title="`Remove ${objectType} image`" v-tooltip
                                                   @click.prevent="clearUserPhoto(true)"
                                                >
                                                    <font-awesome-icon
                                                        action="clear"
                                                        fixed-width
                                                        :icon="['duotone', 'trash-can']"
                                                        size="lg"
                                                    />
                                                </a>
                                                <div class="invalid-feedback mb-n100" id="validationFeedbackImageValid">
                                                    {{ customImageError.message }}
                                                </div>
                                            </div>

                                            <div
                                                v-show="customImageSource && isCustomImageValid"
                                                class="mt-3 overflow-hidden"
                                                style="aspect-ratio: 1 / 1"
                                            >
<!--                                                <vue-cropper-->
<!--                                                    alt="Source Image"-->
<!--                                                    :aspect-ratio="1"-->
<!--                                                    class="h-100 w-100 overflow-hidden"-->
<!--                                                    :min-container-height="325"-->
<!--                                                    :min-container-width="325"-->
<!--                                                    ref="cropper"-->
<!--                                                    :src="customImageSource"-->
<!--                                                    :view-mode="2"-->

<!--                                                    :crop="crop"-->
<!--                                                    :ready="cropperReady"-->
<!--                                                />-->
                                                <cropper
                                                    ref="cropper"
                                                    alt="Source Image"
                                                    class="h-100 w-100 overflow-hidden"
                                                    image-restriction="stencil"
                                                    :auto-zoom="false"
                                                    :max-height="maxImageSize"
                                                    :max-width="maxImageSize"
                                                    :min-height="minImageSize"
                                                    :min-width="minImageSize"
                                                    :src="customImageSource"
                                                    :stencil-props="{
                                                        aspectRatio: 1,
                                                        handlers: {},
                                                        movable: false,
                                                        resizable: true,
                                                    }"
                                                    v-memo="[customImageSource, maxImageSize, minImageSize]"
                                                    @change="cropImage"
                                                />
                                            </div>

                                            <div
                                                v-show="!hasNewUserPhoto"
                                                class="text-start mt-3"
                                                style="aspect-ratio: 1 / 1"
                                            >
                                                .PNG, .JPG, and .GIF files are accepted. Minimum image size is {{ minImageSize }}x{{ minImageSize }}.
                                                <template v-if="hasUserPhoto">
                                                    You may upload a new custom photo, or remove the current one to use the default (largest percentage) representative photo.
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-end mt-3">
                                <button
                                    class="btn btn-success btn-arrow-right"
                                    :disabled="!allowPhotoSelection"
                                    tabindex="3"
                                    @click.prevent="savePhotoSelection"
                                >
                                    <font-awesome-icon :icon="['duotone', 'save']"/>
                                    Save photo selection
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import {Cropper} from 'vue-advanced-cropper';
    // import VueCropper from 'vue-cropperjs';
    import cloneDeep from 'lodash-es/cloneDeep';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon';
    const RepresentativePhoto = () => import(/* webpackChunkName: "RepresentativePhoto" */ 'Components/common/representative-photo');
    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');
    import * as ReportUtilities from 'Utilities/reports';
    import {defaultError} from 'Stores/common/defaults';
    import {PhotoAssignment} from 'Stores/common/models';
    import {useAccountStore} from 'Stores/account';
    import {ActivityType} from 'Stores/activity';
    import {useAudienceStore} from 'Stores/audience/audience';
    import {FileMode, UploadMeta, useFileStore} from 'Stores/file';
    import {Persona, usePersonaStore} from 'Stores/persona';
    import * as Utils from 'Utilities/utils';
    import {Modal} from 'bootstrap';

    @Component<ChooseRepresentativePhoto>({
        components: {
            Cropper,
            FontAwesomeIcon,
            RepresentativePhoto,
            Spinner,
            // VueCropper,
        },
        setup() {
            return {
                accountStore: useAccountStore(),
                audienceStore: useAudienceStore(),
                fileStore: useFileStore(),
                personaStore: usePersonaStore(),
            };
        }
    })
    class ChooseRepresentativePhoto extends Vue {
        @Prop({
            type: [Object, null],
            default: () => {
                return {};
            }
        })
        currentPhoto!: PhotoAssignment | null;

        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        insights: any;

        @Prop({
            type: Object,
        })
        object!: Object;

        acceptedImageTypes = 'image/png, image/jpg, image/jpeg, image/gif';
        croppedImage: any = null;
        // currentPhotoType: string = 'count';
        customImageFilename: string | null = null;
        customImageSource: any = null;
        customImageType: string | null = null;
        customImageError: Error = cloneDeep(defaultError);
        errorCodes = {
            breaking: '400',
            warning: '200',
        }
        errorMessages = {
            cropTooSmall: 'Selected image area does not meet minimum size requirement.',
            imageTooSmall: 'Image does not meet minimum size requirement.',
        }
        fileError: Error = cloneDeep(defaultError);
        // fileLoader: string = 'file loader';
        fileSaved: boolean = false;
        maxImageSize: number = 2048;
        minImageSize: number = 256;
        removeUserPhoto: boolean = false;
        selectedPhoto: PhotoAssignment | null = null;
        photoSelectionPending: boolean = false;
        Utils = Utils;
        choosePhotoModal: Modal | null = null;
        choosePhotoModalId: string = 'choosePhotoModal';

        mounted() {
            this.selectedPhoto = cloneDeep(this.currentPhoto);
            const choosePhotoModalElement: HTMLElement | null = document.getElementById(this.choosePhotoModalId);

            if (choosePhotoModalElement) {
                this.choosePhotoModal = new Modal(choosePhotoModalElement as Element, {backdrop: 'static'});
                choosePhotoModalElement.addEventListener('hidden.bs.modal', event => {
                    this.clearUserPhoto();
                });
            }
        }

        unmounted() {
            const choosePhotoModalElement: HTMLElement | null = document.getElementById(this.choosePhotoModalId);

            if (choosePhotoModalElement) {
                choosePhotoModalElement.removeEventListener('hidden.bs.modal', event => {
                    this.clearUserPhoto();
                });
            }
        }

        get allowDeleteUserImage() {
            return (this.hasUserPhoto || this.hasNewUserPhoto)
                && !this.photoSelectionPending
                && !this.customImageError.code;
        }

        get allowPhotoSelection() {
            return !this.photoSelectionPending
                && !this.customImageError.message;
        }

        get fieldDictionary() {
            return this.accountStore.getAccountDictionary;
        }

        get hasNewUserPhoto() {
            return this.isCustomImageValid
                && (
                    this.customImageSource !== null
                    || (this.object.photo || null) !== null
                )
        }

        get hasUserPhoto() {
            return !this.removeUserPhoto && this.object.userPhoto?.hasOwnProperty('filePathUri');
        }

        get imageFilename(): string | null {
            if (this.removeUserPhoto) {
                return null;
            }

            return this.customImageFilename || this.object.userPhoto?.name || null;
        }

        get imageTypes() {
            return [
                // {type: 'index', label: 'Highest Indexing'},
                {type: 'count', label: 'Largest Percentage'},
            ]
        }

        get isCustomImageValid() {
            if (this.customImageError && (this.customImageError.code === this.errorCodes.breaking)) {
                return false;
            }

            return true;

        }

        get objectType(): string {
            return this.object.hasOwnProperty('personaJobId') ? 'Audience' : 'Persona';
        }

        manageChoosePhotoModal(action: string) {
            switch (action) {
                case 'hide':
                    // Set the selection back if the user closes the modal instead of saving
                    if (this.selectedPhoto?.id !== this.currentPhoto!.id) {
                        this.selectedPhoto = this.currentPhoto!;
                    }

                    this.choosePhotoModal?.hide();
                    break;
                case 'show':
                    this.choosePhotoModal?.show();
                    break;
            }
        }

        clearUserPhoto(removeUserPhoto: boolean = false) {
            if (this.object.userPhoto !== null) {
                this.removeUserPhoto = removeUserPhoto;
            }

            this.croppedImage = null;
            this.customImageError = cloneDeep(defaultError);
            this.customImageFilename = null;
            this.customImageSource = null;
            this.$refs['customImageInput'].value = null;
        }

        // crop() {
        //     const cropper = this.$refs['cropper'];
        //     if (!cropper || !this.isCustomImageValid) {
        //         return false;
        //     }
        //
        //     const cropArea = cropper.getCroppedCanvas();
        //     if (cropArea) {
        //         this.customImageError = cloneDeep(defaultError);
        //
        //         // Check for min cropped image size
        //         if (cropArea.getAttribute('width') < this.minImageSize || cropArea.getAttribute('height') < this.minImageSize) {
        //             this.customImageError.code = this.errorCodes.warning;
        //             this.customImageError.message = this.errorMessages.cropTooSmall;
        //         }
        //
        //         this.croppedImage = cropArea.toDataURL();
        //     }
        // }

        cropImage({coordinates, canvas}) {
            this.croppedImage = canvas.toDataURL();
        }

        insightsDisplay(field: string, property: string, context: string) {
            if (property === 'count') {
                property = 'groupRatio';
            }
            const highestValue = this.insightsHighestValue(field, property);

            if (highestValue) {
                switch (context) {
                    case 'description':
                        if (highestValue.dictionaryApplied === true) {
                            return highestValue.value;
                        }
                        const dictionaryValue = ReportUtilities.dictionaryValue(
                            this.fieldDictionary.standard,
                            highestValue.value,
                            field
                        );
                        return dictionaryValue.shortDescription;

                    case 'difference':
                        switch (property) {
                            case 'index':
                                return '+' + Utils.formatValue(Utils.indexPercent(highestValue[property]), 'percent', 0);
                            case 'groupRatio':
                                return Utils.formatValue(highestValue[property], 'decimal', 0) + '%';
                        }
                }
            }

            return `${context} n/a`;
        }

        insightsHighestValue(field: string, property: string) {
            const insights = cloneDeep(this.insights);

            if (insights.offline_standard?.demographics[field]) {
                return Utils.sortByProperty(
                    insights.offline_standard?.demographics[field],
                    property,
                    'desc'
                )[0];
            }
        }

        isCurrentPhoto(type: string): boolean {
            switch (type) {
                case 'count':
                default:
                    return !(this.hasUserPhoto || this.hasNewUserPhoto) || !!this.customImageError.message; // TODO: need a more concrete check?

                case 'user':
                    return this.hasUserPhoto || (this.hasNewUserPhoto && !this.customImageError.message);
            }
        }

        photoCharacteristics(context?: string) {
            // TODO: allow selecting characteristics based on context?
            return this.object.hasOwnProperty('photoCharacteristics') ?
                this.object['photoCharacteristics'] :
                null;
        }

        prepareCustomImage(event) {
            const file = event.target.files[0];
            if (!file) {
                return false;
            }

            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    // this.$refs.cropper.replace(event.target.result); // Refresh cropperjs with the updated source
                    this.customImageFilename = file.name;
                    this.customImageType = file.type;
                    this.customImageSource = event.target.result;
                    // this.currentPhotoType = 'user';
                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        }

        async savePhotoSelection() {
            this.photoSelectionPending = true

            if (this.isCurrentPhoto('user')) {
                // if (this.object.userPhoto?.id)
                if (this.croppedImage) {
                    this.photoSelectionPending = true;
                    const cropper = this.$refs['cropper'];
                    const {canvas} = cropper.getResult();
                    // const canvas: HTMLCanvasElement = cropper.getCroppedCanvas();
                    await canvas.toBlob(async (blob: any) => {
                        const croppedImageFile: any = new File(
                            [blob],
                            this.customImageFilename!,
                            {
                                type: this.customImageType!,
                            }
                        );
                        croppedImageFile.representativePhoto = true;

                        const uploadError: string = 'Something went wrong. Your file could not be uploaded.';
                        const fileResponse = await this.fileStore.uploadFile(croppedImageFile);
                        const file: UploadMeta = fileResponse?.data || null;

                        if (file) {
                            this.fileSaved = (fileResponse.status === 'success');
                            if (this.fileSaved && file.id) {
                                let newFile: UploadMeta = {
                                    mode: FileMode.NEW,
                                    accountId: file.accountId,
                                    id: file.id,
                                    name: file.name,
                                    type: file.fileType!,
                                    filePathUri: file.filePathUri,
                                    dataType: 'representativePhoto',
                                    isActive: true,
                                    // dataType: 'image',
                                    // tags: this.file.tags,
                                    lastUsed: file.lastUsed,
                                    created: file.created
                                };

                                const metaDataResponse = await this.fileStore.saveFileMetaData(newFile);
                                const fileMeta = metaDataResponse.data || false;
                                if (fileMeta) {
                                    let patchResult;
                                    // Assign this image to the object
                                    switch (this.objectType.toLowerCase()) {
                                        case ActivityType.audience:
                                            await this.audienceStore.setAudience(this.object);
                                            patchResult = await this.audienceStore.saveAudienceUserPhoto(fileMeta);
                                            break;

                                        case ActivityType.persona:
                                            patchResult = await this.personaStore.savePersonaUserPhoto(fileMeta);
                                            break;
                                    }
                                    this.$emit('update-user-photo', patchResult);

                                    this.photoSelectionPending = false;
                                    this.manageChoosePhotoModal('hide');
                                } else {
                                    this.fileError.message = uploadError;
                                }
                            } else {
                                this.fileError.message = uploadError;
                            }
                        } else {
                            this.fileError.message = uploadError;
                        }
                    });

                    // this.$wait.end(this.fileLoader);
                } else {
                    // Nothing changed!
                    this.photoSelectionPending = false;
                    this.manageChoosePhotoModal('hide');
                }
            } else {
                // Unset the user photo
                let patchResult;
                switch (this.objectType.toLowerCase()) {
                    case ActivityType.audience:
                        await this.audienceStore.setAudience(this.object);
                        patchResult = await this.audienceStore.saveAudienceUserPhoto(null);
                        break;

                    case ActivityType.persona:
                        patchResult = await this.personaStore.savePersonaUserPhoto(null);
                        break;
                }
                this.$emit('update-user-photo', patchResult);

                this.photoSelectionPending = false;
                this.manageChoosePhotoModal('hide');
            }
        }

        selectCustomPhoto() {
            this.$refs['customImageInput'].click();
        }
    }

    export default toNative(ChooseRepresentativePhoto);
</script>

<style src="vue-advanced-cropper/dist/style.css"/>
