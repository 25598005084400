import crypto from 'crypto-browserify';
import bcrypt from 'bcryptjs';

export const aid: string = process.env.APP_ID || '';
export const cryptoError: string = 'Crypto failure';
const algorithm: string = 'aes-256-cbc';
const key: Buffer = Buffer.from( aid.replace( /-/g, '' ) );
const iv = crypto.randomBytes( 16 );

/**
 * @name                generateSalt
 * @description         Asynchronously generates a salt
 * @param       rounds  Number of rounds to use, default 12 (if omitted) (bcryptjs default is 10)
 *                      The higher the number, the longer the calculation time to return salt
 * @return              Promise with resulting salt
 */
export const generateSalt = ( rounds?: number ) => {
    rounds = rounds ? rounds : 12;
    return bcrypt.genSalt( rounds );
};

/**
 * @name                getSaltedHash
 * @description         Asynchronously creates a salted hash
 * @param       value   Value to be hashed
 * @param       salt    salt to be used in the hash
 * @return              Promise with resulting salted hash
 */
export const getHash = async ( value: string, salt: string ) => {
    return bcrypt.hash( value, salt );
};

/**
 * @name                getPepperedHash
 * @description         Asynchronously creates a peppered hash
 * @param       body    Request body containing the salt and hash to be peppered and rehashed
 * @return              Promise with resulting peppered hash
 */
export const getPepperedHash = async ( body: any ) => {
    const pepper: string = body.password + aid;
    const pepperedHash: string = await getHash( pepper, body.salt || '' );

    return pepperedHash;
}

/**
 * @name                getHashMatch
 * @description         Asynchronously generates a salt
 * @param       value   Cleartext value to match
 * @param       hash    Hashed value to match
 * @return              Promise with resulting boolean
 */
export const getHashMatch = ( value: string, hash: string ) => {
    return bcrypt.compare( value, hash );
};

export const encrypt = (obj: any, keyValue?: string) => {
    const localKey = keyValue ? Buffer.from(keyValue.replace(/-/g, '')) : key;
    console.log(`ENCRYPTING DATA - algorithm: '${algorithm}' // key: '${localKey}' // iv: '${iv}'`, obj);
    let result = '';

    try {
        if (obj) {
            let cipher = crypto.createCipheriv(algorithm, localKey, iv);
            let encrypted = Buffer.from('');

            if (typeof obj === 'string') {
                encrypted = cipher.update(Buffer.from(obj));
            } else if (typeof obj === 'object') {
                encrypted = cipher.update(Buffer.from(JSON.stringify(obj)));
            }

            encrypted = Buffer.concat([encrypted, cipher.final()]);
            result = iv.toString('hex') + ':' + encrypted.toString('hex');
        }
    } catch (error) {
        console.error(error.stack);
    }

    return result;
}

export const decrypt = ( obj ) => {
    let decrypted: any;

    try {
        if ( obj && ( obj.indexOf( ':' ) > -1 ) ) {
            let parts = obj.split( ':' );
            let iv = Buffer.from( parts.shift(), 'hex' );
            let encrypted = Buffer.from( parts.join( ':' ), 'hex' );
            let decipher = crypto.createDecipheriv( algorithm, key, iv );
            decrypted = decipher.update( encrypted );
            decrypted = Buffer.concat( [ decrypted, decipher.final() ] );
        }
    } catch (error) {
        console.error( error.stack );
    }

    return ( decrypted ) ? decrypted.toString() : '';
}

export const getAuthorizationHeader = ( cookie: string ) => {
    if ( cookie ) {
        const authToken: string = decrypt( cookie );

        if ( authToken ) {
            return `Bearer ${authToken}`;
        }
    }

    return '';
}
