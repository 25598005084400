import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg" }
const _hoisted_4 = {
  key: 0,
  class: "text-center pb-3"
}
const _hoisted_5 = {
  class: "btn-group select-group me-3",
  role: "group"
}
const _hoisted_6 = {
  class: "btn-group select-group",
  role: "group"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "btn-group select-group d-flex mb-3"
}
const _hoisted_9 = { class: "h5 text-primary mb-3 mx-auto" }
const _hoisted_10 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_11 = { class: "text-primary w-100" }
const _hoisted_12 = { class: "align-top" }
const _hoisted_13 = { class: "align-top text-end text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_highcharts, {
          "constructor-type": "mapChart",
          options: _ctx.filteredChart,
          style: {"overflow":"visible !important"}
        }, null, 8 /* PROPS */, ["options"]),
        _renderSlot(_ctx.$slots, "afterChart", { id: "after_chart_slot" }),
        _createCommentVNode(" Controls "),
        (_ctx.showControls)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createCommentVNode(" Show/hide data labels "),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: _normalizeClass(["btn select-blue", [{active: _ctx.showDataLabels}]]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDataLabels = true))
                }, " Show Data Labels ", 2 /* CLASS */),
                _createElementVNode("button", {
                  class: _normalizeClass(["btn select-blue", [{active: !_ctx.showDataLabels}]]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDataLabels = false))
                }, " No Data Labels ", 2 /* CLASS */)
              ]),
              _createCommentVNode(" Show specific values "),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayOptions, (button, index) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: index,
                    class: _normalizeClass(["btn", [`select-${button.color}`, {active: _ctx.displayGroup === button.group}]]),
                    onClick: ($event: any) => (_ctx.displayGroup = button.group)
                  }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_7))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createCommentVNode(" Detail sidebar "),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.sidebarClass)
      }, [
        (_ctx.showControls)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["btn media-body w-100 select-green", [{active: _ctx.sortGroup === 'index'}]]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSortGroup('index')))
              }, " Index ", 2 /* CLASS */),
              _createElementVNode("button", {
                class: _normalizeClass(["btn media-body w-100 select-blue", [{active: _ctx.sortGroup === 'groupRatio'}]]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setSortGroup('groupRatio')))
              }, " % of Persona ", 2 /* CLASS */)
            ]))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail, (detailSection, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "card bg-blue-10 border-0 p-3 mb-3"
          }, [
            _createElementVNode("u", _hoisted_9, _toDisplayString(detailSection.name), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("table", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items[_ctx.sortGroup], (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.label
                  }, [
                    _createElementVNode("td", _hoisted_12, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_13, _toDisplayString(item.value), 1 /* TEXT */)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ])
  ]))
}