<template>
    <section>
        <div id="navbarTop" class="shadow-sm">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light d-flex w-100" :key="navbarKey">
                    <router-link
                        class="navbar-brand flex-shrink-1"
                        :to="navLink('activity')"
                        tabindex="-1"
                        @click="updateNavigation('activity')"
                    >
                        <img src="/assets/images/logo.svg" alt="" tabindex="-1">
                    </router-link>

                    <div class="d-lg-none flex-grow-1">
                        <navigation-extra @logout="logout"/>
                    </div>

                    <button
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        class="navbar-toggler flex-shrink-1"
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                        type="button"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="d-lg-flex flex-lg-row-reverse w-100">
                        <div class="d-none d-lg-inline-block align-self-center w-100">
                            <navigation-extra @logout="logout"/>
                        </div>

                        <div id="navbarSupportedContent" class="collapse navbar-collapse ">
                            <ul class="navbar-nav me-auto nav-tabs w-100">
                                <li v-if="accounts.length > 1"
                                    class="nav-item dropdown dropdown-bubble p-0"
                                    data-action="account"
                                >
                                    <a aria-expanded="false"
                                       aria-haspopup="true"
                                       class="nav-link dropdown-toggle"
                                       data-bs-toggle="dropdown"
                                       href="#"
                                       id="navbarDropdown"
                                       role="button"
                                    >
                                        <font-awesome-icon :icon="['solid', 'user']"></font-awesome-icon>
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <router-link
                                            v-for="account of accounts"
                                            class="dropdown-item"
                                            :class="{'active disabled': isActiveAccount(account.id)}"
                                            :key="account.id"
                                            :to="{name: 'activity', params: {accountId: account.id}}"
                                            @click="setAccount(account.id)"
                                        >
                                            {{ account.name }}
                                        </router-link>
                                    </div>

                                </li>
                                <li class="nav-item"
                                    data-nav-target="activity"
                                    data-toggle="tab"
                                    :class="navLinkClasses('activity')"
                                >
                                    <router-link
                                        class="nav-link"
                                        :to="navLink('activity')"
                                        tabindex="-1"
                                        @click="updateNavigation('activity')"
                                    >
                                        Activity
                                    </router-link>
                                </li>
                                <li class="nav-item"
                                    :class="navLinkClasses('personaBuild')"
                                    data-nav-target="personaBuild"
                                    data-toggle="tab"
                                >
                                    <router-link
                                        class="nav-link"
                                        :to="navLink('personaBuild')"
                                        @click="updateNavigation('personaBuild')"
                                    >
                                        Build a Persona
                                    </router-link>
                                </li>
                                <li class="nav-item"
                                    :class="navLinkClasses('comparisonBuild')"
                                    data-nav-target="comparisonBuild"
                                    data-toggle="tab"
                                >
                                    <router-link
                                        class="nav-link"
                                        :to="navLink('comparisonBuild')"
                                        @click="updateNavigation('comparisonBuild')"
                                    >
                                        Compare Personas
                                    </router-link>
                                </li>

                                <li class="nav-item"
                                    :class="navLinkClasses('audienceBuild')"
                                    data-nav-target="audienceBuild"
                                    data-toggle="tab"
                                >
                                    <router-link
                                        class="nav-link"
                                        :to="navLink('audienceBuild')"
                                        @click="updateNavigation('audienceBuild')"
                                    >
                                        Build an Audience
                                    </router-link>
                                </li>
                                <li class="nav-item nav-item-last"
                                    :class="navLinkClasses('audienceSend')"
                                    data-nav-target="audienceSend"
                                    data-toggle="tab"
                                >
                                    <router-link
                                        class="nav-link"
                                        :to="navLink('audienceSend')"
                                        @click="updateNavigation('audienceSend')"
                                    >
                                        Send an Audience
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <subnav :class="$route.name" placement="top"/>
    </section>
</template>

<script lang="ts">
    import {Vue, Component, Watch, toNative} from 'vue-facing-decorator';
    import {nextTick} from 'vue';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from "Components/common/font-awesome-icon";
    import NavigationExtra from 'Components/app/navigation/navigation-extra';
    const Subnav = () => import(/* webpackChunkName: "Subnav" */ 'Components/app/navigation/subnav.vue');
    import {AudienceMode, AudienceTabs} from 'Stores/audience/audience';
    import {ComparisonMode, ComparisonTabs} from 'Stores/comparison';
    import {PersonaMode, PersonaTabs} from 'Stores/persona';
    import {Actions, Status} from 'Utilities/immutables';
    import {useAccountStore, Account, AccountLoaders} from 'Stores/account';
    import {useNavigationStore} from 'Stores/common/navigation/navigation';
    import {useUserStore} from 'Stores/user';
    import {useAppStore} from 'Stores/common/app';

    @Component<Navigation>({
        components: {
            FontAwesomeIcon,
            NavigationExtra,
            Subnav,
        },
        setup() {
            const appStore = useAppStore();
            const accountStore = useAccountStore();
            const userStore = useUserStore();
            return {appStore, accountStore, userStore};
        }
    })
    class Navigation extends Vue {
        Utils = Utils;

        get account() {
            return this.accountStore.getAccount;
        }
        get accountId() {
            return this.account?.id;
        }

        get accountLoading() {
            return this.appStore.getLoaderActive(AccountLoaders.LOADING);
        }

        get accounts() {
            const userAccounts: Account[] = this.userStore.getUser.accounts ? Array.from( this.userStore.getUser.accounts ) : [];
            let activeAccounts = userAccounts.filter( account => account.status === Status.ACTIVE);
            return Utils.sortByProperty(activeAccounts || [], 'name', 'asc');
        }

        get navbarKey() {
            const navigationStore = useNavigationStore();
            const navigation = navigationStore.getNavigation;
            return `nav_${this.$route.name}:${navigation?.timestamp || 0}`;
        }

        isActiveAccount(accountId) {
            return this.accountId === accountId;
        }

        isAncestor($navType) {
            switch ($navType) {
                case 'activity':
                    return this.$route.name === 'audienceReport' ||
                        this.$route.name === 'comparisonReport' ||
                        this.$route.name === 'personaReport' ||
                        this.$route.name === 'topicLibrary' ||
                        this.$route.name === 'files';
            }

            return false;
        }

        logout() {
            this.$emit(Actions.LOGOUT);
        }

        navLink(context) {
            switch (context) {
                case 'activity':
                default:
                    return {
                        name: 'activity',
                        params: {
                            accountId: this.accountId,
                        },
                    };
                case 'audienceBuild':
                    return {
                        name: 'audienceBuild',
                        params: {
                            accountId: this.accountId,
                            tab: AudienceTabs.PERSONA,
                        },
                        query: {
                            mode: AudienceMode.NEW,
                            // subnav: true,
                        },
                    }
                case 'audienceSend':
                    return {
                        name: 'audienceSend',
                        params: {
                            accountId: this.accountId,
                        },
                    }
                case 'audienceSend_PLACEHOLDER':
                    return {
                        name: 'error404',
                    }
                case 'comparisonBuild':
                    return {
                        name: 'comparisonBuild',
                        params: {
                            accountId: this.accountId,
                            tab: ComparisonTabs.PERSONAS,
                        },
                        query: {
                            mode: ComparisonMode.NEW,
                            // subnav: true,
                        },
                    };
                case 'personaBuild':
                    return {
                        name: 'personaBuild',
                        params: {
                            accountId: this.accountId,
                            tab: PersonaTabs.CHARACTERISTICS,
                            section: PersonaTabs.DEMOGRAPHICS,
                        },
                        query: {
                            mode: PersonaMode.NEW,
                            // subnav: true,
                        },
                    };
                case 'personaCompare':
                    return {
                        name: 'personaCompare',
                        params: {
                            accountId: this.accountId,
                        },
                    };
            }
        }

        navLinkClasses(context) {
            let classes: string[] = [];
            if (this.$route.name === context) {
                classes.push('active');
            }
            if (this.isAncestor(context)) {
                classes.push('ancestor');
            }

            return classes.join(' ');
        }

        async setAccount( accountId ) {
            this.appStore.setLoader(AccountLoaders.LOADING);
            const user = await this.userStore.setUserCurrentAccount( accountId );
            let switched: boolean;

            if ( user ) {
                if ( this.$route.name !== 'activity' || this.$route.params.accountId !== accountId ) {
                    this.$router.push( { name: 'activity', params: { accountId } } );
                } else {
                    // Reload the Activity list
                    await this.$refs['activities']?.applySearchParameters()
                }
                switched = true;
            } else {
                switched = false;
            }

            await nextTick( () => {
                setTimeout( async () => {
                    this.appStore.clearLoader(AccountLoaders.LOADING);
                }, 1000 );
            } );

            return switched;
        }

        async updateNavigation(routeName) {
            switch (routeName) {
                case 'activity':
                    // Clear any existing Activity page filters
                    window.sessionStorage.removeItem('activityPageSearchParameters');
                    break;
            }
        }
    }
    export default toNative(Navigation);
</script>
<style lang="scss" src="./navigation.scss"></style>

