import cloneDeep from 'lodash-es/cloneDeep';
import { defineStore } from 'pinia';
import api from 'Server/api';
import { Job, defaultJob, useJobStore } from 'Stores/job';
import { useAccountStore } from './account';
import { RequestError } from 'Utilities/immutables';

export interface InsightState {
    insightType: string |  null;
    insight: Insight;
    insightDefinition: JSON;
    insights: Array<any>;
    insightCount: number;
    job: Job;
    originatorRoot: string | null;
    originatorId: string | null;
}

export interface Insight {
    id: string;
    accountId: string;
    insightType: string;
    name: string;
    artifacts: {};
    tags: string[];
    created: {
        id: string;
        firstName: string;
        lastName: string;
        timestamp: Date;
    },
    updated: {
        id: string;
        firstName: string;
        lastName: string;
        timestamp: Date;
    }
}

export interface InsightsOfflineStandardInterface {
    code: number,
    status: string,
    data: {
        demogSource: string,
        demographics: {},
    }
}

export const defaultInsight: Insight = {
    id: '',
    accountId: '',
    insightType: '',
    name: '',
    artifacts: {},
    tags: [''],
    created: {
        id: '',
        firstName: '',
        lastName: '',
        timestamp: new Date()
    },
    updated: {
        id: '',
        firstName: '',
        lastName: '',
        timestamp: new Date()
    }
}

export const useInsightStore = defineStore('insight', {
    state: (): InsightState => ({
        insightType: null,
        insight: cloneDeep(defaultInsight),
        insightDefinition: JSON.parse('{}'),
        insights: [],
        insightCount: 0,
        job: cloneDeep(defaultJob),
        originatorRoot: null,
        originatorId: null
    }),

    getters: {
    },

    actions: {
        async clearInsightState() {
            try {
                this.job = defaultJob;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        /** @deprecated **/
        async getInsights(params: any = {shared: false}) {
            console.warn('[DEPRECATED METHOD] Calls to getInsights() should be replaced with getInsightsBatch()');
            try {
                if (this.originatorRoot && this.originatorId) {
                    // Get the job
                    if (!this.job?.id) {
                        useJobStore().setOriginatorRoot(this.originatorRoot);
                        useJobStore().setOriginatorId(this.originatorId);
                        const latestJob = await useJobStore().getJobByIdOrLatest(params);
                        this.job = latestJob as Job;
                    }

                    // Retrieve job data
                    const accountId: boolean = params.accountId || useAccountStore().getActiveAccount.id;
                    const shared: boolean = params.shared === 'true';
                    const queryString: string = shared ? `?shared=true` : ``;
                    let response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/${this.originatorRoot}/${this.originatorId}/jobs/${this.job?.id}/insights/${this.insightType}${queryString}`);

                    if (response.data?.data) {
                        return response.data.data;
                    } else {
                        throw `getInsights: ${RequestError.UNABLE_TO_RETRIEVE}`;
                    }

                } else {
                    throw `getInsights: ${RequestError.INVALID_REQUEST_PARAMS}`;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getInsightsBatch(params: any = {shared: false}, types: string[] = []) {
            if (!types.length) {
                if (!this.insightType) {
                    throw `getInsights: ${RequestError.INVALID_REQUEST_PARAMS}`;
                }
                types = [this.insightType];
            }

            try {
                if (this.originatorRoot && this.originatorId) {
                    // console.log(`GET INSIGHTS BATCH: ${types.join(', ')}`, params);
                    // Get the job
                    if (!this.job?.id) {
                        useJobStore().setOriginatorRoot(this.originatorRoot);
                        useJobStore().setOriginatorId(this.originatorId);
                        this.job = await useJobStore().getJobByIdOrLatest(params) as Job;
                    }

                    // Retrieve job data
                    const accountId: boolean = params.accountId || useAccountStore().getActiveAccount.id;
                    const shared: boolean = params.shared === 'true';
                    const queryString: string = shared ? `?shared=true` : ``;
                    const endpoints = types.map((type: string) =>
                        `/api/accounts/${accountId}/${this.originatorRoot}/${this.originatorId}/jobs/${this.job?.id}/insights/${type}${queryString}`
                    )
                    let responses = await Promise.all(endpoints.map((endpoint: string) => api.getAxiosInstance.get(endpoint)));
                    // console.log('API RESPONSES:', responses);

                    const responseObject: any = {};
                    for (let i = 0, end = types.length; i < end; ++i) {
                        const type = types[i];
                        const response = responses[i];

                        responseObject[type] = response.data?.data ?
                            response.data.data :
                            {error: RequestError.UNABLE_TO_RETRIEVE}
                    }

                    return responseObject;
                } else {
                    throw `getInsights: ${RequestError.INVALID_REQUEST_PARAMS}`;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setInsightJobId(id: string) {
            try {
                this.job.id = id;

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setInsightType(insightType: string) {
            try {
                this.insightType = insightType;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setOriginatorId(id: string) {
            try {
                this.originatorId = id;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setOriginatorRoot(root: string) {
            try {
                this.originatorRoot = root;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }
})
