<template>
    <div class="d-flex justify-content-between" data-intent="extra-features">
        <img :alt="account ? account.name : ''"
             class="px-3"
             data-intent="client-logo"
             :onerror="Utils.imageFallback(defaultBrandLogoPath)"
             :src="logoPath"
        />

        <div class="flex-shrink-1 d-flex align-items-center">
            <div class="p-2">
                <router-link
                    :to="{
                        name: 'help',
                        params: {
                            tab: helpTabs.FAQ,
                        },
                    }"
                    target="_blank"
                    :title="`Open Help in new tab`" v-tooltip:bottom
                >
                    <font-awesome-icon fixed-width icon="circle-question" />
                </router-link>
            </div>

            <div class="dropdown dropdown-bubble">
                <div class="p-2 show-orange"
                     id="navGeneralFunctions"
                     data-bs-toggle="dropdown"
                     aria-haspopup="true"
                     aria-expanded="false"
                >
                    <font-awesome-icon fixed-width icon="ellipsis-v" />
                </div>
                <div class="dropdown-menu dropdown-menu-end arrow-top"
                     aria-labelledby="navGeneralFunctions">
                    <router-link
                        class="dropdown-item"
                        :to="{name: 'topicLibrary'}"
                    >
                        Topic Library
                    </router-link>
                    <router-link
                        class="dropdown-item"
                        :to="{name: 'listLibrary'}"
                    >
                        Upload Library
                    </router-link>
                    <router-link
                        class="dropdown-item"
                        :to="{ name: 'userFeedback' }">
                        Send Feedback
                    </router-link>
                    <router-link
                        class="dropdown-item item-secondary"
                        :to="{name: 'account', params: {tab: accountTabs.SUMMARY}}"
                    >
                        <font-awesome-icon :icon="['solid', 'gear']" size="lg"/>
                        Account Settings
                    </router-link>
                    <router-link
                        class="dropdown-item item-secondary"
                        :to="{name: 'user', params: {accountId: account.id}}"
                    >
                        <font-awesome-icon :icon="['solid', 'gear']" size="lg"/>
                        User Profile
                    </router-link>
                    <a class="dropdown-item item-danger"
                       href
                       @click.prevent="logout"
                    >
                        <font-awesome-icon :icon="['solid', 'right-from-bracket']" size="lg"/>
                        Log Out
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, toNative} from 'vue-facing-decorator';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from "Components/common/font-awesome-icon";
    import {HelpTabs} from 'Stores/help';
    import {useAccountStore, AccountTabs} from 'Stores/account';
    import {Actions} from 'Utilities/immutables';

    @Component<NavigationExtra>({
        components: {
            FontAwesomeIcon,
        },
        setup() {
            const accountStore = useAccountStore();
            return { accountStore };
        }
    })
    class NavigationExtra extends Vue {
        accountTabs = AccountTabs;
        helpTabs = HelpTabs;
        Utils = Utils;
        defaultBrandLogoPath = '/assets/images/transparent.png';

        get account() {
            return this.accountStore.getAccount;
        }

        get logoPath(): string {
            return this.account ?
                this.account.logoFile?.filePathUri?.length > 0 ?
                    Utils.getNonPrefixedFileUri(this.account.logoFile.filePathUri) :
                    this.defaultBrandLogoPath
                    : '';
        }

        logout() {
            this.$emit(Actions.LOGOUT);
        }
    }
    export default toNative(NavigationExtra);
</script>
