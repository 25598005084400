import { Account, useAccountStore } from 'Stores/account';
import { ListParameters } from 'Stores/common/models';
import { User, UserTimestamp, defaultUserTimestamp } from 'Stores/user';
import { defineStore } from 'pinia';
import { useUserStore } from 'Stores/user';
import api from 'Server/api';
import cloneDeep from 'lodash-es/cloneDeep';
import { AxiosResponse } from 'axios';
import { axiosResponse } from 'Utilities/utils';
import { RequestError } from 'Utilities/immutables';

export interface ClientPlatformState {
    clientPlatform: ClientPlatform | null;
    clientPlatforms: ClientPlatform[];
}

export interface ClientPlatform {
    accountId: string;
    clientId: string;
    platformId: string;
    name?: string;
    advertiserAccount: string;
    seatBusiness: string;
    secretKey: string;
    currentUserId: string;
    created?: UserTimestamp;
    updated?: UserTimestamp;
}

export const defaultClientPlatform: ClientPlatform = {
    accountId: '',
    clientId: '',
    platformId: '',
    advertiserAccount: '',
    seatBusiness: '',
    secretKey: '',
    currentUserId: ''
}

export const useClientPlatformStore = defineStore('clientPlatform', {
    state: (): ClientPlatformState => ({
        clientPlatform: {
            accountId: '',
            clientId: '',
            platformId: '',
            name: '',
            advertiserAccount: '',
            seatBusiness: '',
            secretKey: '',
            currentUserId: '',
            created: cloneDeep(defaultUserTimestamp),
            updated: cloneDeep(defaultUserTimestamp)
        },
        clientPlatforms: []
    }),

    getters: {
        getClientPlatforms: (state: ClientPlatformState) => state.clientPlatforms,
    },

    actions: {
        async deleteClientPlatform({clientId, clientPlatform}) {
            try {
                // console.log('DELETE ClientPlatform:', clientPlatform)
                const currentUser: User = useUserStore().getUser;
                clientPlatform.currentUserId = currentUser.id;
                const body: any = clientPlatform;
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.delete(`/api/accounts/${currentUser.currentAccountId}/clients/${clientId}/platforms/${clientPlatform.platformId}`, body);
                // console.log('DELETE RESPONSE:', response);
                const deleted = response.data?.status === 'success';

                if (!deleted) {
                    throw response;
                }

                this.clientPlatform = null;

                return deleted;
            } catch (error) {
                console.error('[clientPlatform] deleteClientPlatform error:', error);
                return false;
            }
        },

        async getClientPlatformNameValidation(value: string) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const clientId: string | null = this.clientPlatform ? this.clientPlatform.clientId : null;
                const name: string = encodeURIComponent( value );

                if (clientId) {
                    const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/clients/${clientId}/platforms/names/${name}` );
                    return response.data?.data;
                } else {
                    throw `getClientPlatformNameValidation: ${RequestError.INVALID_REQUEST_PARAMS}`; 
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },
        
        async getServiceClientPlatforms(client: Client) {
            try {
                const accountId = useAccountStore().getActiveAccount.id;
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/clients/${client.id}/platforms`);
                this.clientPlatforms = response.data?.data?.clientPlatforms || [];
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveClientPlatform({clientId, clientPlatform}) {
            try {
                // console.log('SAVE NEW ClientPlatform:', clientPlatform)
                const currentUser: User = useUserStore().getUser;
                clientPlatform.currentUserId = currentUser.id;
                const body: any = clientPlatform;
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.post(`/api/accounts/${currentUser.currentAccountId}/clients/${clientId}/platforms`, body);
                clientPlatform = response.data?.data;

                if (!clientPlatform) {
                    throw response;
                }

                this.clientPlatform = clientPlatform;

                return clientPlatform;
            } catch (error) {
                console.error('[clientPlatform] saveClientPlatform error:', error);
                return false;
            }
        },

        async setClientPlatform(platform: ClientPlatform) {
            try {
                this.clientPlatform = platform;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setClientPlatformName(name: string) {
            try {
                if (this.clientPlatform) {
                    this.clientPlatform.name = name;
                }
                
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async updateClientPlatform({clientId, clientPlatform}) {
            try {
                // console.log('UPDATE ClientPlatform:', clientPlatform)
                const currentUser: User = useUserStore().getUser;
                clientPlatform.currentUserId = currentUser.id;
                const body: any = clientPlatform;
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.put(`/api/accounts/${currentUser.currentAccountId}/clients/${clientId}/platforms/${clientPlatform.platformId}`, body);
                clientPlatform = response.data?.data;

                if (!clientPlatform) {
                    throw response;
                }

                this.clientPlatform = clientPlatform;

                return clientPlatform;
            } catch (error) {
                console.error('[clientPlatform] updateClientPlatform error:', error);
                return false;
            }
        }
    }
})