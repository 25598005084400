import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "topic-widget" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.topItems, (item, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "topic-widget-item row g-0",
        title: _ctx.tooltipText(item),
        key: index,
        style: _normalizeStyle({height: `${_ctx.itemHeight(item, index)}`})
      }, [
        _createCommentVNode("            <div v-if=\"context === 'twitter'\""),
        _createCommentVNode("                 class=\"col-2 h-100 pe-3 d-flex align-items-center overflow-hidden\""),
        _createCommentVNode("                 v-html=\"itemIcon(item)\""),
        _createCommentVNode("            ></div>"),
        _createCommentVNode("            <div class=\"col\">"),
        _createElementVNode("div", {
          class: _normalizeClass(["card h-100 text-center rounded-0", {'fw-bold': index === 0}])
        }, [
          _createElementVNode("div", {
            class: "my-auto text-fit",
            innerHTML: _ctx.itemLabel(item)
          }, null, 8 /* PROPS */, _hoisted_3)
        ], 2 /* CLASS */),
        _createCommentVNode("            </div>")
      ], 12 /* STYLE, PROPS */, _hoisted_2)), [
        [_directive_tooltip]
      ])
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}