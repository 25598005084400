import cloneDeep from 'lodash-es/cloneDeep';
import { defineStore } from 'pinia';

export interface Navigation {
    name: string,
    timestamp: string,
}

export interface NavigationState {
    navigation: Navigation,
}

export interface NavLinkParams {
    section?: string,
    tab: string,
}

export interface NavLinkQuery {
    jobId?: string,
}

export interface NavLink {
    default?: boolean,
    disabled?: boolean,
    icon?: string | string[] | null | undefined,
    id?: string,
    label: string,
    links?: NavLink[],
    name: string,
    params?: NavLinkParams,
    query?: NavLinkQuery,
}

export interface NavElement {
    type: string,
    links?: NavLink[],
}

export const defaultNavigation = {
    name: '',
    timestamp: '',
}

export const useNavigationStore = defineStore('navigation', {
    state: (): NavigationState => ({
        navigation: cloneDeep( defaultNavigation )
    }),

    getters: {
        getDefaultNavigation: () => defaultNavigation,
        getNavigation: (state: NavigationState) => state.navigation
    },

    actions: {
        setNavigation(to: string) {
            try {
                this.navigation.name = to;
                this.navigation.timestamp = Date.now().toString();

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        setNavigationDisplay( routeName: string ) {
            try {
                const nav = document.querySelector('#navbarTop .navbar');
                if (nav) {
                    const navItemSelector = '.nav-item.active[data-toggle="tab"]';
                    for (const navTab of Array.from(nav.querySelectorAll(`${navItemSelector}:not([data-nav-target="${routeName}"])`))) {
                        navTab.classList.remove('active');
                    }
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        }
    }
})
