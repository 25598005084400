import { useAccountStore } from 'Stores/account';
import { defineStore } from 'pinia';
import { useUserStore } from 'Stores/user';
import api from 'Server/api';
import {isEmptyArray, isEmptyObject} from 'Utilities/inspect';

export interface Tag {
    accountId?: string,
    userId?: string,
    tags: string[],
}

export interface TagInterface {
    classes?: string
    text: string,
    tiClasses?: string[],
    value?: string,
}

export interface ActiveTagInterface extends TagInterface {
    active: boolean,
}

export const TagPattern: RegExp = /^[a-z0-9_]{2,}$/i;
export const TagPatternNoLengthCheck: RegExp = /^[a-z0-9_]*$/i;

export interface TagObjectInterface {
    tag: ActiveTagInterface,
    addTag?,
    deleteTag?,
}

interface TagResponse {
    accountId: string,
    createdAt: string,
    createdBy: string,
    objectId: string,
    tag: string
}

export interface TagState {
    accountId: '',
    accountTags: Tag[];
    saveTagResponse: Object;
    tags: [''],
    userId: ''
}

export interface TagPayload {
    fetchedAt: string,
    originatorRoot: string,
    originatorId: string,
    tag: Tag;
}

export const useTagStore = defineStore('tag', {
    state: (): TagState => ({
        accountId: '',
        accountTags: [],
        saveTagResponse: {},
        tags: [''],
        userId: ''
    }),

    getters: {
        getSaveTagResponse: (state: TagState) => state.saveTagResponse,
    },

    actions: {
        async addAccountTags(tags: string[]) {
            try {
                // Remove existing tags from consideration
                tags = tags.filter(tagText => !this.accountTags.some((tag: Tag) => tag.tags[0].toLowerCase() === tagText.toLowerCase()))

                // Save new tags to state
                const newTags = tags
                    .map(tag => {
                        return {
                            accountId: useAccountStore().getActiveAccount.id,
                            userId: useUserStore().getUser.id,
                            tags: [tag],
                        }
                    });
                const accountTags = newTags.concat( this.accountTags as any[] );
                this.accountTags = accountTags;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async clearAccountTags() {
            try {
                this.accountTags = [];
                return true;
            } catch (error) {
                console.error( error );
                return false;
            }
        },

        async getAccountTags(search) {
            try {
                let tagMatches = this.accountTags;
                if (search?.length) {
                    // Filter tags by search string
                    tagMatches = tagMatches.filter((tag: Tag): boolean => tag.tags[0].indexOf(search) > -1);
                }
    
                return tagMatches;
            } catch (error) {
                console.error( error );
                return false;
            }
        },

        async getMatchingTags(search: string) {
            if (!isEmptyArray(this.accountTags)) {
                return await this.getAccountTags(search);
            }

            try {
                const response = await api.getAxiosInstance.get(`/api/accounts/${useAccountStore().getActiveAccount.id}/tags`);

                if (response.data.data) {
                    const filteredTags = response.data.data.tags
                    .filter(tag => tag.length > 0)
                    .sort()
                    .map(tag => {
                        return {
                            accountId: useAccountStore().getActiveAccount.id,
                            userId: useUserStore().getUser.id,
                            tags: [tag],
                        }
                    })
                    this.accountTags = filteredTags;
                    return this.accountTags;
                }
            } catch (error) {
                console.error(error);
            }
        },

        async saveTags(payload: TagPayload) {
            try {
                const body = {
                    fetchedAt: payload.fetchedAt,
                    tags: payload.tag.tags,
                };
                const endpoint = `/api/accounts/${useAccountStore().getActiveAccount.id}/${payload.originatorRoot}/${payload.originatorId}`;
                const response = await api.getAxiosInstance.patch(endpoint, body);
                const tag = response.data;

                if (tag.data) {
                    this.saveTagResponse = tag.data;

                    // Update the local store with newly added tags
                    if (!isEmptyArray(this.saveTagResponse)) {
                        for (let tagCandidate of this.saveTagResponse) {
                            if (!this.accountTags.some(tag => tag.tags[0] === tagCandidate.tag)) {
                                this.accountTags.push({
                                    accountId: tagCandidate.accountId,
                                    userId: this.userId,
                                    tags: [tagCandidate.tag]
                                })
                            }
                        }
                    }

                    return this.saveTagResponse;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        }
    }
})