import api from 'Server/api';
import { ListParameters } from 'Stores/common/models';
import { User, UserTimestamp, useUserStore } from 'Stores/user';
import { RequestError } from 'Utilities/immutables';
import { isEmptyArray } from 'Utilities/utils';
import cloneDeep from 'lodash-es/cloneDeep';
import { defineStore } from 'pinia';
export interface NotificationState {
    notification: Notification;
    notifications: Notifications;
}
export interface Notification {
    id: string,
    title: string,
    shortDescription: string,
    body: string,
    isActive: boolean,
    requireAcknowledgement: boolean,
    startAt?: Date | null,
    endAt?: Date | null,
    created: UserTimestamp,
    updated: UserTimestamp
}
export interface Notifications {
    active: boolean,
    params?: ListParameters,
    data: Notification[],
    displayDate?: Date | null
}

export const defaultNotification: Notification = {
    id: '',
    title: '',
    shortDescription: '',
    body: '',
    isActive: false,
    requireAcknowledgement: false,
    startAt: null,
    endAt: null,
    created: {},
    updated: {}
}

export const defaultNotificationsParameters: ListParameters = {
    sort: 'lastUpdated',
    order: 'desc'
}

export const defaultNotifications: Notifications = {
    params: cloneDeep( defaultNotificationsParameters ),
    data: [],
    displayDate: null,
    active: false
}

export const useNotificationStore = defineStore('notification', {
    state: (): NotificationState => ( {
        notification: cloneDeep( defaultNotification ),
        notifications: cloneDeep( defaultNotifications )
    } ),

    getters: {
        getActiveNotifications( state: NotificationState ) {
            return state.notifications.active;
        },
        getNotifications( state: NotificationState ) {
            return state.notifications;
        }
    },

    actions: {
        async clearNotificationsState() {
            try {
                this.notifications = cloneDeep(defaultNotifications);

                return true;
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        async setNotificationReceipt( notification: Notification ) {
            try {
                const currentUser: User = useUserStore().getUser;

                if ( currentUser ) {
                    const response = await api.getAxiosInstance.post( `/api/users/${currentUser.id}/notifications/${notification.id}` );
                    const notificationData = response.data;

                    if ( notificationData?.code == 200 ) {
                        this.notifications.data = this.notifications.data.filter( item => item.id !== notification.id );
                        await this.setNotifications();

                        return notificationData.data;
                    } else {
                        throw RequestError.UNABLE_TO_RETRIEVE;
                    }
                }

                return true;
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        async setNotifications( params?: ListParameters ) {
            params = params ? params : defaultNotificationsParameters;

            try {
                const user: User = useUserStore().getUser;
                const query: string = '?isActive=true';
                const response = await api.getAxiosInstance.get( `/api/users/${user.id}/notifications${query}`, { params: params } );

                if ( response.data ) {
                    let notifications: Notifications = {
                        data: response.data.data,
                        active: false,
                        displayDate: null
                    };

                    this.notifications = notifications;

                    if (!isEmptyArray(this.notifications.data)) {
                        await this.setNotificationsActive();
                    }

                    return true;
                } else {
                    throw RequestError.UNABLE_TO_RETRIEVE;
                }
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        async setNotificationsActive() {
            try {
                let notifications: Notifications = this.notifications || defaultNotifications;

                if (!isEmptyArray(notifications.data)) {
                    if ( notifications.displayDate ) {
                        let lastDisplayDate: Date = new Date( notifications.displayDate );
                        let latestNotification: Notification = notifications.data.reduce( ( a: any, b: any ) => {
                            if ( a.updated.timestamp && b.updated.timestamp ) {
                                return new Date( a.updated.timestamp ) > new Date( b.updated.timestamp ) ? a : b;
                            }
                        } );
    
                        if ( latestNotification && latestNotification.updated ) {
                            const latestTimestamp: Date = latestNotification.updated.timestamp ? new Date( latestNotification.updated.timestamp ) : new Date();
                            notifications.active = new Date(latestTimestamp.toUTCString()) > new Date(lastDisplayDate.toUTCString());
                        }
                    } else {
                        notifications.active = true;
                    }
    
                    this.notifications.active = notifications.active;
                }

                return this.notifications.active;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setNotificationsDisplayDate( latest: Date ) {
            try {
                const currentUser: User = useUserStore().getUser;

                if ( currentUser && latest ) {
                    this.notifications.displayDate = latest;
                }

                return true;
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        }
    }
})