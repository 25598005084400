import api from 'Server/api';
import { getHash } from 'Server/crypto';
import { AccountUser, useAccountStore } from 'Stores/account';
import { User, UserCredentials, UserVerification, defaultUser, useUserStore } from 'Stores/user';
import { Actions, RequestError } from 'Utilities/immutables';
import { uidMatcher } from 'Utilities/regex-matchers';
import { defineStore } from 'pinia';
import { invalidCredentials } from 'Stores/common/defaults';
import { useNotificationStore } from 'Stores/notification';
import { caseInsensitiveEquals, isEmptyArray } from 'Utilities/utils';

export interface AppState {
    loaders: AppLoader[];
}

export interface AppLoader {
    context: string;
    active: boolean;
}

/* Font Awesome icons for various features of the site */
export enum FeatureIcons {
    CONGLOMERATE_RFM_BRANDS = 'lightbulb-dollar', // badge-dollar, building, registered, shop, store,
    CONGLOMERATE_RFM_MARKETS = 'compass', // IND33 / Market Affinity
    CONSUMER_SPENDING = 'shopping-cart',
    DEMOGRAPHICS = 'id-card',
    GEOGRAPHY = 'earth-americas',
    HIGH_LEVEL_RFM = 'money-bill',
    PAST_PURCHASES = 'bag-shopping',
    POLITICAL = 'landmark-dome',
    SOCIAL_AFFINITY = 'users',
}

export enum Loaders {
    NAVIGATE = 'navigate',
    LOAD = 'load',
    SAVE = 'save',
    SEARCH = 'search',
    SEND ='send',
    VERIFY = 'verify',
    VALIDATE = 'validate'
}

export const useAppStore = defineStore('app', {
    state: (): AppState => ( {
        loaders: []
    } ),

    getters: {
        getLoaders: (state: AppState) => state.loaders,
        getLoaderActive: (state) => {
            return (context) => state.loaders.find((loader) => loader?.context === context)?.active || false;
        },
    },

    actions: {
        async appRefreshRequired() {
            const currentAppVersion: string = window.localStorage.getItem( 'pbv' ) || '';
            const currentApiVersion: string = window.localStorage.getItem( 'pbapiv' ) || '';

            try {
                const appMeta: any = await api.getAxiosInstance.get( `/api/meta/app` );
                const meta: any = appMeta.data;
                const initializing: boolean = ( !currentAppVersion.length || !currentApiVersion.length );
                const upgrade: boolean = ( ( ( currentAppVersion.length >= 0 ) && currentAppVersion !== meta.ui?.version )
                                            || ( ( currentApiVersion.length >= 0 ) && currentApiVersion !== meta.api?.version ) );

                if ( meta.ui && meta.api ) {
                    if ( initializing || ( !initializing && upgrade ) ) {
                        window.localStorage.setItem( 'pbv', meta.ui.version );
                        window.localStorage.setItem( 'pbapiv', meta.api.version );
                        return true;
                    } else {
                        console.debug( `${meta.ui.name} is running: ${currentAppVersion}` );
                        console.debug( `${meta.api.name} is running: ${currentApiVersion}` );
                        return false;
                    }
                } else {
                    throw 'Meta data error';
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async checkCredentials( credentials: UserCredentials ) {
            try {
                if ( credentials.username ) {
                    const response: any = await api.getAxiosInstance.post( '/api/auth/login', { username: credentials.username } );
                    let credentialsCheck: any = response.data?.data;

                    if ( credentialsCheck ) {
                        if ( credentialsCheck.isActive && !credentialsCheck.isLocked && credentialsCheck.salt && typeof credentialsCheck.salt === 'string' ) {
                            return { salt: credentialsCheck.salt };
                        } else {
                            console.error( `${RequestError.CREDENTIAL_CHECK_FAILED}: ${credentials.username}` );
                            delete( credentialsCheck.salt );
                            return credentialsCheck;
                        }
                    } else if ( response ) {
                        return response;
                    } else {
                        throw `${RequestError.INVALID_RESPONSE}: ${credentialsCheck}`;
                    }
                } else {
                    throw `${RequestError.INVALID_REQUEST}`;
                }
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        clearLoader(context: string) {
            try {
                return this.setLoader(context, null);
            } catch ( error ) {
                console.error(error);
                return false;
            }
        },

        async isSharedKey( route ) {
            try {
                const body = {
                    accountId: route.params.accountId,
                    token: route.params.token,
                }
                const response = await api.getAxiosInstance.post( `/api/auth/shared-key`, body );
                const authResponse = response.data;

                if ( authResponse.token?.length > 0 && authResponse.accountId?.length > 0 ) {
                    return authResponse;
                } else {
                    return false;
                }
            } catch ( error ) {
                console.error( 'UNABLE TO RETRIEVE SHARED KEY INFO',  error );
                return false;
            }
        },

        async login( credentials: UserCredentials ) {
            try {
                if ( credentials.username && credentials.password && credentials.salt ) {
                    credentials.password = await getHash( credentials.password, credentials.salt || '' );

                    const body: UserCredentials = credentials;
                    const response: any = await api.getAxiosInstance.post( '/api/auth/login', body );
                    const auth: any = response.data;

                    if ( auth ) {
                        if ( auth.data && auth.data.user ) {
                            const user: AccountUser = auth.data.user;
                            await useUserStore().setUser( user );
                            await useUserStore().setUserAccounts( user.id );

                            window.localStorage.setItem( 'pbuser', '1' );
                        } else if ( auth.message ) {
                            console.error( `${RequestError.LOGIN_FAILED} (${auth.message}): ${credentials.username}` );
                        }

                        return auth;
                    } else {
                        throw `${RequestError.INVALID_RESPONSE}: ${auth}`;
                    }
                } else {
                    throw `${RequestError.INVALID_REQUEST}`;
                }
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        async logout( args?: { lastPath: string, rememberMe: boolean } ) {
            try {
                let querystring: string = '';

                if ( args ) {
                    if ( 'lastPath' in args ) {
                        window.sessionStorage.setItem( 'previousRoute', args.lastPath );
                    }

                    if ( 'rememberMe' in args ) {
                        querystring = `?rememberMe=${args.rememberMe}`;
                    }
                }

                const response = await api.getAxiosInstance.get( `/api/auth/logout${querystring}` );
                const auth = response.data;
                window.localStorage.setItem( 'pbexp', '' );
                window.localStorage.setItem( 'pbuser', '0' );

                if ( auth.success ) {
                    await useUserStore().setUser( defaultUser );
                    console.log('🔦🔦🔦 Logout auth success (???)');
                    useAccountStore().clearAccountMetadata();
                    await useNotificationStore().clearNotificationsState();
                    return true;
                } else {
                    return false;
                }
              } catch ( error ) {
                console.error( error );
            }
        },

        async resetPassword( credentials: UserCredentials ) {
            try {
                switch ( credentials.action ) {
                    case Actions.USER_PASSWORD_RESET:
                    case Actions.USER_FORGOT_PASSWORD_RESET:
                        if ( credentials.username ) {
                            const body: any = { action: credentials.action };
                            const userReset: any = await api.getAxiosInstance.put( `/api/auth/users/${credentials.username}/reset`, body );

                            if ( userReset && userReset.hasOwnProperty( 'data' ) ) {
                                return userReset.data;
                            } else {
                                return false;
                            }
                        }

                        break;
                    default:
                        return false;
                }
              } catch ( error ) {
                console.error( error );
            }
        },

        async searchSocialHandles( handle: string ) {
            try {
                const limit: number = 100; // Harcode for now
                const params: Object = { search: encodeURIComponent( handle ), limit: limit };
                const response: any = await api.getAxiosInstance.get( '/api/meta/social/handles', { params: params } );
                const handles: any = response.data;

                if ( handles && handles.data ) {
                    for ( let i = 0; i < handles.data.length; i++ ) {
                        let handle = handles.data[ i ];
                        handle.selected = false;
                    }

                    return handles.data;
                } else {
                    throw `Error: ${RequestError.INVALID_RESPONSE} (searchSocialHandles)`;
                }
            } catch ( error ) {
                console.error( error );
            }
        },

        setLoader(context: string, active: boolean | null = true) {
            if (!context.length) {
                return false;
            }

            try {
                const indexes: number[] = this.loaders.reduce<number[]>((accumulator, loader, index) => {
                    if (context && loader.context === context) {
                        accumulator.push(index);
                    }

                    return accumulator;
                }, []);

                // active = active !== undefined ? active : true;
                if (!isEmptyArray(indexes)) {
                    for ( let i = 0; i < indexes.length; i++ ) {
                        let index: number = indexes[ i ];

                        if (active) {
                            this.loaders[index].active = active;
                        } else {
                            this.loaders.splice(index, 1);
                        }
                    }
                } else {
                    if (active !== null) {
                        this.loaders.push({context: context, active: active});
                    }
                }

                return true;
              } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async verify2faCode( credentials: UserCredentials ) {
            try {
                if ( credentials.code && typeof credentials.rememberMe === 'boolean' ) {
                    const body: UserCredentials = credentials;
                    const response: any = await api.getAxiosInstance.post( '/api/auth/2fa/verification', body );
                    const verification: any = response.data;

                    if ( verification ) {
                        return verification;
                    } else {
                        throw `${RequestError.INVALID_RESPONSE}: ${verification}`;
                    }
                } else {
                    throw `${RequestError.INVALID_REQUEST}`;
                }
            } catch ( error ) {
                console.error( error.stack );
                return false;
            }
        },

        async verifyPassword( credentials: UserCredentials ) {
            try {
                const currentUser: User = useUserStore().getUser;

                if ( credentials.password && ( credentials.id === currentUser.id ) ) {
                    const response: any = await this.login( credentials );

                    return ( uidMatcher.test( response?.data?.token ) );
                } else {
                    throw ( {
                        error: invalidCredentials
                    } );
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
                return false;
            }
        },

        async verifySecurityQnA( verification: UserVerification ) {
            try {
                if ( verification.questions && verification.answers ) {
                    const body: any = { id: useUserStore().getUser.id, verification: verification };
                    const verifiedUser: User = await api.getAxiosInstance.put( `/api/auth/users/${useUserStore().getUser.id}/verification`, body );

                    if ( verifiedUser && verifiedUser.hasOwnProperty( 'data' ) ) {
                        return verifiedUser;
                    } else {
                        return false;
                    }
                }
              } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },
    }
})
