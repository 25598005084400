import { RX_NUMBER } from './regex-matchers';

export const HAS_WINDOW_SUPPORT = typeof window !== 'undefined';
export const HAS_DOCUMENT_SUPPORT = typeof document !== 'undefined';
export const HAS_NAVIGATOR_SUPPORT = typeof navigator !== 'undefined';
export const IS_BROWSER = HAS_WINDOW_SUPPORT && HAS_DOCUMENT_SUPPORT && HAS_NAVIGATOR_SUPPORT;
export const WINDOW = HAS_WINDOW_SUPPORT ? window : {};
export const File = HAS_WINDOW_SUPPORT ? WINDOW.File : class File extends Object {};

export const toType = value => typeof value

export const toRawType = value => Object.prototype.toString.call(value).slice(8, -1)

export const toRawTypeLC = value => toRawType(value).toLowerCase()

export const isUndefined = value => value === undefined

export const isNull = value => value === null

export const isEmptyString = value => value === ''

export const isEmptyObject = (obj: any): boolean => {
  if (isObject(obj)) {
      return Object.keys(obj).length < 1;
  } else {
      return false;
  }
};

export const isEmptyArray = (array: Array<any>) => {
  return (!Array.isArray(array) || !array.length || (array.length === 0));
};

export const isUndefinedOrNull = value => isUndefined(value) || isNull(value)

export const isUndefinedOrNullOrEmpty = value => isUndefinedOrNull(value) || isEmptyString(value)

export const isFunction = value => toType(value) === 'function'

export const isBoolean = value => toType(value) === 'boolean'

export const isString = value => toType(value) === 'string'

export const isNumber = value => toType(value) === 'number'

export const isNumeric = value => RX_NUMBER.test(String(value))

export const isPrimitive = value => isBoolean(value) || isString(value) || isNumber(value)

export const isArray = value => Array.isArray(value)

// Quick object check
// This is primarily used to tell Objects from primitive values
// when we know the value is a JSON-compliant type
// Note object could be a complex type like array, Date, etc.
export const isObject = obj => obj !== null && typeof obj === 'object'

// Strict object type check
// Only returns true for plain JavaScript objects
export const isPlainObject = obj => Object.prototype.toString.call(obj) === '[object Object]'

export const isDate = value => value instanceof Date

export const isEvent = value => value instanceof Event

export const isFile = value => value instanceof File

export const isRegExp = value => toRawType(value) === 'RegExp'

export const isPromise = value =>
  !isUndefinedOrNull(value) && isFunction(value.then) && isFunction(value.catch)
