export const saveStateRouteNames: string[] = [ 'personaBuild', 'audienceBuild' ];

export const publicRouteNames = ( routes: any ) => {
    let publicRoutes: string[] = [];

    try {
        publicRoutes = routes.filter( route => { return route.meta && !route.meta!.requiresAuth } ).map( ( route ) => { return route.name! });
    } catch (error) {
        console.error(error.stack);
    }

    return publicRoutes;
}

export const publicRoutePending = ( routes: any, router: any ) => {
    let pending: boolean = false;

    try {
        const routerHistory: any = ( router as any ).history;
        const pendingRoute: string =  ( routerHistory && routerHistory.pending ) ? routerHistory.pending.name : '';

        pending = publicRouteNames( routes ).includes( pendingRoute );
    } catch (error) {
        console.error(error.stack);
    }

    return pending;
}

export const shareRoutePending = ( router: any ) => {
    let pending: boolean = false;

    try {
        const routerHistory: any = ( router as any ).history;
        const shareRoutes: string[] = [ 'comparisonReportShare', 'personaReportShare', 'audienceReportShare', 'personaReportPDF', 'audienceReportPDF' ];
        const pendingRoute: string =  ( routerHistory && routerHistory.pending ) ? routerHistory.pending.name : '';

        pending = ( shareRoutes.includes( pendingRoute ) );
    } catch (error) {
        console.error(error.stack);
    }

    return pending;
}