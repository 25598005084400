<template>
    <div class="row g-0 w-100 page-footer align-items-end">
        <div class="col page-footer-left text-start">
            <div v-if="content.hasOwnProperty('left')" v-html="content.left"></div>
        </div>

        <div class="col page-footer-center text-center">
            <div v-if="content.hasOwnProperty('center')" v-html="content.center"></div>
        </div>

        <div class="col page-footer-right text-end">
            <div v-if="content.hasOwnProperty('right')" v-html="content.right"></div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';

    @Component<PageFooter>({
        components: {
        }
    })
    class PageFooter extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        content: any
    }
    export default toNative(PageFooter);
</script>
