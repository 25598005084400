<template>
    <section class="container-fluid bg-comparison-report p-0 pt-3">
        <div class="row">
            <div v-for="(chartSection, personaIndex) in chartData" class="col-6" :key="personaIndex">

                <!-- Top section -->
                <div class="bg-white mb-3">
                    <div v-for="chartElements of chartSection.topSection"
                         :data-chart-id="`${chartElements.chart.id}:${personaIndex}`"
                         :key="`${chartElements.chart.id}:${personaIndex}`"
                    >
                        <div class="w-100">
                            <div class="text-center">
                                <highcharts :options="chartElements.chart"/>
                            </div>
                        </div>

                        <!-- Associated data, if available -->
                        <div class="d-flex align-items-end mt-3">
                            <div class="additional-info p-3 m-3 w-100 bg-blue-10">
                                <template v-if="chartElements.associatedData !== false">
                                    <chart-associated-data :data="chartElements.associatedData"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Middle section -->
                <div class="bg-white mb-3">
                    <highcharts :options="chartSection.middleSection.chart"/>

                    <!-- Detail sidebar -->
                    <div class="p-3">
                        <div class="mb-n3">
                            <div v-for="detailSection of chartSection.middleSection.detail"
                                 class="card bg-blue-10 border-0 p-3 mb-3"
                            >
                                <u class="h5 text-primary mb-3 mx-auto text-nowrap" v-html="detailSection.name"></u>
                                <div style="max-height: 17em; overflow: auto">
                                    <table class="text-primary w-100">
                                        <tr v-for="item of detailSection.items"
                                            :key="item.index"
                                        >
                                            <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                            <td class="align-top text-end text-nowrap" v-html="item.value"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bottom section -->
                <div class="bg-white pt-3" v-if="displayTopSocialContent">
                    <h5 class="text-gray-75 text-center mb-3">Top Social Content Posted by Persona {{ Utils.numberToAlpha(personaIndex + 1) }}</h5>

                    <div v-for="chartElements of chartSection.bottomSection"
                         class="pb-3 m-3"
                         :data-chart-id="`${chartElements.chart.id}:${personaIndex}`"
                         :key="`${chartElements.chart.id}:${personaIndex}`"
                    >
                        <highcharts :options="chartElements.chart"/>

                        <!-- Detail sidebar -->
                        <div class="card bg-blue-10 border-0 p-3">
                            <u class="h5 text-primary mb-3 mx-auto text-nowrap"
                               v-html="`${chartElements.detail.contentTypeLabel} Used by Persona`"
                            ></u>
                            <table class="text-primary w-100">
                                <thead>
                                <tr>
                                    <th><u>{{ chartElements.detail.contentTypeLabel }}</u></th>
                                    <th class="text-center"><u>Index</u></th>
                                    <th class="text-center"><u>Total Post Count</u></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="item of chartElements.detail.items"
                                    :key="item.index"
                                >
                                    <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                    <td class="align-top text-center" v-html="item.indexValue"></td>
                                    <td class="align-top text-center" v-html="item.countValue"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import Highcharts from 'highcharts';
    import {Chart} from 'highcharts-vue';
    import mapInit from 'highcharts/modules/map';
    import FontAwesomeIcon from "Components/common/font-awesome-icon";
    import ChartAssociatedData from "Components/persona/report/chart-associated-data";
    import * as Utils from 'Utilities/utils';

    mapInit(Highcharts);

    @Component({
        components: {
            ChartAssociatedData,
            highcharts: Chart,
            FontAwesomeIcon,
        }
    })
    class SocialActivityComparison extends Vue {
        @Prop({
            type: Object,
            default: () => {
            }
        })
        chartData;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        // showDataLabels: boolean = true;
        sortGroup: string = 'index';
        // displayGroup: string = 'all';
        displayOptions = [
            {
                group: 'low',
                label: 'Show Only Low',
                color: 'red',
            },
            {
                group: 'all',
                label: 'Show All Values',
                color: 'blue',
            },
            {
                group: 'high',
                label: 'Show Only High',
                color: 'green',
            },
        ];
        Utils = Utils;

        get displayTopSocialContent() {
            return false; // PBUILDER-2782 - top content should NEVER appear
            // return this.chartData.every((chartSection: any) => chartSection.bottomSection.every(chartData => chartData.chart !== false));
        }
    }
    export default toNative(SocialActivityComparison);
</script>
