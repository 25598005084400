import {Error, ProxyResponse} from 'Stores/common/models';
import { invalidAuthResponse } from 'Utilities/immutables';

export const defaultError: Error = { message: '' };
export const defaultRoute = { name: 'activity' };
export const defaultStatus = {
    status: '',
    statusCode: 0,
    displayText: '',
    hoverText: '',
    sendEmail: 0,
    timestamp: ''
}
export const invalidCredentials: ProxyResponse = { code: invalidAuthResponse.code, status: invalidAuthResponse.status += ': Invalid Credentials' };