<template>
    <div class="row p-2" data-reset="persona-counter">
        <div v-for="(persona, index) of comparisonPersonas"
             class="col position-relative align-self-center"
             data-intent="persona-info"
             :key="persona.id"
        >
            <div class="row">
                <div class="col-auto position-relative align-self-center">
                    <div data-intent="representative-photo">
                        <representative-photo
                            :characteristics="persona.photoCharacteristics"
                            :photo-source="persona.userPhoto?.filePathUri || null"
                        />
                    </div>
                </div>

                <div class="col ps-0 align-self-center">
                    <div class="persona-name header-title d-flex">
                        <div class="align-self-center">
                            <strong>Persona:</strong> {{ persona.name }}
                        </div>
                    </div>

                    <div>
                        <ul v-if="mode !== ComparisonMode.PRINT && index === 0" class="list-inline d-inline mb-0 me-3">
                            <li class="list-inline-item">
                                <a href @click.prevent="showSummaryInfo">
                                    <font-awesome-icon :icon="['solid', 'circle-info']" fixed-width/>
                                </a>
                            </li>

                            <li class="list-inline-item">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="pdfSharing">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <a v-else
                                    href
                                    :title="`Download or Share PDF`" v-tooltip
                                    @click.prevent="shareModal('pdf', 'show')"
                                >
                                    <font-awesome-icon :icon="['regular', 'file-pdf']" fixed-width/>
                                </a>
                            </li>

                            <li class="list-inline-item">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="reportSharing">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <a v-else
                                    href
                                    :title="`Share this Report`" v-tooltip
                                    @click.prevent="shareModal('report', 'show')"
                                >
                                    <font-awesome-icon :icon="['solid', 'share-from-square']" fixed-width/>
                                </a>
                            </li>
                        </ul>
                        <em class="header-detail small">Built on {{ getBuildDate( index+1 ) }}</em>
                    </div>
                </div>

                <div v-if="index === (comparisonActivityCount - 1)" class="col-auto my-auto">
                    <img class="brand-logo"
                         :onerror="Utils.imageFallback(defaultBrandLogoPath)"
                         :src="logoPath"
                    />
                </div>
            </div>
        </div>

        <bootstrap-modal
            body-class="text-normal"
            class="modal-xl"
            content-class="bg-blue-25 border-primary"
            :hide-on-backdrop="false"
            title-class="text-primary"
            :id="summaryInfoModalId"
        >
            <template #title class="text-primary">Comparison Summary Information</template>
            <template #body>
                <div class="row mb-3 text-primary">
                    <div class="col text-start">Comparison Built By: <strong>{{ comparisonBuiltBy }}</strong></div>
                    <!--                                <div class="col text-end">Persona based on <strong>{{ personaCount }}</strong> people-->
                    <!--                                </div>-->
                </div>

                <!--                            <h6><i>Comparison Characteristics</i></h6>-->
                <div id="characteristics">
                    <characteristics :mode="ComparisonMode.REPORT"/>
                </div>
            </template>
        </bootstrap-modal>

        <share
            v-if="mode !== ComparisonMode.PRINT"
            :content-title="comparison.name"
            :content-type="`Comparison`"
            :context="shareContext"
            :link="shareLink(shareContext)"
            ref="share"
        />
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';
    import {nextTick, defineAsyncComponent, ref} from 'vue';
    import {useHead} from '@unhead/vue';
    import {Modal} from 'bootstrap';
    import BootstrapModal from 'Components/common/bootstrap/modal.vue';
    import Characteristics from 'Components/comparison/characteristics/characteristics.vue';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import {useAccountStore} from 'Stores/account';
    import {useAppStore} from 'Stores/common/app';
    import {PhotoAssignment} from 'Stores/common/models';
    import {useComparisonStore, comparisonActivityCount, ComparisonMode} from 'Stores/comparison';
    import {Persona} from 'Stores/persona';
    import {Events} from 'Utilities/immutables';
    import {ReportLoaders} from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';

    const RepresentativePhoto = () => import(/* webpackChunkName: "RepresentativePhoto" */ 'Components/common/representative-photo');

    @Component<ComparisonHeader>({
        emits: [Events.HIDE_TOOLTIPS],
        setup() {
            useHead({
                title: `Comparison: ${useComparisonStore().getComparison.name || 'Loading...'}`
            });

            return {
                appStore: useAppStore(),
                accountStore: useAccountStore(),
                comparisonStore: useComparisonStore(),
            };
        },
        components: {
            BootstrapModal,
            Characteristics,
            FontAwesomeIcon,
            RepresentativePhoto,
            Share: defineAsyncComponent(() =>
                import('Components/common/report/share.vue')
            )
        }
    })
    class ComparisonHeader extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            },
        })
        comparison: any;

        @Prop({
            type: [Object, Array],
            default: _ => {
                return [];
            },
        })
        insights?: any[];

        @Prop({
            type: String,
            default: ComparisonMode.REPORT,
        })
        mode?: string;

        @Prop({
            type: Object,
            default: () => {
                return {};
            },
        })
        shareToken?: any;

        blankBrandLogoPath = '/assets/images/transparent.png';
        comparisonActivityCount = comparisonActivityCount;
        ComparisonMode = ComparisonMode;
        defaultBrandLogoPath = '/assets/images/wiland-logo.svg';
        shareContext: string = '';
        // shareToken: any = ref({});
        summaryInfoModal: Modal | null = null;
        summaryInfoModalId: string = 'comparisonSummaryInfoModal';

        Utils = Utils;

        get account() {
            return this.accountStore.getAccount;
        }

        get comparisonBuiltBy(): string {
            return `${this.comparison.updated?.user?.firstName || 'unknown'} ${this.comparison.updated?.user?.lastName || 'unknown'}`;
        }

        get comparisonPersonas() {
            return this.comparisonStore.getPersonas;
        }

        get logoPath(): string {
            // TODO: move to a reusable spot?
            return this.comparison.account.logoFile?.filePathUri?.length > 0 ?
                this.blankBrandLogoPath :
                this.defaultBrandLogoPath;
        }

        get pdfSharing() {
            return this.appStore.getLoaderActive(`${ReportLoaders.SHARING_REPORT}-pdf`);
        }

        get reportSharing() {
            return this.appStore.getLoaderActive(`${ReportLoaders.SHARING_REPORT}-report`);
        }

        mounted() {
            if (document.getElementById(this.summaryInfoModalId)) {
                this.summaryInfoModal = new Modal(document.getElementById(this.summaryInfoModalId)!);
            }
        }

        getBuildDate( index: number ) {
            return this.Utils.dateFormat(this.comparison[`personaJob${index}CompletedAt`], {format: 'standard'});
        }

        currentPhoto(persona: Persona) {
            if (persona.photos && persona.photos.length > 0) {
                for (let i = 0; i < persona.photos.length; i++) {
                    const photo: PhotoAssignment = persona.photos[i];

                    if (photo.type === 'current') {
                        return photo;
                    }
                }
            }
        }

        shareLink(context: string) {
            let route: any = {
                name: '',
                params: {
                    accountId: this.account.id,
                    id: this.comparison.id,
                },
            };

            switch (context) {
                case 'pdf':
                    if (!this.shareToken.hasOwnProperty('token')) {
                        return '';
                    }
                    route.name = 'comparisonReportPDF';
                    route.params.token = this.shareToken.token;
                    break;

                case 'report':
                    if (!this.shareToken.hasOwnProperty('token')) {
                        return '';
                    }
                    route.name = 'comparisonReportShare';
                    route.params.token = this.shareToken.token;
                    break;
            }

            return Utils.siteRoot() + this.$router.resolve(route).href.replace(/^\//, '');
        }

        async shareModal(context: string, action: string) {
            this.$eventBus.emit(Events.HIDE_TOOLTIPS);
            this.shareContext = context;
            this.appStore.setLoader(`${ReportLoaders.SHARING_REPORT}-${this.shareContext}`);

            switch (action) {
                case 'hide':
                    this.$refs['share'].shareModal.hide();
                    break;
                case 'show':
                    if (!this.shareToken.hasOwnProperty('comparison') || this.comparison.id !== this.shareToken?.comparison?.id) {
                        const shareToken = await this.comparisonStore.generateComparisonShareToken(this.comparison);
                        this.$emit(Events.UPDATE_SHARE_TOKEN, shareToken);
                    }
                    await nextTick(() => {
                        this.$refs['share'].shareModal.show();
                        this.appStore.clearLoader(`${ReportLoaders.SHARING_REPORT}-${this.shareContext}`);
                    })
                    break;
            }
        }

        async showSummaryInfo() {
            if (this.mode !== ComparisonMode.PRINT) {
                this.summaryInfoModal?.show();
            }
        }
    }
    export default toNative(ComparisonHeader);
</script>
