import api from 'Server/api';
import cloneDeep from 'lodash-es/cloneDeep';
import { Status } from 'Stores/common/models';
import { defineStore } from 'pinia';
import { useAccountStore } from 'Stores/account';
import { defaultStatus } from 'Stores/common/defaults';
import { isEmptyArray } from 'Utilities/utils';
import { AxiosResponse } from 'axios';

export interface JobState {
    jobId: string,
    job: Job,
    originatorRoot: string,
    originatorId: string
}

export interface Job {
    accountId: string;
    id: string;
    name: string;
    status: Status,
    submitted: {
        id: string;
        firstName: string;
        lastName: string;
        timestamp: string;
    }
}

export const defaultJob: Job = {
    accountId: '',
    id: '',
    name: '',
    status: cloneDeep(defaultStatus),
    submitted: {
        id: '',
        firstName: '',
        lastName: '',
        timestamp: ''
    }
}

export const useJobStore = defineStore('job', {
    state: (): JobState => ({
        jobId: '',
        job: cloneDeep(defaultJob),
        originatorRoot: '',
        originatorId: ''
    }),

    getters: {
        getJob: ( state: JobState ) => state.job,
    },

    actions: {
        async getJobByIdOrLatest(params: any = {shared: false}) {
            try {
                // TODO: is this repeatable for other types of jobs, or only Persona?
                const accountId: string = params.accountId || useAccountStore().getActiveAccount.id;
                const shared: boolean = params.shared === 'true';
                const queryString: string = shared ? `?shared=true` : ``;
                let endpoint = `/api/accounts/${accountId}/${this.originatorRoot}/${this.originatorId}/jobs`;
                let config: any = {};

                if (this.jobId) {
                    endpoint = `${endpoint}/${this.jobId}`;
                } else {
                    // Get the most recent job
                    config.params = {
                        sortBy: 'updated',
                        orderBy: 'desc',
                        limit: 1
                    };
                }

                const response = await api.getAxiosInstance.get(endpoint + queryString, config);
                const jobData = response.data?.data || null;
                this.job = this.jobId
                    ? jobData
                    : !isEmptyArray(jobData) ? jobData[0] : cloneDeep(defaultJob); // Use the top (most recent) job

                return this.job;
            } catch (e) {
                console.error('job.ts->getJob() error:', e);
                return null;
            }
        },

        async getJobsByIds(params: any) {
            // console.log(`💰 GETTING JOBS BY IDs: ${JSON.stringify(params)}`);
            try {
                const accountId: string = params.accountId || useAccountStore().getActiveAccount.id;
                const shared: boolean = params.shared === true || params.shared === 'true';
                // console.log(`SHARED MODE -> ${shared ? '🟢 YES' : '🔴 NO'}`);
                const queryString: string = shared ? `?shared=true` : ``;
                const endpoints: string[] = params.jobs.map((job: any) =>
                    `/api/accounts/${accountId}/${params.originator}/${job.originatorId}/jobs/${job.id}${queryString}`
                );
                // console.log(`⏩ API ENDPOINTS: ${JSON.stringify(endpoints)}`);
                const responses: AxiosResponse<any>[] = await Promise.all(endpoints.map((endpoint: string) => api.getAxiosInstance.get(endpoint)));
                let jobs: Job[] = [];

                for (let i: number = 0; i < responses.length; i++) {
                    if (responses[i].data.data) {
                        jobs.push(responses[i].data.data);
                    }
                }

                return jobs;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setJobId(id: string) {
            try {
                this.jobId = id;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setOriginatorId(id: string) {
            try {
                this.originatorId = id;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setOriginatorRoot(root: string) {
            try {
                this.originatorRoot = root;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setJobName(name: string) {
            try {
                this.job.name = name;

                const endpoint = `/api/accounts/${useAccountStore().getActiveAccount.id}/${this.originatorRoot}/${this.originatorId}/jobs/${this.job.id}`;
                const body = {
                    name: this.job.name
                };

                return await api.getAxiosInstance.patch(endpoint, body);
            } catch (error) {
                console.error(error);
                return false;
            }
        },
    }
})
