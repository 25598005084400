import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "page-header w-100 d-flex justify-content-between align-items-center" }
const _hoisted_2 = { class: "page-header-left text-start" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "page-header-center text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "page-header-right text-end" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.content.hasOwnProperty('left'))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.content.left
          }, null, 8 /* PROPS */, _hoisted_3))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.content.hasOwnProperty('center'))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.content.center
          }, null, 8 /* PROPS */, _hoisted_5))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.content.hasOwnProperty('right'))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.content.right
          }, null, 8 /* PROPS */, _hoisted_7))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}